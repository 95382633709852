import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { MouseEventHandler } from "react";

interface IDataLoaderErrorProps {
  errorMessage?: string;
  onRetry?: MouseEventHandler<HTMLButtonElement>;
}

const DataLoaderError = ({ errorMessage, onRetry }: IDataLoaderErrorProps) => {
  return (
    <Box
      marginTop="1.5rem"
      display="flex"
      flexDirection="column"
      gap="1rem"
      justifyContent="center"
      alignItems="center"
    >
      <Typography variant="h6" component="h6" color="#ff5722">
        {errorMessage
          ? errorMessage
          : "Something went wrong! Please try again..."}
      </Typography>

      {onRetry && <Button
        onClick={onRetry}
        sx={{
          padding: "7px 30px",
          fontWeight: "600",
          borderRadius: "50rem",
          minWidth: "140px",
        }}
        variant="contained"
      >
        TRY AGAIN
      </Button>}
    </Box>
  );
};
export default DataLoaderError;
