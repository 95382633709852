import PageLayout from "components/PageLayout";
import Card from "components/Card";
import { ClassReportFormValues } from "types/report";
import { Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useActiveClassReport } from "hooks/reports";
import ReportForm from "views/report/class/Form";

const ClassResult = () => {
  const {
    data: classReport,
    isLoading,
    isError,
    isFetching,
    refetch,
  } = useActiveClassReport();

  const history = useHistory();

  const handleSubmission = async (values: Partial<ClassReportFormValues>) => {
    history.push(
      `/dashboard/result/class/view/?session=${values.session}&term=${values.term}&class=${values.class}`
    );
  };

  return (
    <Box sx={{ margin: { xs: "0", lg: "0 120px" } }}>
      <PageLayout
        loading={isLoading || isFetching}
        error={isError}
        onRetry={refetch}
      >
        <Card title="CLASS RESULT">
          <ReportForm classReport={classReport} onSubmit={handleSubmission} />
        </Card>
      </PageLayout>
    </Box>
  );
};

export default ClassResult;
