import React, { useState } from "react";
import TextField from "components/Form/TextField";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Box, InputLabel } from "@mui/material";
import { IUpdateProfileValues } from "types/profile";
import { SubmitButton } from "views/auth/Common";
import { useUpdateProfileInfo } from "hooks/dashboard";
import DataLoader from "components/DataLoader";
import DataLoaderError from "components/DataLoaderError";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { useStateData, useUpdateSchoolLogo } from "../../hooks/dashboard/index";
import styles from "./profile.module.css";
import ProfileImage from "components/ProfileImage/index";
import { generateUUID } from "hooks/uuid";
import useS3Upload from "hooks/thirdparty/useS3Upload";
import { toast } from "react-toastify";
import { GenericAPIResponse } from "../../types/generic";

const validationSchema = Yup.object().shape({
  firstname: Yup.string().required("First name is required"),
  lastname: Yup.string().required("Last name is required"),
  phone: Yup.string().required("Phone number is required"),
  school: Yup.string().required("School name is required").min(5, "Too Short!"),
  schoolphone: Yup.string().required("Phone number is required"),
  schoolemail: Yup.string()
    .email("Provide a valid email address")
    .required("Email address is required"),
  state: Yup.string().required("Select a state from the drop down"),
  city: Yup.string().required("City is required"),
  address: Yup.string().required("Address is required"),
});

interface IUpdateProfileProps {
  onSubmit: (values: IUpdateProfileValues) => Promise<any> | any;
}

interface ILogoUploadResponse {
  code: string;
  message: string;
  error: string;
}

const UpdateProfileForm = ({ onSubmit }: IUpdateProfileProps) => {
  const [logoSrc, setLogoSrc] = useState("");

  // Prefilling the details with users previous record
  const { isLoading, data, refetch, error } = useUpdateProfileInfo();
  const schoolData = data?.schools;

  const { data: stateData } = useStateData();

  const { progress, uploadAsync } = useS3Upload();

  const initialValues: IUpdateProfileValues = {
    firstname: data?.firstname,
    lastname: data?.lastname,
    phone: data?.phone,
    school: schoolData?.school,
    schoolphone: schoolData?.phone,
    schoolemail: schoolData?.email,
    state: schoolData?.state,
    city: schoolData?.city,
    address: schoolData?.address,
    avatar: schoolData?.avatar,
  };

  //setLogoSrc(schoolData?.avatar || "");

  const { mutateAsync: updateLogoAsync } = useUpdateSchoolLogo();

  const handleAvatarChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      if (!event.target.files) return;
      const folder = "profile/";
      const currentFile = event.target.files[0];
      const fileName = folder + generateUUID() + currentFile.name;
      const url = URL.createObjectURL(currentFile);
      setLogoSrc(url);
      await uploadAsync(currentFile, fileName);
      const uploadedFilePath =
        "https://" +
        process.env.REACT_APP_AWS_BUCKET_NAME +
        ".s3.amazonaws.com/" +
        fileName;
      setLogoSrc(uploadedFilePath);
      const response = await updateLogoAsync({ path: uploadedFilePath });
      toast(response.message, { type: "success" });
    } catch {
      toast("Something went wrong while uploading logo. Please try again", {
        type: "error",
      });
    }
  };

  // Handling error when fetching previous data
  if (isLoading) {
    return <DataLoader />;
  } else if (error) {
    return (
      <DataLoaderError
        errorMessage="Loading Details Failed... "
        onRetry={() => refetch()}
      />
    );
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        await onSubmit(values);
        setSubmitting(false);
      }}
      validationSchema={validationSchema}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        isSubmitting,
      }) => (
        <Form>
          <h2 className={styles.headline}>Personal Info</h2>
          <Box className={styles.textFieldContainer} marginBottom="24px">
            <TextField
              type="text"
              name="firstname"
              value={values.firstname}
              helperText={errors.firstname}
              error={!!(errors.firstname && touched.firstname)}
              label="First name"
              variant="outlined"
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <TextField
              type="string"
              name="lastname"
              value={values.lastname || ""}
              helperText={errors.lastname}
              error={!!(errors.lastname && touched.lastname)}
              variant="outlined"
              label="Last name"
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <TextField
              type="string"
              name="phone"
              value={values.phone || ""}
              helperText={errors.phone}
              error={!!(errors.phone && touched.phone)}
              variant="outlined"
              label="Phone Number"
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Box>
          <h2 className={styles.headline}>School Info</h2>
          <Box
            display="flex"
            marginTop="16px"
            justifyContent="space-between"
            flexDirection={{ xs: "column", sm: "row" }}
            gap={{ xs: "1rem", sm: "0" }}
            width="100%"
          >
            <ProfileImage
              src={logoSrc || schoolData?.avatar}
              size="120px"
              type="circle"
              canChange={true}
              callback={handleAvatarChange}
              uploadProgress={progress}
            />
          </Box>

          <Box className={styles.textFieldContainer}>
            <TextField
              type="text"
              name="school"
              value={values.school}
              helperText={errors.school}
              error={!!(errors.school && touched.school)}
              label="School name"
              variant="outlined"
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <TextField
              type="string"
              name="schoolemail"
              value={values.schoolemail || ""}
              helperText={errors.schoolemail}
              error={!!(errors.schoolemail && touched.schoolemail)}
              variant="outlined"
              label="School email address"
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Box>

          <Box className={styles.textFieldContainer}>
            <TextField
              type="string"
              name="schoolphone"
              value={values.schoolphone || ""}
              error={!!(errors.schoolphone && touched.schoolphone)}
              label="Contact address"
              variant="outlined"
              helperText={errors.schoolphone}
              onChange={handleChange}
              onBlur={handleBlur}
              sx={{ flex: "1" }}
            />

            <Box margin="0 5px" flex={1}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="state">State</InputLabel>
                <Select
                  type="text"
                  name="state"
                  value={values.state || ""}
                  error={!!(errors.state && touched.state)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  labelId="state"
                  id="demo-simple-select"
                  label="State"
                >
                  {stateData?.map((state) => {
                    return <MenuItem value={state.id}>{state.state}</MenuItem>;
                  })}
                </Select>
                <FormHelperText>{errors.state}</FormHelperText>
              </FormControl>
            </Box>
          </Box>

          <Box className={styles.textFieldContainer}>
            <TextField
              type="text"
              name="city"
              label="City"
              variant="outlined"
              value={values.city || ""}
              error={!!(errors.city && touched.city)}
              helperText={errors.city}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <TextField
              type="text"
              name="address"
              value={values.address || ""}
              helperText={errors.address}
              error={!!(errors.address && touched.address)}
              onChange={handleChange}
              onBlur={handleBlur}
              label="Address"
              variant="outlined"
            />
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            flexDirection="row"
            textAlign="center"
            marginTop="30px"
          >
            <SubmitButton
              disabled={isSubmitting}
              type="submit"
              variant="contained"
            >
              {isSubmitting ? "Please wait..." : "Update Details"}
            </SubmitButton>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default UpdateProfileForm;
