import { decodeToken } from "api/cookie";
import { QueryOptions, useMutation, useQuery } from "react-query";
import { deleteRequest, get, post, put } from "api";
import { MutationOptions } from "hooks/types";
import { GenericAPIResponse } from "types/generic";
import { IAddTermValues, Term } from "types/terms";

export const useListTerms = (options?: QueryOptions<Term[]>) => {
  const userid = decodeToken().userid;
  return useQuery<Term[]>(["settings", "term"], () => get(`/students/terms`), {
    ...(options || {}),
  });
};

export const useAddTermMutation = (
  options?: MutationOptions<GenericAPIResponse<any>>
) => {
  return useMutation(
    (value: IAddTermValues) =>
      post(`/students/term`, {
        term: value.term,
        status: value.status,
      }),
    { ...(options || {}) }
  );
};

export const useEditTermMutation = (
  options?: MutationOptions<GenericAPIResponse<any>>
) => {
  return useMutation(
    (value: IAddTermValues) =>
      put(`/students/term`, {
        term: value.term,
        status: value.status,
        id: value.id,
      }),
    { ...(options || {}) }
  );
};

export const useDeleteTerm = (
  options?: MutationOptions<GenericAPIResponse<any>>
) => {
  return useMutation(
    (termId: number) => deleteRequest(`/students/term/${termId}`),
    { ...(options || {}) }
  );
};
