export type IAddUserFormValues = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  role: string;
  password: string;
  class: string;
};

export enum UserRoles {
  Teacher = "Form Teacher",
  Subject = "Subject Teacher",
  Head = "Head Teacher (Full Access)",
  Accountant = "Accountant",
  Receptionist = "Receptionist",
  Exam = "Exam Officer",
  Store = "Store Keeper",
  Librarian = "Librarian",
}

export type IUser = {
  id: number;
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  status: string;
  privileges: string;
  acc_status: IAccountStatus;
};

export type IAccountStatus = "active" | "inactive";
