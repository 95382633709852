import { LockOpenOutlined, LockOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import Table, { RowData } from "components/Table";
import { viewAccountAddUserUrl } from "config/urls";
import { useHistory } from "react-router-dom";
import { IUser } from "types/users";

interface IUsersTableProps {
  users: IUser[];
  onUpdateAccountStatus?: (userId: number, updateToLocked: boolean) => any;
}

const UsersTable = (props: IUsersTableProps) => {
  const { users, onUpdateAccountStatus } = props;
  const history = useHistory();
  return (
    <Table
      title={{ label: "Manage Users" }}
      disableSideSheet={true}
      ctaLabel="Add User"
      onCTAClicked={() => {
        history.push(viewAccountAddUserUrl());
      }}
      rows={users}
      columns={[
        {
          id: "firstname",
          label: "First Name",
        },
        {
          id: "lastname",
          label: "Last Name",
        },
        {
          id: "email",
          label: "Email Address",
        },
        {
          id: "phone",
          label: "Phone Number",
        },
        {
          id: "actions",
          label: "",
          renderer: (item: RowData) => {
            const value = item as IUser;
            return (
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  onUpdateAccountStatus?.(
                    value.id,
                    value.acc_status === "active"
                  );
                }}
                style={{ whiteSpace: "nowrap" }}
                variant="outlined"
              >
                {value.acc_status === "active" ? "LOCK USER" : "UNLOCK USER"}
                {value.acc_status === "active" ? (
                  <LockOutlined />
                ) : (
                  <LockOpenOutlined />
                )}
              </Button>
            );
          },
        },
      ]}
    />
  );
};

export default UsersTable;
