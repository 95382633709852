import { useListReportSetting } from 'hooks/reportComment';
import React, { useState } from 'react'
import PageLayout from "components/PageLayout";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { GenericAPIResponse } from "types/generic";
import DeleteModal from "components/DeleteModal";
import { useAddReportCommentMutation } from 'hooks/reportComment';
import { useEditReportCommentMutation } from 'hooks/reportComment';
import { useDeleteReportComment } from 'hooks/reportComment';
import { IReportCommentSetting, IReportCommentSettingFormValue } from 'types/report';
import ReportCommentTable from './Table';
import AddReportCommentForm from './AddReportCommentForm';
import { Fab } from '@mui/material';
import { Add } from '@mui/icons-material';


const ReportComment = () => {

  const { isLoading, isFetching, isError, refetch, data } = useListReportSetting();
  const [getReportCommentId, setGetReportCommentId] = useState<number>(0);
  const [openReportCommentModal, setReportCommentModalOpen] = useState(false);
  const [openEditReportCommentModal, setEditReportCommentModalOpen] = useState(false);
  const [openDeleteAcctModal, setOpenDeleteAcctModal] = useState(false);
  const [editReportCommentObj, setEditReportCommentObj] = useState<IReportCommentSettingFormValue>();

  const handleSubmitError = (error?: AxiosError) => {
    const data = error || {
      message: "We couldn't process your request! Try again",
    };

    toast(data.message, { type: "error" });
  };

  const handleSubmitSuccess = (data: GenericAPIResponse<IReportCommentSetting>) => {
    if (data.code === 0) {
      handleSubmitError();
    } else {
      toast("Report comment added successfully!", { type: "success" });
      setReportCommentModalOpen(false);
      refetch();
    }
  };

  const { mutateAsync: submitAddReportCommentPostRequest } = useAddReportCommentMutation({
    onError: (error: AxiosError) => handleSubmitError(error), //Error handle tech debt to keep API the way it is.
    onSuccess: (data: GenericAPIResponse<any>) => handleSubmitSuccess(data),
  });

  // Handle update
  const { mutateAsync: submitEditReportCommentRequest } = useEditReportCommentMutation({
    onError: (error: AxiosError) => handleSubmitError(error), //Error handle tech debt to keep API the way it is.
    onSuccess: (data: GenericAPIResponse<any>) => handleEditSuccess(data),
  });

  // Handle Delete
  const { mutateAsync: deleteReportCommentRequest } = useDeleteReportComment({
    onError: (error: AxiosError) => handleSubmitError(error), //Error handle tech debt to keep API the way it is.
    onSuccess: (data: GenericAPIResponse<any>) => handleDeleteSuccess(data),
  });

  const handleEditSuccess = (data: GenericAPIResponse<IReportCommentSetting>) => {
    if (data.code === 0) {
      handleSubmitError();
    } else {
      toast("Report Comment updated successfully!", { type: "success" });
      setEditReportCommentModalOpen(false);
      refetch();
    }
  };

  const handleDeleteSuccess = (data: GenericAPIResponse<IReportCommentSetting>) => {
    //set cookie
    if (data.code === 0) {
      handleSubmitError();
    } else {
      toast("Report Comment deleted successfully!", { type: "success" });
      setOpenDeleteAcctModal(false);

      refetch();
    }
  };

  const handleOpenModal = () => {
    setReportCommentModalOpen(true);
  };
  return (
    <PageLayout
    loading={isLoading || isFetching}
    error={isError}
    onRetry={refetch}
  >
    <ReportCommentTable
      openAddReportCommentModal={handleOpenModal}
      reportComments={data?.data || []}
      onDeleteReportComment={(setId) => {
        setOpenDeleteAcctModal(true);
        setGetReportCommentId(setId);
      }}
      onUpdateReportComment={(vals) => {
        setEditReportCommentObj(vals);
        setEditReportCommentModalOpen(true);
      }}
    />
    {openReportCommentModal && (
      <AddReportCommentForm
        open={true}
        handleClose={() => setReportCommentModalOpen(false)}
        onSubmit={submitAddReportCommentPostRequest}
      />
    )}

    {openEditReportCommentModal && (
      <AddReportCommentForm
        open={true}
        isEdit
        data={editReportCommentObj}
        handleClose={() => setEditReportCommentModalOpen(false)}
        onSubmit={submitEditReportCommentRequest}
      />
    )}

    {openDeleteAcctModal && (
      <DeleteModal
        prompt="Do you want to delete this report comment?"
        onCancel={() => setOpenDeleteAcctModal(false)}
        onConfirm={async () => {
          await deleteReportCommentRequest(getReportCommentId);
        }}
        title="Delete Report Comment"
        open={true}
      />
    )}
     <Fab
        onClick={() => handleOpenModal()}
        color="primary"
        style={{ position: "fixed", right: 40, bottom: 40 }}
        sx={{ display: { md: "none" } }}
      >
        <Add />
      </Fab>
  </PageLayout>
  )
}

export default ReportComment
