import { decodeToken } from "api/cookie";
import { QueryOptions, useMutation, useQuery } from "react-query";
import { deleteRequest, get, post, put } from "api";
import { MutationOptions } from "hooks/types";
import { GenericAPIResponse } from "types/generic";
import { IAddTermValues, Term } from "types/terms";
import { IReportSettings, IReportSettingsResponse } from "types/report";

export const useGetResultSettings = (options?: QueryOptions<IReportSettingsResponse>) => {
    return useQuery<IReportSettingsResponse>(
        ["result", "settings"],
        () => get(`/results/settings`),
        { ...(options || {}) }
    );
};

export const useGetTeacherSignature = (options?: QueryOptions<any>) => {
    return useQuery<any>(
        ["settings", "signature"],
        () => get(`/settings/signature`),
        { ...(options || {}) }
    );
};

export const useUpdateTeacherSignatureMutation = (
    options?: MutationOptions<GenericAPIResponse<any>>
) => {
    return useMutation(
        (path: string) =>
            put(`/settings/signature`, {
                path: path,
            }),
        { ...(options || {}) }
    );
};


export const useUpdateResultSettingMutation = (
    options?: MutationOptions<GenericAPIResponse<any>>
) => {

    return useMutation(
        (value: IReportSettings) =>
            post(`/results/settings`, {
                open_days: value.day_school_open,
                next_term_begins: value.next_term_begins,
            }),
        { ...(options || {}) }
    );
};



