import { TextFieldContainer, SubmitButton } from "views/auth/Common";
import TextField from "components/Form/TextField";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Box, Dialog, DialogContent } from "@mui/material";
import {
  IReportCommentSetting,
  IReportCommentSettingFormValue,
} from "types/report";
import DialogTitle from "components/DialogTitle";
export interface SimpleDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}

const validationSchema = Yup.object().shape({
  comment: Yup.string().required("Comment is required"),
  title: Yup.string().required("Please enter a title"),
});

export interface IReportCommentFormProps {
  onSubmit: (values: IReportCommentSetting) => Promise<any> | any;
  open: boolean;
  isEdit?: boolean;
  data?: IReportCommentSettingFormValue;
  handleClose: Function;
}

const AddReportCommentForm = ({
  onSubmit,
  open,
  handleClose,
  isEdit,
  data,
}: IReportCommentFormProps) => {
  const initialValues: IReportCommentSetting = isEdit
    ? {
        comment: data?.comment,
        id: data?.id,
        title: data?.title,
      }
    : {};

  return (
    <Dialog
      sx={{ ".MuiTypography-root": { padding: "16px 0 0px 16px" } }}
      fullWidth={true}
      open={open}
      onClose={() => handleClose()}
    >
      <DialogTitle
        title={`${isEdit ? "Update" : "Add"} Report Comment`}
        onClose={() => handleClose()}
      />
      <DialogContent dividers style={{paddingTop: 0}}>
        <Formik
          initialValues={initialValues}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            await onSubmit(values);
            setSubmitting(false);
          }}
          validationSchema={validationSchema}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
          }) => (
            <Form>
              <TextFieldContainer
                direction="column"
                style={{gap: "24px" }}
              >
                <TextField
                  type="text"
                  name="comment"
                  label="Comment"
                  variant="outlined"
                  value={values.comment || ""}
                  error={!!(errors.comment && touched.comment)}
                  helperText={errors.comment}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <TextField
                  type="text"
                  name="title"
                  label="Title"
                  variant="outlined"
                  value={values.title || ""}
                  error={!!(errors.title && touched.title)}
                  helperText={errors.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </TextFieldContainer>

              <Box
                flexDirection="row"
                textAlign="center"
                style={{ margin: "16px 0px" }}
              >
                <SubmitButton
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                >
                  {isSubmitting
                    ? "Please wait..."
                    : `${isEdit ? "Update" : "Add"} Report Comment`}
                </SubmitButton>
              </Box>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};
export default AddReportCommentForm;
