import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useFormik} from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import { Session } from "types/sessions";
import { Term } from "types/terms";
import { IFilterPinValues } from "types/results";

const validationSchema = Yup.object().shape({
  session: Yup.string().required("Session is required"),
  term: Yup.string().required("Please select a term"),
});

interface FilterPinProps {
  onSubmit: (values: IFilterPinValues) => void;
  sessions: Session[];
  terms: Term[];
}

const FilterPinForm = ({ onSubmit, sessions, terms }: FilterPinProps) => {
  const initialValues: Partial<IFilterPinValues> = {};

  const { values, handleChange, isSubmitting, handleSubmit, isValid } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: async (
        values: Partial<IFilterPinValues>,
        { setSubmitting }
      ) => {
        setSubmitting(true);
        onSubmit?.(values as IFilterPinValues);
        setSubmitting(false);
      },
    });
  return (
    <>
      <Box>
        <h3 style={{ color: "var(--primary)" }}>Filter Result Pins</h3>
        <form onSubmit={handleSubmit}>
          <List>
            <ListItem>
              <FormControl fullWidth>
                {!values.session && (
                  <InputLabel id="session">Select Session</InputLabel>
                )}
                <Select
                  type="text"
                  name="session"
                  onChange={handleChange}
                  labelId="session"
                  id="session-select"
                  label="Select Session"
                  value={values.session || ""}
                  variant="standard"
                  fullWidth
                  size="small"
                >
                  {sessions?.map((session) => {
                    return (
                      <MenuItem key={session.id} value={session.id}>
                        {session.session}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </ListItem>
            <ListItem>
              <FormControl fullWidth>
                {!values.session && (
                  <InputLabel id="term">Select Term</InputLabel>
                )}
                <Select
                  type="text"
                  name="term"
                  onChange={handleChange}
                  labelId="term"
                  id="term-select"
                  label="Select term"
                  value={values.term}
                  variant="standard"
                  fullWidth
                  size="small"
                >
                  {terms?.map((term) => {
                    return (
                      <MenuItem key={term.id} value={term.id}>
                        {term.term}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </ListItem>
            <ListItem>
              <Button
                sx={{
                  padding: "4px 20px",
                  minWidth: "30px",
                  whiteSpace: "nowrap",
                }}
                variant="contained"
                disabled={isSubmitting || !isValid}
                type="submit"
              >
                {isSubmitting ? "Please wait..." : "Apply Filter"}
              </Button>
            </ListItem>
          </List>
        </form>
      </Box>
    </>
  );
};

export default FilterPinForm;

const List = styled("div")`
  display: flex;
  background: #fff;
  justify-content: center;
  align-items: center;
  margin-top: 12px;
  padding: 4px 8px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 12%);
  width: 100%;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    gap: 8px;
    width: 100%;

    div {
      padding: 4px 8px;
      border: none;
    }
  }
`;

const ListItem = styled("div")`
  padding: 8px 16px;
  color: var(--primary);
  width: 100%;
  border-right: 1px solid #b1b4b2;

  :last-child {
    border: none;
    width: 50%;
  }
`;
