import { decodeToken } from "api/cookie";
import { QueryOptions, useMutation, useQuery } from "react-query";
import { deleteRequest, get, post, put } from "api";
import { MutationOptions } from "hooks/types";
import { GenericAPIResponse } from "types/generic";
import { Class, ClassListResponse, IClassValues } from "types/classes";

export const useListClasses = (options?: QueryOptions<ClassListResponse>) => {
    const userid = decodeToken().userid;
    return useQuery<ClassListResponse>(
        ["settings", "class"],
        () => get(`/students/classes`),
        { ...(options || {}) }
    );
};


export const useAddClassMutation = (
    options?: MutationOptions<GenericAPIResponse<any>>
) => {
    return useMutation(
        (value: IClassValues) =>
            post(`/students/classes`, {
                class: value.class,
                section: value.section,
                is_nursery: value.is_nursery
            }),
        { ...(options || {}) }
    );
};


export const useEditClassMutation = (
    options?: MutationOptions<GenericAPIResponse<any>>
) => {

    return useMutation(
        (value: IClassValues) =>
            put(`/students/classes`, {
                class: value.class,
                section: value.section,
                is_nursery: value.is_nursery,
                id: value.id,
            }),
        { ...(options || {}) }
    );
};


export const useDeleteClass = (
    options?: MutationOptions<GenericAPIResponse<any>>
) => {
    return useMutation(
        (classID: number) => deleteRequest(`/students/classes/${classID}`),
        { ...(options || {}) }
    );
};
