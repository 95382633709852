import { get, post } from "api";
import { MutationOptions } from "hooks/types";
import { QueryOptions, useMutation, useQuery } from "react-query";
import { GenericAPIResponse } from "types/generic";
import { IClassResultStudentMatchResponse, IGetCumulativeResultGroup, IGetListPinValues, IPostPinFormTypes } from "types/results";


// Mutation for creating new pin
export const useCreatePinMutation = (
  options?: MutationOptions<GenericAPIResponse<any>>
) => {
  return useMutation(
    (values: IPostPinFormTypes) =>
      post(`user/generatepin`, {
        pin: values.pinNumbers,
        session: values.session,
        term: values.term,
      }),
    options
  );
};

//Query for getting result pins
export const usePins = (
  sessionId?: string ,
  termId?: string,
  options?: QueryOptions<IGetListPinValues>
) => {
  return useQuery<IGetListPinValues>(
    ["user", "view_pins", sessionId, termId],
    () => get(`user/viewpins/${sessionId}/${termId}`),
    { ...(options || {}), enabled: !!sessionId && !!termId}
  );
};

//Getting the list of report available for the active session
export const useCumulativeResultGroup = (
  sessionId: string | null,
  options?: QueryOptions<IGetCumulativeResultGroup>
) => {
  return useQuery<IGetCumulativeResultGroup>(
    ["students", "class_report_match"],
    () => get(`/results/cumulativegroups/${sessionId}`),
    { ...(options || {}) }
  );
};

//Fetches the list of matching student with result data for the session, term and calss.
export const useClassResultStudents = (
  sessionId: string,
  termId: string,
  classId: string,
  options?: QueryOptions<IClassResultStudentMatchResponse>
) => {
  return useQuery<IClassResultStudentMatchResponse>(
    ["results", "match", sessionId, termId, classId],
    () => get(`/results/match/${sessionId}/${termId}/${classId}`),
    { ...(options || {}),  enabled: !!(sessionId && termId && classId) }
  );
};


