export const viewCategoriesUrl = () => "/dashboard/categories";
export const viewCategoryDetailsUrl = (categoryId: number | string) =>
  `/dashboard/categories/${categoryId}`;
export const viewQuestionsUrl = (categoryId: number | string) =>
  `/dashboard/categories/${categoryId}`;
export const addQuestionsUrl = (categoryId: number | string) =>
  `/dashboard/categories/${categoryId}/questions`;
export const editQuestionUrl = (questionId: number | string) =>
  `/dashboard/categories/questions/${questionId}/edit`;
export const viewStudentsUrl = () => "/dashboard/students";
export const viewStudentGroupsUrl = () => "/dashboard/groups";
export const addAssessmentsUrl = () => "/dashboard/assessments/add";
export const viewAssessmentsUrl = () => "/dashboard/assessments";
export const viewAssessmentDetailsUrl = (id: string | number) =>
  `/dashboard/assessments/${id}/details`;
export const viewEditAssessmentUrl = (id: string | number) =>
  `/dashboard/assessments/${id}/edit`;
export const attemptAssessmentUrl = (assessmentId: string | number) =>
  `/dashboard/attempt/${assessmentId}`;
export const dashboardHomeUrl = () => "/dashboard/home";
export const viewAccountProfileUrl = () => "/dashboard/account/profile";
export const viewAccountAddUserUrl = () => "/dashboard/account/add_user";
export const viewAccountUpdateUserUrl = () => "/dashboard/account/update_user";
export const viewAccountListUserUrl = () => "/dashboard/account/list_user";
export const viewChangePasswordUrl = () => "/dashboard/account/change_password";
export const viewAddStudentUrl = () => "/dashboard/students/add_students";
export const viewListStudentUrl = () => "/dashboard/students/list_student";
export const viewStudentByClassUrl = () =>
  "/dashboard/students/student_by_class";
export const viewSettingstUrl = () => "/dashboard/settings";
export const classReportUrl = () => "/dashboard/report/class_report";
export const subjectReportUrl = () => "/dashboard/report/subject_report";
export const studentReportUrl = () => "/dashboard/report/student_report";
export const reportGroupUrl = () => "/dashboard/report/group_report";
export const nurseryClassReportUrl = () =>
  "/dashboard/report/nursery_class_report";
export const nurserySubjectReportUrl = () =>
  "/dashboard/report/nursery_subject_report";
export const nurseryStudentReportUrl = () =>
  "/dashboard/report/nursery_student_report";
export const classResultUrl = () => "/dashboard/result/class";
export const studentResultUrl = () => "/dashboard/result/student";
export const cumulativeResultUrl = () => "/dashboard/result/cumulative_result";
export const nurseryClassResultUrl = () =>
  "/dashboard/result/nursery_class_result";
export const nurseryStudentResultUrl = () =>
  "/dashboard/result/nursery_student_result";
export const resultPinUrl = () => "/dashboard/result/result_pin";
export const viewResultPinUrl = () => "/dashboard/result/list_result_pin";
export const logVisitorsUrl = () => "/dashboard/visitors/log_visitors";
export const checkinVisitorsUrl = () => "/dashboard/visitors/checkin_visitors";
export const VisitorsHistoryUrl = () => "/dashboard/visitors/visitors_history";
