import { useCallback, useEffect } from "react";


 export const useEscapeKey = (handleClose) => {
    const handleEscKey = useCallback((event) => {
    if (event.key === "Escape") {
      handleClose();
    }
  }, [handleClose]);
 
  useEffect(() => {
    document.addEventListener("keyup", handleEscKey, false);
 
    return () => {
      document.removeEventListener("keyup", handleEscKey, false);
    };
  }, [handleEscKey]);
}