
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import PageLayout from "components/PageLayout";
import React, { useState } from "react";
import { GenericAPIResponse } from "types/generic";
import { useAddClassMutation, useDeleteClass, useEditClassMutation, useListClasses } from "hooks/class";
import { IClassValues } from "types/classes";
import ClassTable from "./Table";
import AddClassForm from "./ClassForm";
import DeleteModal from "components/DeleteModal";
import { Fab } from "@mui/material";
import { Add } from "@mui/icons-material";
const Class = () => {

  const { isLoading, isFetching, isError, refetch, data}   = useListClasses();

  const [getClassId, setGetClassId] = useState<number>(0);
  const [openClassModal, setClassModalOpen] = useState(false);
  const [openEditClassModal, setEditClassModalOpen] = useState(false);
  const [openDeleteAcctModal, setOpenDeleteAcctModal] = useState(false);
  const [editClassObj, setEditClassObj] = useState<IClassValues>();


  const handleSubmitError = (error?: AxiosError) => {
    const data = error || {
      message: "We couldn't process your request! Try again",
    };
    toast(data.message, { type: "error" });
  };

  const handleSubmitSuccess = (data: GenericAPIResponse<IClassValues>) => {
    if (data.code === 0) {
      handleSubmitError();
    } else {
      toast("Class added successfully!", { type: "success" });
      setClassModalOpen(false);
      refetch();
    }
  };

  const { mutateAsync: submitAddClassPostRequest } = useAddClassMutation({
    onError: (error: AxiosError) => handleSubmitError(error), //Error handle tech debt to keep API the way it is.
    onSuccess: (data: GenericAPIResponse<any>) => handleSubmitSuccess(data),
  });

  // Handle update Class
  const { mutateAsync: submitEditClassRequest } = useEditClassMutation({
    onError: (error: AxiosError) => handleSubmitError(error), //Error handle tech debt to keep API the way it is.
    onSuccess: (data: GenericAPIResponse<any>) => handleEditSuccess(data),
  });

  // Handle Delete Class
  const { mutateAsync: deleteClassRequest } = useDeleteClass({
    onError: (error: AxiosError) => handleSubmitError(error), //Error handle tech debt to keep API the way it is.
    onSuccess: (data: GenericAPIResponse<any>) => handleDeleteSuccess(data),
  });

  const handleEditSuccess = (data: GenericAPIResponse<IClassValues>) => {
    if (data.code === 0) {
      handleSubmitError();
    } else {
      // success toast
      toast("Class updated successfully!", { type: "success" });
      setEditClassModalOpen(false);
      // refresh the data for account details.
      refetch();
    }
  };

  const handleDeleteSuccess = (data: GenericAPIResponse<IClassValues>) => {
    //set cookie
    if (data.code === 0) {
      handleSubmitError();
    } else {
      // success toast
      toast("Class deleted successfully!", { type: "success" });
      setOpenDeleteAcctModal(false);
      // refresh the data for account details.
      refetch();
    }
  };

  const handleOpenModal = () => {
    setClassModalOpen(true);
  };


  return (
    <PageLayout
      loading={isLoading || isFetching}
      error={isError}
      onRetry={refetch}
    >
      <ClassTable
        openAddClassModal={handleOpenModal}
        classes={data?.data || []}
        onDeleteClass={(setId) => {
          setOpenDeleteAcctModal(true);
          setGetClassId(setId);
        }}
        onUpdateClass={(vals) => {
          setEditClassObj(vals);
          setEditClassModalOpen(true);
        }}
      />
      {openClassModal && (
        <AddClassForm
          open={true}
          handleClose={() => setClassModalOpen(false)}
          onSubmit={submitAddClassPostRequest}
        />
      )}

      {openEditClassModal && (
        <AddClassForm
          open={true}
          isEdit
          data={editClassObj}
          handleClose={() => setEditClassModalOpen(false)}
          onSubmit={submitEditClassRequest}
        />
      )}

      {openDeleteAcctModal && (
        <DeleteModal
          prompt="Do you want to delete this class?"
          onCancel={() => setOpenDeleteAcctModal(false)}
          onConfirm={async () => {
            await deleteClassRequest(getClassId);
          }}
          title="Delete Class"
          open={true}
        />
      )}
       <Fab
        onClick={() => handleOpenModal()}
        color="primary"
        style={{ position: "fixed", right: 40, bottom: 40 }}
        sx={{ display: { md: "none" } }}
      >
        <Add />
      </Fab>
    </PageLayout>
  )
}

export default Class
