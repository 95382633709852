import React from "react";
import PageLayout from "components/PageLayout";
import { useHistory, useLocation } from "react-router-dom";
import ResultTable from "./Table";
import { useCumulativeResultGroup } from "hooks/results";
import NoRecordFound from "components/NoRecordFound";
import { cumulativeResultUrl } from "config/urls";

const CumulativeResultGroup = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search).get("session");

  const {
    data: result,
    isLoading,
    isError,
    isFetching,
    refetch,
  } = useCumulativeResultGroup(params);

  const history = useHistory();

  return (
    <PageLayout
      loading={isLoading || isFetching}
      error={isError}
      onRetry={refetch}
    >
      {result?.data.length !== 0 ? (
        <ResultTable resultGroup={result?.data || []} />
      ) : (
        <NoRecordFound
          title="Cumulative Result Group"
          onCTAClicked={() => {
            history.push(cumulativeResultUrl());
          }}
        />
      )}
    </PageLayout>
  );
};

export default CumulativeResultGroup;
