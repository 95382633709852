import { toast } from "react-toastify";
import { IClassReportList } from "types/report";

export const isNotWithinRange = (values: Partial<IClassReportList>) => {
  if (!values.scores || values.scores === undefined) return [];

  for (let i = 0; i < values.scores.length; i++) {
    const first_ca = values.scores[i].first_ca || 0;
    const second_ca = values.scores[i].second_ca || 0;
    const third_ca = values.scores[i].third_ca || 0;
    const fourth_ca = values.scores[i].fourth_ca || 0;
    const exam = values.scores[i].exam || 0;

    if (first_ca < 0 || first_ca > 100 || isNaN(first_ca)) {
      toast.warning(
        "Score must be within 0 and 100 and must not be a negative value!"
      );
      return false;
    } else if (second_ca < 0 || second_ca > 100 || isNaN(second_ca)) {
      toast.warning(
        "Score must be within 0 and 100 and must not be a negative value!"
      );
      return false;
    } else if (third_ca < 0 || third_ca > 100 || isNaN(third_ca)) {
      toast.warning(
        "Score must be within 0 and 100 and must not be a negative value!"
      );
      return false;
    } else if (fourth_ca < 0 || fourth_ca > 100 || isNaN(fourth_ca)) {
      toast.warning(
        "Score must be within 0 and 100 and must not be a negative value!"
      );
      return false;
    } else if (exam < 0 || exam > 100 || isNaN(exam)) {
      toast.warning(
        "Score must be within 0 and 100 and must not be a negative value!"
      );
      return false;
    }
  }

  return true;
};
