import { useState } from 'react'
import Fab from '@mui/material/Fab'
import Box from '@mui/material/Box'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import StarsRoundedIcon from '@mui/icons-material/StarsRounded'
import { subscriptionData } from './Data'

interface ISubscriptionCardsProps {
  onChange: (value: string) => void
}

const SubscriptionCards = (props: ISubscriptionCardsProps) => {
  const [selected, setSelected] = useState('')

  const isButtonSelected = (value: string) => {
    if (selected === value) {
      return true
    }
  }

  const handleChange = (type: string) => {
    setSelected(type)
    props.onChange(type)
  }

  return (
    <RadioGroup
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        '& > :not(style)': {
          m: 1.2,
          width: 418,
          height: 95,
          elevation: 0,
          boxShadow: 0,
          borderRadius: 5
        }
      }}
      aria-labelledby='demo-radio-buttons-group-label'
      defaultValue=''
      name='radio-buttons-group'
    >
      {subscriptionData.map((data, index) => {
        return (
          <Fab
            sx={{
              background: '#fff',
              border: '1.8px solid hsl(214deg 25% 87%)'
            }}
            key={index}
            variant='extended'
          >
            <StarsRoundedIcon
              sx={{
                mr: 2,
                position: 'absolute',
                top: 25,
                left: 20,
                paddingLeft: 0.1,
                color: data.starColor,
                justifyContent: 'start',
                alignContent: 'start'
              }}
            />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                flex: 1,
                right: '-3rem'
              }}
            >
              <span style={{ color: '#45a595' }}>$</span>
              <div
                style={{
                  fontSize: '1.8rem',
                  fontFamily: 'Montserrat',
                  color: '#45a595',
                  justifyContent: 'start',
                  alignContent: 'start'
                }}
              >
                {data.value}
              </div>
              <span
                style={{
                  color: '#45a595',
                  marginRight: '2rem',
                  fontSize: '11px',
                  textTransform: 'lowercase'
                }}
              >
                {data.period}
              </span>

              <div
                style={{
                  position: 'relative',
                  textTransform: 'capitalize',
                  fontFamily: 'Montserrat',
                  display: 'flex',
                  flexDirection: 'column',
                  fontSize: '11px'
                }}
              >
                {data.plan}
              </div>
            </Box>

            <FormControlLabel
              sx={{
                paddingLeft: 44.3,
                paddingRight: 3.9,
                paddingY: 3,
                flex: 1,
                position: 'absolute',
                right: '0rem'
              }}
              value={data.valueI}
              control={<Radio />}
              label={data.label}
              checked={isButtonSelected(data.value)}
              onChange={() => handleChange(data.type)}
              labelPlacement='start'
            />
          </Fab>
        )
      })}
    </RadioGroup>
  )
}

export default SubscriptionCards
