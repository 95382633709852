import { Grid } from "@mui/material";
import React from "react";

interface IFieldContainer {
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  children: React.ReactNode | React.ReactNode[];
}

/* Renders a responsive grid item container for input fields  */
const FieldContainer = (props: IFieldContainer) => {
  const { xs = 12, sm = 6, md = 6, lg = 4, children } = props;

  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg}>
      {children}
    </Grid>
  );
};

export default FieldContainer;
