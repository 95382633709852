import { Box, Tab, Tabs } from '@mui/material'
import {
  SubcriptionContainer,
  Content,
  Title,
  Layout,
  CardsContainer
} from './SubscriptionStyle'
import SubscriptionCards from './SubscriptionCards'
import Plans from './Plans'
import { useState } from 'react'

const SignUp = () => {
  const [selectedPlanType, setSelectedPlanType] = useState('basic')
  return (
    <Layout>
      <SubcriptionContainer>
        <Content>
          <Title>Choose a plan</Title>
          <Box mt={2}>
            <div style={{ padding: '0px 0px 0px 30px' }}>
              Take your desired plan to get access to our content easily, we
              like to offer special license offer to our users
            </div>
          </Box>
          <Plans
            selectedType={selectedPlanType}
            onChange={type => setSelectedPlanType(type)}
          />
        </Content>
      </SubcriptionContainer>
      <CardsContainer>
        <SubscriptionCards onChange={type => setSelectedPlanType(type)} />
      </CardsContainer>
    </Layout>
  )
}

export default SignUp
