import { Button, ButtonGroup } from "@mui/material";
import { SubmitButton } from "components/Common";
import Table, { RowData } from "components/Table";
import { Link } from "react-router-dom";
import { IReportGroup } from "types/report";

interface ISessionTableProps {
  reportGroup: IReportGroup[];
}

const ReportGroupTable = (props: ISessionTableProps) => {
  const { reportGroup, } = props;

  return (
    <Table
      title={{ label: "Manage Report Group" }}
      disableSideSheet={true}
      rows={reportGroup}
      columns={[
        {
          id: "session",
          label: "Session",
            renderer: (item: RowData) => {
              const value = item as IReportGroup;
              return (
                <>{value.session.session}</>
              );
            },
         
        },

        {
            id: "term",
            label: "Term",
            renderer: (item: RowData) => {
                const value = item as IReportGroup;
                return (
                  <>{value.term.term}</>
                );
              },
          },
  
        {
          id: "status",
          label: "Status",
          renderer: (item: RowData) => {
            const value = item as IReportGroup;
            const statusTitleCase = value.status
              .toLowerCase()
              .split(' ')
              .map(word => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' ');
          
            return <>{statusTitleCase}</>;
          },
        },

        {
          id: "actions",
          label: "Action",
          renderer: (item: RowData) => {
            const value = item as IReportGroup;
            return (
              <Link
              to={`/dashboard/report/reportgroupbyclass?session=${value.session.id}&term=${value.term.id}`}
             
            >
                <Button
                style={{ whiteSpace: "nowrap" }}
                type="submit"
                variant="outlined"
              >
               
                  Generate by class
                 
              </Button>
              </Link> 
            );
          },
        },
      ]}
    />
  );
};

export default ReportGroupTable;
