import PageLayout from "components/PageLayout";
import { useReportGroupByClass } from "hooks/reports";
import React from "react";
import { useLocation } from "react-router-dom";
import GenerateReportGroupTable from "./Table";
import PageListItem from "../subject/list/SubjectDetails";
const ReportGroupbyClass = () => {
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const { data, isLoading, isFetching, isError, refetch } =
    useReportGroupByClass(
      searchParams.get("session"),
      searchParams.get("term")
    );

  return (
    <PageLayout
      loading={isLoading || isFetching}
      error={isError}
      onRetry={refetch}
    >
      <PageListItem
        values={[
          `Session: ${data?.session?.session || "-"}`,
          `Term: ${data?.term?.term || ""}`,
        ]}
      />

      <GenerateReportGroupTable
        title={`Matching Classes`}
        groups={data?.groups || []}
      />
    </PageLayout>
  );
};

export default ReportGroupbyClass;
