import React from "react";
import { Box } from "@mui/material";
import ResetPasswordForm from "./ResetPasswordForm";
import { FormContainer, FormContent, Title, Layout } from "../Common";
import Header from "components/NavMenu/Header";
import { useHistory } from "react-router-dom";
import { useResetMutation } from "../../../hooks/auth/index";
import { AxiosError } from "axios";
import { GenericAPIResponse } from "../../../types/generic";
import { IAuthData } from "../../../types/auth";
import { toast } from "react-toastify";

const ResetPassword = () => {

  const history = useHistory();

  const { mutateAsync: submitResetPasswordRequest } = useResetMutation({
    onError: (error: GenericAPIResponse<IAuthData>) => handleAuthError(error), //Error handle tech debt to keep API the way it is.
    onSuccess: (data: GenericAPIResponse<IAuthData>) => handleAuthSuccess(data),
  });

  const handleAuthError = (error: GenericAPIResponse<IAuthData>) => {
    const data = error || {
      message: "We couldn't process your request! Try again",
    };
    toast(data.message, { type: "error" });
  };

  const handleAuthSuccess = (data: GenericAPIResponse<IAuthData>) => {
    
    if (data.code === 0) {
      handleAuthError(data);
    } else {
      history.push("/");
    }
  };

    return (
      <>
      <Header />
      <Layout>
        <FormContainer>
          <FormContent style={{ marginTop: 32 }}>
            <Title>Reset Password</Title>
            <Box mt={2}>
              <ResetPasswordForm onSubmit={submitResetPasswordRequest} />
            </Box>
          </FormContent>
        </FormContainer>
      </Layout>
    </>
  );
};

export default ResetPassword;