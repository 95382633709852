import React from "react";
import EditReportDetails from "./EditReportDetails";
import { GenericAPIResponse } from "types/generic";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { useSaveReportMutation } from "hooks/reports";

const EditReport = ({ row, renderAsNursery }: {row : any, renderAsNursery? : boolean}) => {
  const { mutateAsync: saveReportDetails } = useSaveReportMutation({
    onError: (error: AxiosError) => handleSubmitError(error),
    onSuccess: (data: GenericAPIResponse<any>) => handleSubmitSuccess(data),
  });

  const handleSubmitError = (error?: AxiosError) => {
    const data = error || {
      message: "We couldn't process your request! Try again",
    };
    toast(data.message, { type: "error" });
  };

  const handleSubmitSuccess = (data: GenericAPIResponse<any>) => {
    if (data.code === 1) {
      toast("Student record details saved successfully!", { type: "success" });
    } else {
      handleSubmitError();
    }
  };

  return (
    <>
      <EditReportDetails renderAsNursery={renderAsNursery} row={row} onSubmit={saveReportDetails} />
    </>
  );
};

export default EditReport;
