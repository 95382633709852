import Box from '@mui/material/Box'
import TabContext from '@mui/lab/TabContext'
import TabPanel from '@mui/lab/TabPanel'
import { VerifiedOutlined } from '@mui/icons-material'
import FileDownloadDoneIcon from '@mui/icons-material/FileDownloadDone'
import {
  subscriptionInfoBasic,
  subscriptionInfoStarter,
  subscriptionInfoPremium
} from './Data'
import { SubscribeButtonMobile } from './SubscriptionStyle'
import { Tab } from '@mui/material'
import TabList from '@mui/lab/TabList'
import { useEffect, useState } from 'react'

interface ILabTabsProps {
  selectedType: string
  onChange: (value: string) => void
}

export default function LabTabs (props: ILabTabsProps) {
  const [matches, setMatches] = useState(
    window.matchMedia('(max-width: 1198px)').matches
  )

  useEffect(() => {
    window
      .matchMedia('(max-width: 1198px)')
      .addEventListener('change', e => setMatches(e.matches))
  }, [])

  const [value, setValue] = useState(props.selectedType || 'starter')

  useEffect(() => {
    setValue(props.selectedType)
  }, [props.selectedType])

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }
  return (
    <Box
      sx={{
        width: '100%',
        typography: 'body1',
        paddingTop: '4rem',
        position: 'relative'
      }}
    >
      <TabContext value={value}>
        {matches && (
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              alignContent: 'center',
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex'
            }}
          >
            <TabList onChange={handleChange} aria-label='lab API tabs example'>
              <Tab label='starter' value='starter' />
              <Tab label='basic' value='basic' />
              <Tab label='premium' value='premium' />
            </TabList>
          </Box>
        )}
        <TabPanel value='starter'>
          <Box
            sx={{
              bgcolor: 'background.paper',
              position: 'relative',
              padding: '2rem 1rem 2rem 1rem',
              minHeight: 200,
              borderRadius: 5,
              border: '1.8px solid hsl(214deg 25% 87%)',
              display: 'flex',
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
              // width: '100%'
            }}
          >
            {subscriptionInfoStarter.map((subInfo, index) => {
              return (
                <Box
                  sx={
                    {
                      // alignItems: 'flex-end',
                      // justifyContent: 'flex-end'
                    }
                  }
                  key={index}
                >
                  <div
                    style={{
                      display: subInfo.display,
                      color: '#45a595',
                      fontWeight: 600,
                      paddingTop: 6,
                      paddingBottom: 18,
                      fontSize: '18px',
                      fontStyle: 'Montserrat',
                      textTransform: 'uppercase'
                    }}
                  >
                    <VerifiedOutlined
                      style={{
                        color: '#45a595'
                      }}
                    />
                    {subInfo.type}

                    <span
                      style={{ paddingLeft: '1rem', fontFamily: 'Montserrat' }}
                    >
                      {subInfo.price}
                    </span>
                  </div>
                  <span
                    style={{
                      fontFamily: 'Montserrat',
                      fontSize: '14px',
                      color: '#70757a'
                    }}
                  >
                    {subInfo.info}
                  </span>
                  <FileDownloadDoneIcon
                    style={{
                      color: '#45a595',
                      fontSize: '20px',
                      position: 'relative',
                      top: '3px',
                      display: subInfo.icon
                    }}
                  />
                </Box>
              )
            })}
            <Box flexDirection='row' textAlign='center'>
              <SubscribeButtonMobile type='submit' variant='contained'>
                subscribe
              </SubscribeButtonMobile>
            </Box>
          </Box>
        </TabPanel>
        <TabPanel value='basic'>
          <Box
            sx={{
              bgcolor: 'background.paper',
              position: 'relative',
              padding: '2rem 1rem 2rem 1rem',
              minHeight: 200,
              borderRadius: 5,
              border: '1.8px solid hsl(214deg 25% 87%)',
              display: 'flex',
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            {subscriptionInfoBasic.map((subInfo, index) => {
              return (
                <Box
                  sx={{
                    alignItems: 'flex-end',
                    justifyContent: 'flex-end'
                  }}
                  key={index}
                >
                  <div
                    style={{
                      display: subInfo.display,
                      color: '#45a595',
                      fontWeight: 600,
                      paddingTop: 6,
                      paddingBottom: 18,
                      fontSize: '18px',
                      fontStyle: 'Montserrat',
                      textTransform: 'uppercase'
                    }}
                  >
                    <VerifiedOutlined
                      style={{
                        color: '#45a595'
                      }}
                    />
                    {subInfo.type}

                    <span
                      style={{ paddingLeft: '1rem', fontFamily: 'Montserrat' }}
                    >
                      {subInfo.price}
                    </span>
                  </div>
                  <span
                    style={{
                      fontFamily: 'Montserrat',
                      fontSize: '14px',
                      color: '#70757a'
                    }}
                  >
                    {subInfo.info}
                  </span>
                  <FileDownloadDoneIcon
                    style={{
                      color: '#45a595',
                      fontSize: '20px',
                      position: 'relative',
                      top: '3px',
                      display: subInfo.icon
                    }}
                  />
                </Box>
              )
            })}
            <Box flexDirection='row' textAlign='center'>
              <SubscribeButtonMobile type='submit' variant='contained'>
                subscribe
              </SubscribeButtonMobile>
            </Box>
          </Box>
        </TabPanel>
        <TabPanel value='premium'>
          <Box
            sx={{
              bgcolor: 'background.paper',
              position: 'relative',
              padding: '2rem 1rem 2rem 1rem',
              minHeight: 200,
              borderRadius: 5,
              border: '1.8px solid hsl(214deg 25% 87%)',
              display: 'flex',
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            {subscriptionInfoPremium.map((subInfo, index) => {
              return (
                <Box
                  sx={{
                    alignItems: 'flex-end',
                    justifyContent: 'flex-end'
                  }}
                  key={index}
                >
                  <div
                    style={{
                      display: subInfo.display,
                      color: '#45a595',
                      fontWeight: 600,
                      paddingTop: 6,
                      paddingBottom: 18,
                      fontSize: '18px',
                      fontStyle: 'Montserrat',
                      textTransform: 'uppercase'
                    }}
                  >
                    <VerifiedOutlined
                      style={{
                        color: '#45a595'
                      }}
                    />
                    {subInfo.type}

                    <span
                      style={{ paddingLeft: '1rem', fontFamily: 'Montserrat' }}
                    >
                      {subInfo.price}
                    </span>
                  </div>
                  <span
                    style={{
                      fontFamily: 'Montserrat',
                      fontSize: '14px',
                      color: '#70757a'
                    }}
                  >
                    {subInfo.info}
                  </span>
                  <FileDownloadDoneIcon
                    style={{
                      color: '#45a595',
                      fontSize: '20px',
                      position: 'relative',
                      top: '3px',
                      display: subInfo.icon
                    }}
                  />
                </Box>
              )
            })}
            <Box flexDirection='row' textAlign='center'>
              <SubscribeButtonMobile type='submit' variant='contained'>
                subscribe
              </SubscribeButtonMobile>
            </Box>
          </Box>
        </TabPanel>
      </TabContext>
    </Box>
  )
}
