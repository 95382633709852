import PageLayout from "components/PageLayout";
import { toast } from "react-toastify";
import React, { useState } from "react";
import { GenericAPIResponse } from "types/generic";
import { AxiosError } from "axios";
import {
  useAddSubjectMutation,
  useDeleteSubject,
  useEditSubjectMutation,
  useListSubjects,
} from "hooks/subject";
import SubjectsTable from "./Table";
import { ISubjectValues, SubjectFormVals } from "types/subject";
import DeleteModal from "components/DeleteModal";
import { useListClasses } from "hooks/class";
import AddSubjectForm from "./AddSubjectForm";
import { Fab } from "@mui/material";
import { Add } from "@mui/icons-material";

const Subject = () => {
  const { isLoading, isFetching, isError, refetch, data } = useListSubjects();
  const { data: classes } = useListClasses();

  const [getSubjectId, setGetSubjectId] = useState<number>(0);
  const [openSubjectModal, setSubjectModalOpen] = useState(false);
  const [openEditSubjectModal, setEditSubjectModalOpen] = useState(false);
  const [openDeleteAcctModal, setOpenDeleteAcctModal] = useState(false);
  const [editSubjectObj, setEditSubjectObj] = useState<SubjectFormVals>();

  const handleSubmitError = (error?: AxiosError) => {
    const data = error || {
      message: "We couldn't process your request! Try again",
    };
    toast(data.message, { type: "error" });
  };

  const handleSubmitSuccess = (data: GenericAPIResponse<ISubjectValues>) => {
    if (data.code === 0) {
      handleSubmitError();
    } else {
      toast("Subject added successfully!", { type: "success" });
      setSubjectModalOpen(false);
      refetch();
    }
  };

  const { mutateAsync: submitAddSubjectPostRequest } = useAddSubjectMutation({
    onError: (error: AxiosError) => handleSubmitError(error), //Error handle tech debt to keep API the way it is.
    onSuccess: (data: GenericAPIResponse<any>) => handleSubmitSuccess(data),
  });

  // Handle update Subject
  const { mutateAsync: submitEditSubjectRequest } = useEditSubjectMutation({
    onError: (error: AxiosError) => handleSubmitError(error), //Error handle tech debt to keep API the way it is.
    onSuccess: (data: GenericAPIResponse<any>) => handleEditSuccess(data),
  });

  // Handle Delete Subject
  const { mutateAsync: deleteSubjectRequest } = useDeleteSubject({
    onError: (error: AxiosError) => handleSubmitError(error), //Error handle tech debt to keep API the way it is.
    onSuccess: (data: GenericAPIResponse<any>) => handleDeleteSuccess(data),
  });

  const handleEditSuccess = (data: GenericAPIResponse<ISubjectValues>) => {
    if (data.code === 0) {
      handleSubmitError();
    } else {
      // success toast
      toast("Subject updated successfully!", { type: "success" });
      setEditSubjectModalOpen(false);
      // refresh the data for account details.
      refetch();
    }
  };

  const handleDeleteSuccess = (data: GenericAPIResponse<ISubjectValues>) => {
    //set cookie
    if (data.code === 0) {
      handleSubmitError();
    } else {
      // success toast
      toast("Subject deleted successfully!", { type: "success" });
      setOpenDeleteAcctModal(false);
      // refresh the data for account details.
      refetch();
    }
  };

  const handleOpenModal = () => {
    setSubjectModalOpen(true);
  };

  return (
    <PageLayout
      loading={isLoading || isFetching}
      error={isError}
      onRetry={refetch}
    >
      <SubjectsTable
        openAddSubjectModal={handleOpenModal}
        subjects={data?.data || []}
        onDeleteSubject={(setId) => {
          setOpenDeleteAcctModal(true);
          setGetSubjectId(setId);
        }}
        onUpdateSubject={(vals) => {
          setEditSubjectObj(vals);
          setEditSubjectModalOpen(true);
        }}
      />

      {openSubjectModal && (
        <AddSubjectForm
          open={true}
          classes={classes?.data}
          handleClose={() => setSubjectModalOpen(false)}
          onSubmit={submitAddSubjectPostRequest}
        />
      )}

      {openEditSubjectModal && (
        <AddSubjectForm
          open={true}
          classes={classes?.data}
          isEdit
          data={editSubjectObj}
          handleClose={() => setEditSubjectModalOpen(false)}
          onSubmit={submitEditSubjectRequest}
        />
      )}

      {openDeleteAcctModal && (
        <DeleteModal
          prompt="Do you want to delete this subject?"
          onCancel={() => setOpenDeleteAcctModal(false)}
          onConfirm={async () => {
            await deleteSubjectRequest(getSubjectId);
          }}
          title="Delete Subject"
          open={true}
        />
      )}
      <Fab
        onClick={() => handleOpenModal()}
        color="primary"
        style={{ position: "fixed", right: 40, bottom: 40 }}
        sx={{ display: { md: "none" } }}
      >
        <Add />
      </Fab>
    </PageLayout>
  );
};

export default Subject;
