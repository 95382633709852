import styled from "styled-components";
import SegmentIcon from "@mui/icons-material/Segment";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Desktop } from "../Breakpoints/BreakPoints";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Buttons } from "components/Button/Buttons";
import { DropDown } from "./DropDown";
import { Link } from "react-router-dom";

const HeaderWrap = styled("div")`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  ${Desktop({
    width: "90%",
    padding: "8px 0",
    height: "auto",
  })}
`;

const Brand = styled("div")`
  display: flex;
  width: 35%;
  justify-content: center;
  align-items: center;
  margin-left: 20px;

  img {
    width: 100%;
    height: auto;
  }

  ${Desktop({
    width: "250px",
    marginLeft: "0",
  })}
`;

const SubMenu = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  @media screen and (max-width: 960px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100vh;
    padding: 0px 20px 5rem 20px;
    overflow-x: hidden;
    overflow-y: scroll;
    text-align: left;
    position: fixed;
    transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    top: 58px;
    transform: translateX(
      ${(props) => (props.openMobileMenu === false ? "0%" : "200%")}
    );
    opacity: 1;
    background: #fff;
    z-index: 1000;
    position: fixed;

    ::-webkit-scrollbar {
      display: none;
    }
  }
`;

const Menu = styled("div")`
  padding-left: 10px;

  ${Desktop({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
    margin: "0",
    padding: "0",
  })}
`;

const MenuItems = styled("li")`
  margin-top: 20px;

  a {
    display: block;
    color: var(--primary);
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1.2;
  }

  a:hover {
    text-decoration: underline;
  }

  ${Desktop({
    margin: "0",

    "& a": {
      fontSize: "1rem",
    },
  })}
`;

const MobileMenu = styled("div")`
  margin-right: 20px;

  ${Desktop({
    display: "none",
  })}
`;

const MenuBtn = styled("button")`
  background-color: var(--secondary);
  display: flex;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  box-shadow: 0 0 30px 10px rgb(20 61 80 / 7%);

  .MuiSvgIcon-root {
    font-size: 25px;
    color: #fff;
  }
`;

const CloseBtn = styled("button")`
  width: 30px;
  height: 30px;
  background-color: #fff;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 0 30px 10px rgb(20 61 80 / 7%);

  .MuiSvgIcon-root {
    color: var(--secondary);
    font-size: 25px;
  }
`;

const HR = styled("hr")`
  width: 100%;
  margin: 1rem 0;
  background-color: #d8d7d7;
  border: none;
  height: 1px;

  ${Desktop({
    display: "none",
  })}
`;

const DropDownMenu = styled("div")`
  position: relative;

  .MuiSvgIcon-root {
    margin-left: 5px;
    transform: rotate(
      ${(props) => (props.dropDown === true ? "-180deg" : "0deg")}
    );
    transition: transform 250ms ease-in-out 0s,
      -webkit-transform 250ms ease-in-out 0s;
  }
`;

const Button = styled(Buttons)`
  .MuiSvgIcon-root {
    transform: rotate(
      ${(props) => (props.dropDown === true ? "-180deg" : "0deg")}
    );
    transition: transform 250ms ease-in-out 0s,
      -webkit-transform 250ms ease-in-out 0s;
  }
`;

const SubHeader = () => {
  const [clickMenu, setClickMenu] = useState(true);
  const [getDropDown, setGetDropDown] = useState(false);
  return (
    <HeaderWrap>
      <Brand>
        <Link to="/">
          <img src="/logo.png" alt="brand logo" style={{ height: 35 }} />
        </Link>
      </Brand>

      <SubMenu openMobileMenu={clickMenu}>
        {/* <SearchBox>
          <SearchInput placeholder="Search..." />
          <Search />
        </SearchBox> */}
        <Menu>
        <MenuItems>
            <a href="/signin">LOGIN</a>
          </MenuItems>
          <MenuItems>
            <a href="https://sms.cliqueapp.com.ng">SMS</a>
          </MenuItems>
          <MenuItems>
            <a href="https://lms.cliqueapp.com.ng">LMS</a>
          </MenuItems>
          <MenuItems>
            <a href="https://cbt.clique.ng">CBT</a>
          </MenuItems>
          <MenuItems>
            <a target="__blank" href="https://academy.clique.ng">
              VIRTUAL ACADEMY
            </a>
          </MenuItems>
          <MenuItems>
            <a href="https://marketplace.cliqueapp.com.ng">BOOK TUTORS</a>
          </MenuItems>
        </Menu>

        <HR />

        <DropDownMenu>
          <Buttons
            btnVariant="btn-primary"
            type="button"
            dropDown={getDropDown}
            onClick={() => {
              setGetDropDown(!getDropDown);
            }}
          >
            <span style={{ paddingLeft: "8px" }}>SIGN IN</span>
            <KeyboardArrowDownIcon />
          </Buttons>

          {getDropDown && <DropDown />}
        </DropDownMenu>
        <Buttons
          onClick={() => window.open("/signup", "_self")}
          btnVariant="btn-secondary"
        >
          TRY FOR FREE
        </Buttons>
      </SubMenu>

      <MobileMenu onClick={() => setClickMenu(!clickMenu)}>
        {clickMenu ? (
          <MenuBtn>
            <SegmentIcon />
          </MenuBtn>
        ) : (
          <CloseBtn>
            <CloseIcon />
          </CloseBtn>
        )}
      </MobileMenu>
    </HeaderWrap>
  );
};

export default SubHeader;
