import { useMemo } from "react";
import Table, { RowData } from "components/Table";
import { studentRecords } from "../../components/Table/types";
import SampleComponent from "./SampleComponent";

interface IStudentRecordsProps {
  records: studentRecords[];
}

const TableTest = ({ records }: IStudentRecordsProps) => {
  const data = useMemo(() => {
    return records.map((value, pos) => ({
      ...value,
      index: pos + 1,
    }));
  }, [records]);

  return (
    <Table
      sideSheetWidth="436px"
      title={{
        label: "Student Records",
      }}
      columnPerBreakpoint={{
        sm: ["index", "name", "class"],
        md: ["index", "name", "intelligence", "score"],
      }}
      rows={data}
      render={(row) => {
        return <SampleComponent row={row} />;
      }}
      columns={[
        {
          id: "index",
          label: "S/N",
        },
        {
          id: "name",
          label: "Name",
        },
        {
          id: "class",
          label: "CLASS",
        },

        {
          id: "intelligence",
          label: "INTELLIGENCE QUOTIENT",
        },

        {
          id: "score",
          label: "SCORE",
        },
        {
          id: "remark",
          label: "Remarks",
          renderer: (item: RowData) => {
            const rowItem = item as studentRecords;
            return <b>{rowItem.remark.description || "-"}</b>;
          },
        },
      ]}
    />
  );
};

export default TableTest;
