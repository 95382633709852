import { Button, ButtonGroup } from "@mui/material";
import Table, { RowData } from "components/Table";
import DeleteIcon from "@mui/icons-material/Delete";
import Edit from "@mui/icons-material/Edit";
import { Class, IClassValues } from "types/classes";

interface IClassTableProps {
  classes: Class[];
  onUpdateClass?: (values: IClassValues) => any;
  onDeleteClass?: (classID: number) => any;
  openAddClassModal: () => void;
}

const ClassTable = (props: IClassTableProps) => {
  const { classes, openAddClassModal, onUpdateClass, onDeleteClass } =
    props;

  return (
    <Table
      title={{ label: "Manage Classes" }}
      disableSideSheet={true}
      ctaLabel="Add Class"
      onCTAClicked={openAddClassModal}
      rows={classes}
      columns={[
        {
          id: "class",
          label: "Class",
        },
        {
          id: "section",
          label: "Section",
        },

        {
          id: "actions",
          label: "Action",
          renderer: (item: RowData) => {
            const value = item as Class;
            return (
              <ButtonGroup size="small">
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    onDeleteClass?.(value.id);
                  }}
                  style={{ whiteSpace: "nowrap" }}
                  variant="outlined"
                >
                  
                  <DeleteIcon sx={{ "&:hover": { color: "#ff5722" } }} />
                </Button>

                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    onUpdateClass?.(value);
                  }}
                  style={{ whiteSpace: "nowrap" }}
                  variant="outlined"
                >
                  
                  <Edit sx={{ "&:hover": { color: "#ff5722" } }} />
                </Button>
              </ButtonGroup>
            );
          },
        },
      ]}
    />
  );
};

export default ClassTable;
