import React from "react";
import TextField from "components/Form/TextField";
import { TextFieldContainer, SubmitButton } from "../Common";
import { Box } from "@mui/material";
import { Form, Formik } from "formik";
import { IChangePasswordFormValues } from "types/auth";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  currentpassword: Yup.string().required("Current Password is required"),
  password: Yup.string()
    .min(7, "Password should be more than 6 characters")
    .required("New Password is required"),
  confirmpassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm new password"),
});

export interface IChangePasswordFormProps {
  onSubmit: (values: IChangePasswordFormValues) => Promise<any> | any;
}

const ChangePasswordForm = ({ onSubmit }: IChangePasswordFormProps) => {
  const initialValues: IChangePasswordFormValues = {
    currentpassword: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        await onSubmit(values);
        setSubmitting(false);
      }}
      validationSchema={validationSchema}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        isSubmitting,
      }) => (
        <Form>
          <TextFieldContainer>
            <TextField
              type="password"
              name="currentpassword"
              label="Current Password"
              variant="outlined"
              value={values.currentpassword || ""}
              error={!!(errors.currentpassword && touched.currentpassword)}
              helperText={errors.currentpassword}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </TextFieldContainer>

          <TextFieldContainer>
            <TextField
              type="password"
              name="password"
              label="New Password"
              variant="outlined"
              value={values.password || ""}
              error={!!(errors.password && touched.password)}
              helperText={errors.password}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </TextFieldContainer>

          <TextFieldContainer>
            <TextField
              type="password"
              name="confirmpassword"
              label="Confirm Password"
              variant="outlined"
              value={values.confirmpassword || ""}
              error={!!(errors.confirmpassword && touched.confirmpassword)}
              helperText={errors.confirmpassword}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </TextFieldContainer>

          <Box
            flexDirection="row"
            textAlign="center"
            style={{ marginTop: "2rem" }}
          >
            <SubmitButton
              disabled={isSubmitting}
              type="submit"
              variant="contained"
            >
              {isSubmitting ? "Please wait..." : "UPDATE PASSWORD"}
            </SubmitButton>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default ChangePasswordForm;
