import PageLayout from "components/PageLayout";
import { AxiosError } from "axios";
import {
  useAddsessionMutation,
  useDeleteSession,
  useEditSessionMutation,
  useListSessions,
} from "hooks/session";

import { toast } from "react-toastify";
import React, { useState } from "react";
import SessionsTable from "./Table";
import { GenericAPIResponse } from "types/generic";
import { IAddSessionValues } from "types/sessions";
import AddSessionForm from "./AddSessionForm";
import DeleteModal from "components/DeleteModal";
import { Fab } from "@mui/material";
import { Add } from "@mui/icons-material";

const Sessions = () => {
  const { isLoading, isFetching, isError, refetch, data } = useListSessions();

  const [getSessionId, setGetSessionId] = useState<number>(0);
  const [openSessionModal, setSessionModalOpen] = useState(false);
  const [openEditSessionModal, setEditSessionModalOpen] = useState(false);
  const [openDeleteAcctModal, setOpenDeleteAcctModal] = useState(false);
  const [editSessionObj, setEditSessionObj] = useState<IAddSessionValues>();

  const handleSubmitError = (error?: AxiosError) => {
    const data = error || {
      message: "We couldn't process your request! Try again",
    };
    toast(data.message, { type: "error" });
  };

  const handleSubmitSuccess = (data: GenericAPIResponse<IAddSessionValues>) => {
    if (data.code === 0) {
      handleSubmitError();
    } else {
      toast("Session added successfully!", { type: "success" });
      setSessionModalOpen(false);
      refetch();
    }
  };

  const { mutateAsync: submitAddSessionPostRequest } = useAddsessionMutation({
    onError: (error: AxiosError) => handleSubmitError(error), //Error handle tech debt to keep API the way it is.
    onSuccess: (data: GenericAPIResponse<any>) => handleSubmitSuccess(data),
  });

  // Handle update Session
  const { mutateAsync: submitEditSessionRequest } = useEditSessionMutation({
    onError: (error: AxiosError) => handleSubmitError(error), //Error handle tech debt to keep API the way it is.
    onSuccess: (data: GenericAPIResponse<any>) => handleEditSuccess(data),
  });

  // Handle Delete Session
  const { mutateAsync: deleteSessionRequest } = useDeleteSession({
    onError: (error: AxiosError) => handleSubmitError(error), //Error handle tech debt to keep API the way it is.
    onSuccess: (data: GenericAPIResponse<any>) => handleDeleteSuccess(data),
  });

  const handleEditSuccess = (data: GenericAPIResponse<IAddSessionValues>) => {
    if (data.code === 0) {
      handleSubmitError();
    } else {
      // success toast
      toast("Session updated successfully!", { type: "success" });
      setEditSessionModalOpen(false);
      // refresh the data for account details.
      refetch();
    }
  };

  const handleDeleteSuccess = (data: GenericAPIResponse<IAddSessionValues>) => {
    //set cookie
    if (data.code === 0) {
      handleSubmitError();
    } else {
      // success toast
      toast("Session deleted successfully!", { type: "success" });
      setOpenDeleteAcctModal(false);

      // refresh the data for account details.
      refetch();
    }
  };

  const handleOpenModal = () => {
    setSessionModalOpen(true);
  };

  return (
    <PageLayout
      loading={isLoading || isFetching}
      error={isError}
      onRetry={refetch}
    >
      <SessionsTable
        openAddSessionModal={handleOpenModal}
        sessions={data || []}
        onDeleteSession={(setId) => {
          setOpenDeleteAcctModal(true);
          setGetSessionId(setId);
        }}
        onUpdateSession={(vals) => {
          setEditSessionObj(vals);
          setEditSessionModalOpen(true);
        }}
      />
      {openSessionModal && (
        <AddSessionForm
          open={true}
          handleClose={() => setSessionModalOpen(false)}
          onSubmit={submitAddSessionPostRequest}
        />
      )}

      {openEditSessionModal && (
        <AddSessionForm
          open={true}
          isEdit
          data={editSessionObj}
          handleClose={() => setEditSessionModalOpen(false)}
          onSubmit={submitEditSessionRequest}
        />
      )}

      {openDeleteAcctModal && (
        <DeleteModal
          prompt="Do you want to delete this Session?"
          onCancel={() => setOpenDeleteAcctModal(false)}
          onConfirm={async () => {
            await deleteSessionRequest(getSessionId);
          }}
          title="Delete Session"
          open={true}
        />
      )}
       <Fab
        onClick={() => handleOpenModal()}
        color="primary"
        style={{ position: "fixed", right: 40, bottom: 40 }}
        sx={{ display: { md: "none" } }}
      >
        <Add />
      </Fab>
    </PageLayout>
  );
};

export default Sessions;
