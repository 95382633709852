import { Box, Button } from "@mui/material";
import ProfileImage from "components/ProfileImage";
import useS3Upload from "hooks/thirdparty/useS3Upload";
import { generateUUID } from "hooks/uuid";
import React, { useState } from "react";
import { GenericAPIResponse } from "types/generic";
import { IReportSettings } from "types/report";
import {
  useGetTeacherSignature,
  useUpdateTeacherSignatureMutation,
} from "hooks/resultSetting";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import DataLoader from "components/DataLoader";

const SignatureSetting = () => {
  const { isLoading, isFetching, isError, refetch, data } =
    useGetTeacherSignature();
  const { progress, uploadAsync } = useS3Upload();
  const [profileImage, setProfileImage] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [signatureImageFile, setSignatureImageFile] = useState<File | null>(
    null
  );

  const handleProfileUpload = (event) => {
    const file = event.target.files[0];
    setSignatureImageFile(file);
    const url = URL.createObjectURL(file);
    setProfileImage(url);
  };

  const handleSubmitError = (error?: AxiosError) => {
    const data = error || {
      message: "We couldn't process your request! Try again",
    };
    toast(data.message, { type: "error" });
  };

  const uploadFileToS3 = async () => {
    if (!signatureImageFile) return;
    const folder = "signature/";
    const currentFile = signatureImageFile;
    const fileName =
      folder + generateUUID() + currentFile.name.replaceAll(" ", "_");
    const path =
      "https://" +
      process.env.REACT_APP_AWS_BUCKET_NAME +
      ".s3.amazonaws.com/" +
      fileName;
    await uploadAsync(currentFile, fileName);
    setProfileImage(path);
    return path;
  };

  const handleEditSuccess = (data: GenericAPIResponse<IReportSettings>) => {
    if (data.code === 0) {
      handleSubmitError();
    } else {
      // success toast
      toast("New Signature updated successfully!", { type: "success" });
      refetch();
    }
  };

  const { mutateAsync: submitUpdateSignatureRequest } =
    useUpdateTeacherSignatureMutation({
      onError: (error: AxiosError) => handleSubmitError(error), //Error handle tech debt to keep API the way it is.
      onSuccess: (data: GenericAPIResponse<any>) => handleEditSuccess(data),
    });

  const handleUpload = async () => {
    setIsUploading(true);
    const pathUr = await uploadFileToS3();
    if (pathUr) {
      submitUpdateSignatureRequest(pathUr);
      setProfileImage("");
      setIsUploading(false);
    }
    setIsUploading(false);
  };
  if (isLoading || isFetching) {
    return <DataLoader />;
  } else {
    return (
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box marginTop="12px">
          <ProfileImage
            src={profileImage || data?.data?.signature}
            size="120px"
            type="curve"
            canChange={true}
            callback={handleProfileUpload}
            uploadProgress={progress}
            id="profileImage"
          />
        </Box>
        <Button
          onClick={handleUpload}
          disabled={isLoading || profileImage === "" || isUploading}
          variant="contained"
          style={{ marginTop: 32 }}
          fullWidth
        >
       { isUploading ? "Uploading..." : "Update Signature"}
        </Button>
      </Box>
    );
  }
};
export default SignatureSetting;
