import React from "react";
import Grades from "./Grades";
import { useGetGradeSettings, useGradeSystemMutation } from "hooks/settings";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { GenericAPIResponse } from "types/generic";
import { IGradeSystem } from "types/settings";
import DataLoaderError from "components/DataLoaderError";
import DataLoader from "components/DataLoader";

const SaveGrade = () => {
  const {
    data: grade,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useGetGradeSettings();

  const initialValues: IGradeSystem = {
    grades: isLoading || isError || isFetching ? [] : grade!,
  };

  const { mutateAsync: handleSubmitGrade } = useGradeSystemMutation({
    onError: (error: AxiosError) => handleSubmitError(error),
    onSuccess: (data: GenericAPIResponse<any>) => handleSubmitSuccess(data),
  });

  const handleSubmitError = (error?: AxiosError) => {
    const data = error || {
      message: "We couldn't process your request! Try again",
    };
    toast(data.message, { type: "error" });
  };

  const handleSubmitSuccess = (data: GenericAPIResponse<any>) => {
    if (data.code === 1) {
      toast("Settings saved successfully!", { type: "success" });
      refetch();
    } else {
      handleSubmitError();
    }
  };

  // Handling error when fetching previous data
  if (isLoading || isFetching) {
    return <DataLoader />;
  } else if (isError) {
    return (
      <DataLoaderError
        errorMessage="Loading Details Failed... "
        onRetry={() => refetch()}
      />
    );
  }

  return (
    <>
      <Grades onSubmit={handleSubmitGrade} initialValues={initialValues} />
    </>
  );
};

export default SaveGrade;
