import { post, get } from "api";
import { MutationOptions } from "hooks/types";
import { useMutation, useQuery, QueryOptions } from "react-query";
import { GenericAPIResponse } from "types/generic";
import {
  IRegisterStudentPostRequestValues,
  IStudentList,
} from "../../types/students";

// submit Add student post request
export const useAddStudentMutation = (
  options?: MutationOptions<GenericAPIResponse<any>>
) => {
  return useMutation(
    (value: IRegisterStudentPostRequestValues) =>
      post(`students/`, { ...value }),
    { ...(options || {}) }
  );
};

// get students list
export const useListStudents = (options?: QueryOptions<IStudentList[]>) => {
  return useQuery<IStudentList[]>(
    ["students", "school"],
    () => get("students/school"),
    { ...(options || {}) }
  );
};

// Update student avatar request
export const useUpdateStudentProfileImage = (
  options?: MutationOptions<GenericAPIResponse<any>>
) => {
  return useMutation(
    (value: { id: string; path: string }) =>
      post(`students/updatevatar`, { ...value }),
    { ...(options || {}) }
  );
};

// Update student avatar request
export const useUpdateStudentMutation = (
  options?: MutationOptions<GenericAPIResponse<any>>
) => {
  return useMutation(
    (value: IRegisterStudentPostRequestValues) =>
      post(`students/update`, { ...value }),
    { ...(options || {}) }
  );
};
