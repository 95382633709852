import React from "react";
import PageLayout from "components/PageLayout";
import { useSubjectReport } from "hooks/reports";
import { useHistory, useLocation } from "react-router-dom";
import SubjectDetails from "./SubjectDetails";
import SubjectListTable from "./Table";
import { FormikProvider, useFormik } from "formik";
import { IGetSubjectReportTypes, IPostSubjectReportType } from "types/report";
import NoRecordFound from "components/NoRecordFound";
import { subjectReportUrl } from "config/urls";
import { isNotWithinRange } from "./validation";
import { Box } from "@mui/material";
import { SubmitButton } from "views/auth/Common";
import PageListItem from "./SubjectDetails";

interface ISubmitRecordProps {
  renderAsNursery?: boolean
  onSubmit: (values: Partial<IPostSubjectReportType>) => Promise<any> | any;
}

const SubjectReportList = ({ onSubmit, renderAsNursery }: ISubmitRecordProps) => {
  const { search } = useLocation();

  //Get query parameter
  const [session, term, subject] = [
    new URLSearchParams(search).get("session"),
    new URLSearchParams(search).get("term"),
    new URLSearchParams(search).get("subject"),
  ];

  //Fetch class report details using the query parameter
  const {
    isLoading,
    isFetching,
    isError,
    data: subjectReports,
    refetch,
  } = useSubjectReport(session, term, subject);

  const initialValues: Partial<IGetSubjectReportTypes> = {
    session: subjectReports?.session,
    term: subjectReports?.term,
    subject: subjectReports?.subject,
    class: {
      class: subjectReports?.class?.class,
      section: subjectReports?.class?.section,
    },
    data: subjectReports?.data,
  };

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,

    onSubmit: async (
      value: Partial<IGetSubjectReportTypes>,
      { setSubmitting }
    ) => {
      const submitValues: Partial<IPostSubjectReportType> = {
        subject: Number(subject),
        session: Number(session),
        term: Number(term),
        reports: formik.values.data?.map((item) => {
          return {
            student_id: item.student.studentid,
            first_ca: item.result.first_ca || "",
            second_ca: item.result.second_ca || "",
            third_ca: item.result.third_ca || "",
            fourth_ca: item.result.fourth_ca || "",
            exam: item.result.exam || "",
          };
        }),
      };

      if (isNotWithinRange(formik.values)) {
        setSubmitting(true);
        await onSubmit(submitValues);
        setSubmitting(false);
      }
    },
  });

  const history = useHistory();

  return (
    <PageLayout
      loading={isLoading || isFetching}
      error={isError}
      onRetry={refetch}
    >
      <PageListItem
        values={[
          `Session: ${subjectReports?.session || '-'}`,
          `Term: ${subjectReports?.term || '-'}`,
          `Class: ${subjectReports?.class?.class || '-'} (${subjectReports?.class?.section || '-'})`,
          `Subject: ${subjectReports?.subject || '-'}`,
        ]}
      />
      {formik.values.data?.length !== 0 ? (
        <FormikProvider value={formik}>
          <form onSubmit={formik.handleSubmit}>
            <Box marginBottom="6rem">
              <SubjectListTable renderAsNursery={renderAsNursery} />
            </Box>

            <Box sx={navStyle}>
              <Box sx={navButtonStyle}>
                <SubmitButton
                  style={{ width: "100%" }}
                  disabled={formik.isSubmitting}
                  type="submit"
                  variant="contained"
                >
                  {formik.isSubmitting ? "Please wait..." : " Save"}
                </SubmitButton>
              </Box>
            </Box>
          </form>
        </FormikProvider>
      ) : (
        <NoRecordFound
          message="There is currently no registered students for this subject"
          title="Subject Report"
          onCTAClicked={() => {
            history.push(subjectReportUrl());
          }}
        />
      )}
    </PageLayout>
  );
};

export default SubjectReportList;

const navStyle = {
  display: "flex",
  justifyContent: "center",
  background: "white",
  position: "fixed",
  bottom: "0",
  right: "0",
  boxShadow: "10px 0px 8px rgb(0 0 0 / 30%)",
  zIndex: "10",
  width: "100%",
  padding: "8px",
};

const navButtonStyle = {
  display: "flex",
  justifyContent: "center",
  width: { xs: "50%", md: "30%" },
  marginLeft: { xs: 0, md: "calc(15% + 70px)" },
};
