import React from "react";
import PageLayout from "components/PageLayout";
import { useClassReportMatchList } from "hooks/reports";
import { useLocation } from "react-router-dom";
import ListTable from "views/report/list/Table";

const NurseryClassReportList = () => {
  const { search } = useLocation();
  const params = new URLSearchParams(search).get("class");

  const {
    data: students,
    isLoading,
    isError,
    isFetching,
    refetch,
  } = useClassReportMatchList(params);

  return (
    <PageLayout
      loading={isLoading || isFetching}
      error={isError}
      onRetry={refetch}
    >
      <ListTable renderAsNursery={true} students={students?.data || []} />
    </PageLayout>
  );
};

export default NurseryClassReportList;
