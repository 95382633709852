import { Box, Modal, Typography } from "@mui/material";
import { useState } from "react";
import { RoundedButton } from "./Common";
import ModalContainer from "./ModalContainer";

interface IConfirmationModalProps {
  prompt: React.ReactNode | string;
  title: string;
  onCancel?: Function;
  onConfirm?: () => Promise<void | any>;
  open: boolean;
}

const ConfirmationModal = ({
  title,
  prompt,
  onCancel,
  onConfirm,
  open,
}: IConfirmationModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Modal
      open={open}
      onClose={() => onCancel && onCancel()}
    >
      <ModalContainer title={title} handleClose={onCancel} style={{width: 380}}>
        <Typography variant="body2">{prompt}</Typography>

        <Box display="flex" flexDirection="row" style={{ marginTop: 32 }} textAlign="center">
          <RoundedButton
            onClick={() => {
              if (onConfirm) {
                setIsLoading(true);
                onConfirm().finally(() => {
                  setIsLoading(false);
                });
              }
            }}
            style={{ padding:"10px 40px"}}
            variant="contained"
            color="primary"
            disabled={isLoading}
            sx={{ mr: 4 }}
          >
            {isLoading? 'Loading...' : 'CONFIRM'}
          </RoundedButton>
          <RoundedButton
            style={{ minWidth: 150 }}
            variant="outlined"
            onClick={() => onCancel && onCancel()}
          >
            CANCEL
          </RoundedButton>
        </Box>
      </ModalContainer>
    </Modal>
  );
};

export default ConfirmationModal;
