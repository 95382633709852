import React from "react";
import TextField from "components/Form/TextField";
import { TextFieldContainer, SubmitButton } from "../Common";
import { Box } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { ILoginFormValues } from "types/auth";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Provide a valid email address")
    .required("Email address is required"),
  password: Yup.string()
    .min(4, "Password should be more than 4 characters")
    .required("Password is required"),
});

export interface ISignInFormProps {
  onSubmit: (values: ILoginFormValues) => Promise<any> | any;
}

//Renders the sign in form.
const SignInForm = ({ onSubmit }: ISignInFormProps) => {
  const initialValues: ILoginFormValues = {};

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        await onSubmit(values);
        setSubmitting(false);
      }}
      validationSchema={validationSchema}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        isSubmitting,
      }) => (
        <Form>
          <TextFieldContainer>
            <TextField
              type="text"
              name="email"
              error={!!(errors.email && touched.email)}
              helperText={errors.email}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email || ""}
              label="Your Email Address"
              variant="outlined"
            />
          </TextFieldContainer>

          <TextFieldContainer>
            <TextField
              type="password"
              name="password"
              error={!!(errors.password && touched.password)}
              helperText={errors.password}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password || ""}
              label="Password"
              variant="outlined"
            />
          </TextFieldContainer>

          <Box
            flexDirection="row"
            textAlign="center"
            sx={{ margin: "16px 0px" }}
          >
            <SubmitButton
              disabled={isSubmitting}
              type="submit"
              variant="contained"
            >
              {isSubmitting ? "Please wait..." : "Login"}
            </SubmitButton>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default SignInForm;
