import React, { useState } from "react";
import TextField from "components/Form/TextField";
import styles from "../../profile/profile.module.css";
import { Card, CardContent, CardHeader } from "@mui/material";
import { Box } from "@mui/system";

const MedicalInfo = ({ values, errors, touched, handleChange, handleBlur }) => {
  return (
    <Box marginBottom="32px">
      <Card sx={{".MuiCardHeader-root": { padding: "20px 0px 0px 14px" }, minWidth: 275,}}
      >
        <CardHeader sx={{"&.MuiCardHeader-title": { padding: "0px", marginBottom: "0" },}}
          title={<h2 className={styles.headline}>Medical Info</h2>}
        />

        <CardContent sx={{ "&.MuiCardContent-root": { padding: "0 16px 24px 16px" } }}>
          <Box className={styles.textFieldContainer}>
            <TextField
              type="text"
              name="medicalCondition"
              value={values.medicalCondition || ""}
              error={!!(errors.medicalCondition && touched.medicalCondition)}
              label="Briefly Outline Student Medical Condition and Upload Medical Report"
              id="outlined-textarea"
              multiline
              rows={5}
              helperText={errors.medicalCondition}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default MedicalInfo;
