import { Box } from "@mui/material";
import styled from "styled-components";

const List = styled("ul")`
  display: flex;
  background: #fff;
  padding: 4px 8px;
  box-shadow: 0 3px 6px rgb(0 0 0 / 12%);
  flex-wrap: wrap;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    width: 100%;

    li {
      padding: 4px 8px;
      border: none;
    }
  }
`;

const ListItem = styled("li")`
  padding: 8px 16px;
  color: var(--primary);
  text-transform: uppercase;
  border-right: 1px solid #b1b4b2;

  :last-child {
    border: none;
  }
`;

interface IPageListItemProps {
  values: string[];
}

const PageListItem = ({ values }: IPageListItemProps) => {
  return (
    <Box display="flex" justifyContent="center" marginBottom="32px">
      <List>
        {values.map((item, index) => (
          <ListItem key={index}>
            <h5>{item || '-'}</h5>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};
export default PageListItem;
