import { TextFieldContainer, SubmitButton } from "views/auth/Common";
import TextField from "components/Form/TextField";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Box, Dialog, DialogContent, IconButton } from "@mui/material";
import Chip from "@mui/material/Chip";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { SubjectFormVals } from "types/subject";
import { Class } from "types/classes";
import DialogTitle from "components/DialogTitle";
export interface SimpleDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}

const validationSchema = Yup.object().shape({
  subject: Yup.string().required("Subject name is required"),
  class: Yup.array().required("Please select a class from the dropdown"),
});

const EditValidationSchema = Yup.object().shape({
  subject: Yup.string().required("Subject name is required"),
  class: Yup.number().required("Please select a class from the dropdown"),
});

export interface ISubjectFormProps {
  onSubmit: (values: SubjectFormVals) => Promise<any> | any;
  open: boolean;
  isEdit?: boolean;
  data?: any;
  handleClose: Function;
  classes?: Class[];
}
const AddSubjectForm = ({
  onSubmit,
  open,
  handleClose,
  isEdit,
  data,
  classes,
}: ISubjectFormProps) => {
  const initialValues: SubjectFormVals = isEdit
    ? {
        subject: data?.subject,
        id: data?.id,
        class: data?.school,
      }
    : {
        subject: "",
        class: [],
      };

  const getClassValue = (index: number) => {
    const classObj = classes?.find((cls) => cls.id == index);
    return `${classObj?.class} (${classObj?.section})`;
  };

  return (
    <Dialog fullWidth={true} open={open} onClose={() => handleClose()}>
      <DialogTitle
        title={`${isEdit ? "Update" : "Add"} Subject`}
        onClose={() => handleClose()}
      />
      <DialogContent dividers style={{paddingTop: 0}}>
        <Formik
          initialValues={initialValues}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            await onSubmit(values);
            setSubmitting(false);
          }}
          validationSchema={isEdit ? EditValidationSchema : validationSchema}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
          }) => (
            <Form>
              <TextFieldContainer direction="column" style={{ gap: "24px" }}>
                <TextField
                  type="text"
                  name="subject"
                  label="Subject Name"
                  variant="outlined"
                  value={values.subject || ""}
                  error={!!(errors.subject && touched.subject)}
                  helperText={errors.subject}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                {isEdit ? (
                  <Box margin="0 5px">
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel id="status">Select Class</InputLabel>
                      <Select
                        type="text"
                        name="class"
                        error={!!(errors.class && touched.class)}
                        onChange={handleChange}
                        defaultValue={data?.class}
                        onBlur={handleBlur}
                        // value={values.class}
                        labelId="status"
                        id="demo-multiple-chip"
                        label="Select Class"
                      >
                        {classes?.map((cls) => {
                          return (
                            <MenuItem
                              value={cls.id}
                            >{`${cls.class} (${cls.section})`}</MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText>{errors.class}</FormHelperText>
                    </FormControl>
                  </Box>
                ) : (
                  <Box margin="0 5px">
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel id="status">Select Class</InputLabel>
                      <Select
                        type="text"
                        multiple
                        name="class"
                        error={!!(errors.class && touched.class)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        defaultValue={isEdit ? data?.class : []}
                        renderValue={(selected: any[]) => (
                          <Box
                            sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                          >
                            {selected?.map((val) => (
                              <Chip key={val} label={getClassValue(val)} />
                            ))}
                          </Box>
                        )}
                        value={values.class}
                        labelId="status"
                        id="demo-multiple-chip"
                        label="Select Class"
                      >
                        {classes?.map((cls) => {
                          return (
                            <MenuItem
                              value={cls.id}
                            >{`${cls.class} (${cls.section})`}</MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText>{errors.class}</FormHelperText>
                    </FormControl>
                  </Box>
                )}
              </TextFieldContainer>

              <Box
                flexDirection="row"
                textAlign="center"
                style={{ margin: "16px 0px" }}
              >
                <SubmitButton
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                >
                  {isSubmitting
                    ? "Please wait..."
                    : `${isEdit ? "Update" : "Add"} Subject`}
                </SubmitButton>
              </Box>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};
export default AddSubjectForm;
