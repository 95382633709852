import { Box } from "@material-ui/core";
import Table, { ITableColumn, RowData } from "components/Table";
import { IStudentList } from "types/students";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import EditReport from "../edit";

interface IStudentsTableProps {
  students: IStudentList[];
  /** Flag that controls the visibility of the edit action button for each student row. False by default */
  hideEditButton?: boolean;
  renderAsNursery?: boolean
  /** Controls visibility of columns */
  columnConfig?: {
    hidePhoneColumn?: boolean;
  };
  sideSheetRenderer?: (row: RowData) => React.ReactNode;
}

const ListTable = (props: IStudentsTableProps) => {
  const { students, hideEditButton, columnConfig, sideSheetRenderer , renderAsNursery} = props;

  const getCustomizableColumns = () => {
    const columns: ITableColumn = [];
    if (!columnConfig?.hidePhoneColumn) {
      columns.push({
        id: "phone",
        label: "PHONE",
        renderer: (item: RowData) => {
          const value = item as IStudentList;
          return <>{value?.phone ? value?.phone : value["guardian"]?.phone}</>;
        },
      });
    }
    return columns;
  };

  return (
    <Table
      sideSheetWidth="60%"
      title={{ label: "Matching Student" }}
      columnPerBreakpoint={{
        sm: ["admno", "firstname", "lastname", "action"],
      }}
      rows={students}
      render={(row) => {
        return <EditReport renderAsNursery={renderAsNursery} row={row as IStudentList} />;
      }}
      columns={[
        {
          id: "admno",
          label: "ADM No",
          renderer: (item: RowData) => {
            const value = item as IStudentList;
            return (
              <span
                style={{
                  textDecoration: "underline",
                  color: "#0aa89e",
                  cursor: "pointer",
                }}
              >
                {value?.admno}
              </span>
            );
          },
        },

        {
          id: "avatar",
          label: "Avatar",
          renderer: (item: RowData) => {
            const value = item as IStudentList;
            return (
              <Avatar
                src={value.avatar}
                sx={{ height: "50px", width: "50px"}}
              />
            );
          },
        },
        {
          id: "firstname",
          label: "First Name",
        },
        {
          id: "lastname",
          label: "Last Name",
        },
        {
          id: "othername",
          label: "Other Name",
        },
        ...getCustomizableColumns(),
        {
          id: "action",
          label: "",
          renderer: (item: RowData) => {
            return (
              <Box style={{ display: "flex", gap: "12px" }}>
                {!hideEditButton && (
                  <Button
                    sx={{
                      padding: "4px 12px",
                      minWidth: "30px",
                      background: "#ff5622e4",
                      "&:hover": {
                        background: "#ff5722",
                      },
                    }}
                    variant="contained"
                  >
                    <EditOutlinedIcon />
                  </Button>
                )}
                <Button
                  sx={{
                    padding: "4px 12px",
                    minWidth: "30px",
                  }}
                  variant="contained"
                >
                  <VisibilityOutlinedIcon />
                </Button>
              </Box>
            );
          },
        },
      ]}
    />
  );
};

export default ListTable;
