import React from "react";
import PageLayout from "components/PageLayout";
import Card from "components/Card";
import { SubjectReportFormValues } from "types/report";
import { Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useActiveClassReport } from "hooks/reports";
import SubjectReportForm from "./Form";

const SubjectReport = () => {
  const {
    data: activeSession,
    isLoading,
    isError,
    isFetching,
    refetch,
  } = useActiveClassReport();

  const history = useHistory();

  const handleSubmission = async (values: Partial<SubjectReportFormValues>) => {
    history.push(
      `subject_report_list?session=${values.session}&term=${values.term}&subject=${values.subject}`
    );
  };

  return (
    <Box sx={{ margin: { xs: "0", lg: "0 120px" } }}>
      <PageLayout
        loading={isLoading || isFetching}
        error={isError}
        onRetry={refetch}
      >
        <Card title="SUBJECT REPORT">
          <SubjectReportForm
            activeSession={activeSession}
            onSubmit={handleSubmission}
          />
        </Card>
      </PageLayout>
    </Box>
  );
};

export default SubjectReport;
