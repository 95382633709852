export const TabsStyle = {
  display: "flex",
  backgroundColor: "rgba(244,246,255)",
  borderRadius: "50rem",
  padding: "4px",
  border: "solid 2px#d4dafe",
  justifyContent: "center",
  alignItems: "center",
  ".MuiTabScrollButton-root": {
    display: { xs: "none", md: "flex", lg: "none" },
  },

  ".MuiTabs-flexContainer": {
    gap: "0.5rem",
  },

  ".Mui-selected": {
    backgroundColor: "#fff",
  },
};

export const TabStyle = {
  color: "#ff562292",
  fontSize: "14px",
  fontWeight: "600",
  lineHeight: "1",
  textAlign: "center",
  padding: "9px 20x",
  minHeight: "0",
  minWidth: "0",
  borderRadius: "50rem",
  transition: "background .18s,color .18s,border-color .18s,border-width .18s",

  "&:hover": {
    backgroundColor: "#fff",
  },
};
