import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import { FormSubHeader, SubmitButton } from "components/Common";
import FieldContainer from "components/FieldContainer";
import TextField from "components/Form/TextField";
import { grey, white } from "config/colors";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { IClass } from "types/common";
import { IAddUserFormValues, UserRoles } from "types/users";
import * as Yup from "yup";

/** Form for adding and editing user details */
interface IAddEditUserFormProps {
  onSubmit?: (values: Partial<IAddUserFormValues>) => Promise<any>;
  classes?: IClass[];
}

const AddUserForm = ({ onSubmit, classes }: IAddEditUserFormProps) => {
  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, "Please enter at least two characters")
      .required("Enter a valid first name"),
    lastName: Yup.string()
      .min(2, "Please enter at least two characters")
      .required("Enter a valid last name"),
    email: Yup.string()
      .email("Enter a valid email address")
      .required("Please enter an email address"),
    phoneNumber: Yup.string()
      .min(11, "Enter a valid mobile number")
      .required("Enter a valid mobile number"),
    password: Yup.string()
      .min(5, "Password must contain up to five characters")
      .required("Enter a stronger password"),
    role: Yup.string()
      .oneOf(Object.keys(UserRoles), "User role is required")
      .required("User role is required"),
    class: Yup.string().when("role", {
      is: (value: string) => value && UserRoles[value] === UserRoles.Teacher,
      then: Yup.string()
        .oneOf(
          classes?.map((item) => item.id.toString()) || [],
          "Please select a valid class"
        )
        .required("Please select a class"),
    }),
  });

  const initialValues: Partial<IAddUserFormValues> = {};

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    isSubmitting,
    handleSubmit,
    isValid,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (
      values: Partial<IAddUserFormValues>,
      { setSubmitting }
    ) => {
      setSubmitting(true);
      await onSubmit?.(values);
      setSubmitting(false);
    },
  });

  const isTeacherRoleSelected =
    values.role && UserRoles[values.role] == UserRoles.Teacher;

  useEffect(() => {
    if (values.role && !isTeacherRoleSelected) {
      setFieldValue("class", ""); // de-select the class if the teacher role is not selected.
    }
  }, [isTeacherRoleSelected]);

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Grid direction="column" display="flex">
          <FormSubHeader>Personal Info</FormSubHeader>
          <Grid container spacing={2} marginTop={0} marginBottom="16px">
            <FieldContainer>
              <TextField
                type="text"
                name="firstName"
                value={values.firstName || ""}
                helperText={errors.firstName}
                error={!!(errors.firstName && touched.firstName)}
                label="First Name"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                overrideDefaultStyles
                fullWidth
              />
            </FieldContainer>
            <FieldContainer>
              <TextField
                type="text"
                name="lastName"
                value={values.lastName || ""}
                helperText={errors.lastName}
                error={!!(errors.lastName && touched.lastName)}
                label="Last Name"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                overrideDefaultStyles
                fullWidth
              />
            </FieldContainer>
            <FieldContainer>
              <TextField
                type="text"
                name="phoneNumber"
                value={values.phoneNumber || ""}
                helperText={errors.phoneNumber}
                error={!!(errors.phoneNumber && touched.phoneNumber)}
                label="Phone Number"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                overrideDefaultStyles
                fullWidth
              />
            </FieldContainer>
            <FieldContainer lg={6}>
              <TextField
                type="email"
                name="email"
                value={values.email || ""}
                helperText={errors.email}
                error={!!(errors.email && touched.email)}
                label="Email address"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                overrideDefaultStyles
                fullWidth
              />
            </FieldContainer>
            <FieldContainer lg={6} sm={12} md={12}>
              <TextField
                type="password"
                name="password"
                value={values.password || ""}
                helperText={errors.password}
                error={!!(errors.password && touched.password)}
                label="Password"
                placeholder="Enter temporary user password"
                variant="outlined"
                onChange={handleChange}
                onBlur={handleBlur}
                overrideDefaultStyles
                fullWidth
              />
            </FieldContainer>
          </Grid>
          <FormSubHeader>Manage Access</FormSubHeader>
          <Grid container spacing={2} marginTop={0}>
            <FieldContainer lg={6}>
              <FormControl fullWidth>
                <InputLabel id="role">Select Role</InputLabel>
                <Select
                  type="text"
                  name="role"
                  error={!!(errors.role && touched.role)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  labelId="role"
                  id="role-select"
                  label="Select Role"
                  value={values.role || ""}
                  defaultValue=""
                >
                  <MenuItem key="empty" value="">
                    -
                  </MenuItem>
                  {Object.keys(UserRoles).map((role) => {
                    return (
                      <MenuItem key={role} value={role}>
                        {UserRoles[role]}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText>{errors.role}</FormHelperText>
              </FormControl>
            </FieldContainer>

            <FieldContainer lg={6}>
              <Tooltip
                arrow
                placement="top"
                title={
                  !isTeacherRoleSelected && values.role
                    ? `Class not applicable to ${UserRoles[values.role!]} role`
                    : ""
                }
              >
                <FormControl
                  fullWidth
                  style={{ background: !isTeacherRoleSelected ? grey : white }}
                >
                  <InputLabel id="class">Select Class</InputLabel>
                  <Select
                    type="text"
                    name="class"
                    error={!!(errors.class && touched.class)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    labelId="class"
                    id="class-select"
                    disabled={!isTeacherRoleSelected}
                    defaultValue=""
                    value={values.class || ""}
                    label="Select Class"
                  >
                    <MenuItem key="empty" value="">
                      -
                    </MenuItem>
                    {classes?.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.class}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText>{errors.class}</FormHelperText>
                </FormControl>
              </Tooltip>
            </FieldContainer>
          </Grid>
          <SubmitButton
            disabled={isSubmitting || !isValid}
            type="submit"
            variant="contained"
            style={{ marginTop: 32, alignSelf: "center" }}
          >
            {isSubmitting ? "Please wait..." : "Add User"}
          </SubmitButton>
        </Grid>
      </form>
    </Box>
  );
};

export default AddUserForm;
