import React from "react";
import { Box } from "@mui/material";
import SignInForm from "./SigninForm";
import { FormContainer, FormContent, Title, Layout } from "../Common";
import { useLoginMutation } from "../../../hooks/auth";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { GenericAPIResponse } from "types/generic";
import { IAuthData } from "types/auth";
import { setToken } from "api/cookie";
import Header from "components/NavMenu/Header";
import { Paragraph } from "../Common";
/** Renders the get started page */

const SignIn = () => {
  const history = useHistory();

  const { mutateAsync: submitLoginRequest } = useLoginMutation({
    onError: (error: GenericAPIResponse<IAuthData>) => handleAuthError(error),
    onSuccess: (data: GenericAPIResponse<IAuthData>) => handleAuthSuccess(data),
  });

  const handleAuthError = (error: GenericAPIResponse<IAuthData>) => {
    const data = error || {
      message: "We couldn't process your request! Try again",
    };
    toast(data.message, { type: "error" });
  };

  const handleAuthSuccess = (data: GenericAPIResponse<IAuthData>) => {
    //set cookie, then proceed to dashboard.
    if (data.code === 0) {
      handleAuthError(data);
    } else {
      setToken(data.token);
      history.push("/dashboard");
    }
  };

  return (
    <>
      <Header />
      <Layout>
        <FormContainer>
          <FormContent style={{ marginTop: 32 }}>
            <Title>Login to Your Account</Title>
            <Paragraph>
              Enter your details below to access your account
            </Paragraph>
            <Box mt={2}>
              <SignInForm onSubmit={submitLoginRequest} />
            </Box>
          </FormContent>
        </FormContainer>
      </Layout>
    </>
  );
};

export default SignIn;
