import React from "react";
import { Box } from "@mui/material";
import ChangePasswordForm from "./ChangePasswordForm";
import { useHistory } from "react-router-dom";
import { useChangePasswordMutation } from "../../../hooks/auth/index";
import { toast } from "react-toastify";
import Card from "components/Card";
import PageLayout from "components/PageLayout";
import { clearAuthCookies } from "api/cookie";
import { useQueryClient } from "react-query";

const ChangePassword = () => {
  const queryClient = useQueryClient();
  const { mutateAsync: changePassword } = useChangePasswordMutation();
  const submitChangePassword = async (values) => {
    try {
      const response = await changePassword(values);
      if (response.code) {
        toast(response.message || "Password changed successfully!", {
          type: "success",
        });
        queryClient.invalidateQueries();
        clearAuthCookies();
        window.location.href = "/signin";
      } else {
        toast(response.message || "Something went wrong! Please try again.", {
          type: "error",
        });
      }
    } catch (error) {
      toast("Something went wrong!", { type: "error" });
    }
  };
  return (
    <>
      <PageLayout loading={false} error={false}>
        <Box
          sx={{
            width: "80%",
            margin: "2rem auto",
          }}
        >
          <Card title="CHANGE PASSWORD">
            <ChangePasswordForm onSubmit={submitChangePassword} />
          </Card>
        </Box>
      </PageLayout>
    </>
  );
};
export default ChangePassword;
