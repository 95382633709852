import React from "react";
import AddEditUserForm from "./Form";
import PageLayout from "components/PageLayout";
import Card from "components/Card";
import { useClasses } from "hooks/common";
import { IAddUserFormValues } from "types/users";
import { useAddUserMutation } from "hooks/users";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { viewAccountListUserUrl } from "config/urls";

const AddEditUser = () => {
  const history = useHistory();
  const {
    data: classes,
    isLoading,
    isError,
    isFetching,
    refetch,
  } = useClasses();

  const { mutateAsync: submitAddUser } = useAddUserMutation();

  const handleSubmission = async (values: Partial<IAddUserFormValues>) => {
    try {
      const response = await submitAddUser(values as IAddUserFormValues);
      if (response.code) {
        toast(response.message || "User added successfully!", {
          type: "success",
        });
        history.push(viewAccountListUserUrl());
      } else {
        toast(response.message || "Something went wrong", { type: "error" });
      }
    } catch (error) {
      toast("Something went wrong", { type: "error" });
    }
  };

  return (
    <PageLayout
      loading={isLoading || isFetching}
      error={isError}
      onRetry={refetch}
    >
      <Card title="ADD USER">
        <AddEditUserForm classes={classes?.data || []} onSubmit={handleSubmission} />
      </Card>
    </PageLayout>
  );
};

export default AddEditUser;
