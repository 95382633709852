import React, { useEffect } from "react";
import {
  Redirect,
  Route,
  Switch,
  matchPath,
  useHistory,
  useLocation,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import DashboardRoute from "./DashboardRoute";
import "react-toastify/dist/ReactToastify.css";
import SignUp from "views/auth/Signup";
import SignIn from "views/auth/Signin";
import { Box } from "@mui/material";
import Index from "../views/index/Index";
import NotFound from "components/NotFound";
import AppBar from "components/Appbar";
import ScrollToTop from "views/index/ScrollToTop";
import Subcription from "views/subscription";
import ResetPassword from "views/auth/ResetPassword";
import ChangePassword from "views/auth/ChangePassword";
import Test from "../Test/index";

/** Setups up the application route */

const AppRoute = () => {
  const { pathname } = useLocation();

  const match = matchPath(pathname, {
    path: "/dashboard",
    exact: false,
    strict: true,
  });

  return (
    <div id="app-container">
      <Switch>
        <Route path="/" exact render={Index} />
        <Route path="/signin" exact component={SignIn} />
        <Route path="/signup" exact component={SignUp} />
        <Route path="/resetpassword" exact component={ResetPassword} />
        <Route path="/changepassword" exact component={ChangePassword} />
        <Route path="/test" exact component={Test} /> 

        {/* <Route path="/homepage" exact component={Index} /> */}

        <Route path="/subscription" component={Subcription} />
        <Route
          path="/dashboard"
          render={(props) => <DashboardRoute {...props} />}
        /> 
        <Route
          path="*"
          render={() => (
            <Box>
              <AppBar />
              <NotFound />
            </Box>
          )}
        />
      </Switch>

      {!match && <ScrollToTop />}
      <ToastContainer />
    </div>
  );
};

export default AppRoute;
