import React from "react";
import { Box, Avatar, Typography } from "@mui/material";
import { IStudentList } from "types/students";

interface IStudentListProps {
  data: IStudentList;
}

const Profile = (props: IStudentListProps) => {
  const { data } = props;

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent={{ xs: "center", sm: "flex-start" }}
      flexDirection="row"
      gap={{ xs: "2rem", sm: "0" }}
      margin="48px 0"
      padding="0 32px"
    >
      <Avatar
        src={data.avatar}
        alt={`${data.firstname}`}
        sx={{ height: "80px", width: "80px" }}
      />

      <Box
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        gap={{ xs: "12px", sm: "0" }}
      >
        <Box
          paddingRight={{ sm: "30px" }}
          marginLeft={{ sm: "20px" }}
          borderRight={{ sm: "1px solid #545f7d6c" }}
        >
          <Typography
            variant="h6"
            sx={{ color: "var(--primary)", fontSize: "18px" }}
          >
            {`${data?.firstname} ${data?.lastname} ${data?.othername}`}
          </Typography>
          <Typography variant="subtitle1">ADM No: {data?.admno}</Typography>
        </Box>

        <Box padding={{ sm: "2px 30px" }}>
          <Typography
            variant="h6"
            sx={{ color: "var(--primary)", fontSize: "18px" }}
          >
            Class: {data["classes"]?.class}
          </Typography>
          <Typography variant="subtitle1">
            Phone: {data?.phone || " None"}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Profile;
