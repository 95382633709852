import React from "react";
import { useLocation } from "react-router-dom";

function useQueryParameters<T>() {
  const { search } = useLocation();

  const parseQueryParams = (params: URLSearchParams) => {
    return Array.from(params.keys())
      .map((k) => [k, params.get(k) || ""])
      .reduce((prev, current) => {
        return {
          ...prev,
          [current[0]]: current[1],
        };
      }, {}) as T;
  };

  return React.useMemo(
    () => parseQueryParams(new URLSearchParams(search)),
    [search]
  );
}

export default useQueryParameters;
