import { Box, Modal, Typography } from "@mui/material";
import { useState } from "react";
import { RoundedButton } from "./Common";
import ModalContainer from "./ModalContainer";

interface IDeleteModalProps {
  prompt: string;
  title: string;
  onCancel?: Function;
  onConfirm?: () => Promise<void | any>;
  open: boolean;
}

const DeleteModal = ({
  title,
  prompt,
  onCancel,
  onConfirm,
  open,
}: IDeleteModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Modal open={open} onClose={() => onCancel && onCancel()}>
      <ModalContainer
        title={title}
        handleClose={onCancel}
        style={{ width: 380, maxWidth: "90%" }}
      >
        <Typography
          display="flex"
          justifyContent="center"
          marginTop="10px"
          fontSize="16px"
          variant="body2"
        >
          {prompt}
        </Typography>

        <Box
          display="flex"
          justifyContent="center"
          style={{ marginTop: 32 }}
          textAlign="center"
        >
          <RoundedButton
            disabled={isLoading}
            onClick={() => {
              if (onConfirm) {
                setIsLoading(true);
                onConfirm().finally(() => {
                  setIsLoading(false);
                });
              }
            }}
            style={{ padding: "10px 40px" }}
            variant="contained"
            color="error"
            sx={{ mr: 4 }}
          >
            {isLoading ? "Loading..." : "DELETE"}
          </RoundedButton>
          <RoundedButton
            disabled={isLoading}
            style={{ padding: "10px 40px" }}
            variant="outlined"
            onClick={() => onCancel && onCancel()}
          >
            CANCEL
          </RoundedButton>
        </Box>
      </ModalContainer>
    </Modal>
  );
};

export default DeleteModal;
