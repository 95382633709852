import Table, { RowData } from "components/Table";
import TextField from "components/Form/TextField";
import { useScreenSize } from "hooks/browser/useScreenSize";
import Typography from "@mui/material/Typography";
import { IClassReportList, IRecordScore } from "types/report";
import { mobileColumnTable } from "components/Table/Style";
import { useFormikContext } from "formik";

const ReportTable = ({ isNursery }: { isNursery?: boolean }) => {
  const { isSm, isMd } = useScreenSize();

  const { values, handleChange } = useFormikContext<IClassReportList>();

  const isNotWithinRange = (value: number) => {
    return value < 0 || value > 100 || isNaN(value);
  };

  const getColumns = () => {
    const columns: any[] = [
      {
        id: "subjects",
        label: "SUBJECTS",
        renderer: (item: RowData) => {
          const value = item as IRecordScore;
          return (
            <Typography sx={{ fontSize: "13px", fontWeight: "600" }}>
              {value.subjects}
            </Typography>
          );
        },
      },
    ];

    if (!isNursery) {
      columns.push(
        ...[
          {
            id: "first_ca",
            label: "FIRST CA",
            renderer: (_, index) => {
              return (
                <TextField
                  sx={{ width: { xs: "95%", md: "80%" } }}
                  type="number"
                  name={`scores.${index}.first_ca`}
                  value={values.scores[index].first_ca}
                  onChange={handleChange}
                  error={isNotWithinRange(
                    Number(values.scores[index].first_ca || 0)
                  )}
                  label={isSm || isMd ? "FIRST CA" : ""}
                />
              );
            },
          },

          {
            id: "second_ca",
            label: "SECOND CA",
            renderer: (_, index) => {
              return (
                <TextField
                  sx={{ width: { xs: "95%", md: "80%" } }}
                  type="number"
                  name={`scores.${index}.second_ca`}
                  value={values.scores[index].second_ca}
                  error={isNotWithinRange(
                    Number(values.scores[index].second_ca) || 0
                  )}
                  onChange={handleChange}
                  label={isSm || isMd ? "SECOND CA" : ""}
                />
              );
            },
          },

          {
            id: "third_ca",
            label: "THIRD CA",
            renderer: (_, index) => {
              return (
                <TextField
                  sx={{ width: { xs: "95%", md: "80%" } }}
                  onChange={handleChange}
                  type="number"
                  name={`scores.${index}.third_ca`}
                  error={isNotWithinRange(
                    Number(values.scores[index].third_ca || 0)
                  )}
                  value={values.scores[index].third_ca}
                  label={isSm || isMd ? "THIRD CA" : ""}
                />
              );
            },
          },

          {
            id: "fourth_ca",
            label: "FOURTH CA",
            renderer: (_, index) => {
              return (
                <TextField
                  sx={{ width: { xs: "95%", md: "80%" } }}
                  onChange={handleChange}
                  type="number"
                  name={`scores.${index}.fourth_ca`}
                  value={values.scores[index].fourth_ca}
                  error={isNotWithinRange(
                    Number(values.scores[index].fourth_ca || 0)
                  )}
                  label={isSm || isMd ? "FOURTH CA" : ""}
                />
              );
            },
          },
        ]
      );
    }

    columns.push({
      id: "exam",
      label: "EXAM",
      renderer: (_, index) => {
        return (
          <TextField
            sx={{ width: { xs: "95%", md: "80%" } }}
            onChange={handleChange}
            type="number"
            name={`scores.${index}.exam`}
            value={values.scores[index].exam}
            error={isNotWithinRange(Number(values.scores[index].exam || 0))}
            label={isSm || isMd ? "EXAM" : ""}
          />
        );
      },
    });

    return columns;
  };

  return (
    <Table
      sx={mobileColumnTable}
      disableFilter
      disableFooter
      disableSideSheet
      rows={values.scores}
      columns={getColumns()}
    />
  );
};

export default ReportTable;
