import logo from '../img/logo.png'
import avatar from '../img/avatar.jpg'
import avatar3 from '../img/avatar3.png'
import file from '../img/file.png'
import file1 from '../img/file1.png'
import profile from '../img/profile.jpg'


export default {
    logo,
    avatar,
    avatar3,
    file,
    file1,
    profile
}

