import { Button, ButtonGroup } from "@mui/material";
import Table, { RowData } from "components/Table";

import DeleteIcon from "@mui/icons-material/Delete";

import Edit from "@mui/icons-material/Edit";
import { SubjectFormVals, Subject } from "types/subject";

interface ISubjectTableProps {
  subjects: Subject[];
  onUpdateSubject?: (values: SubjectFormVals) => any;
  onDeleteSubject?: (subjectId: number) => any;
  openAddSubjectModal: () => void;
}

const SubjectsTable = (props: ISubjectTableProps) => {
  const { subjects, onDeleteSubject, onUpdateSubject, openAddSubjectModal } =
    props;

  return (
    <Table
      title={{ label: "Manage Subjects" }}
      disableSideSheet={true}
      ctaLabel="Add Subject"
      onCTAClicked={openAddSubjectModal}
      rows={subjects}
      columns={[
        {
          id: "subject",
          label: "Subject Name",
        },
        {
          id: "classes",
          label: "Class",

          renderer: (item: RowData) => {
            const values = item as Subject;

            return (
              <>{`${values?.classes?.class} (${values?.classes?.section})`}</>
            );
          },
        },

        {
          id: "actions",
          label: "Action",
          renderer: (item: RowData) => {
            const value = item as Subject;
            return (
              <ButtonGroup size="small">
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    onDeleteSubject?.(value.id);
                  }}
                  style={{ whiteSpace: "nowrap" }}
                  variant="outlined"
                >
                  <DeleteIcon sx={{ "&:hover": { color: "#ff5722" } }} />
                </Button>

                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    onUpdateSubject?.(value);
                  }}
                  style={{ whiteSpace: "nowrap" }}
                  variant="outlined"
                >
                  <Edit sx={{ "&:hover": { color: "#ff5722" } }} />
                </Button>
              </ButtonGroup>
            );
          },
        },
      ]}
    />
  );
};

export default SubjectsTable;
