import React, { useState } from "react";
import PersonalInfo from "./PersonalInfo";
import AcademicInfo from "./AcademicInfo";
import MedicalInfo from "./MedicalInfo";
import ParentInfo from "./ParentInfo";
import { Box } from "@mui/material";
import { Form, Formik } from "formik";
import { IRegisterStudentValues } from "types/students";
import { validationSchema } from "./ValidationSchema";
import { SubmitButton } from "views/auth/Common";
import styles from "../../profile/profile.module.css";
import { generateUUID } from "hooks/uuid";
import { useAddStudentMutation } from "hooks/students";
import { AxiosError } from "axios";
import { GenericAPIResponse } from "types/generic";
import { toast } from "react-toastify";
import useS3Upload from "hooks/thirdparty/useS3Upload";
import { images } from "assets/img";

const AddStudent = () => {
  const initialValues: IRegisterStudentValues = {};

  const { progress, uploadAsync } = useS3Upload();
  const [profileImage, setProfileImage] = useState("");
  const [profileImageFile, setProfileImageFile] = useState<File | null>(null);

  const { mutateAsync: submitAddStudentRequest } = useAddStudentMutation({
    onError: (error: AxiosError) => handleSubmitError(error),
    onSuccess: (data: GenericAPIResponse<any>) => handleSubmitSuccess(data),
  });

  const handleSubmitError = (error?: AxiosError) => {
    const data = error || {
      message: "We couldn't process your request! Try again",
    };
    toast(data.message, { type: "error" });
  };

  const handleSubmitSuccess = (data: GenericAPIResponse<any>) => {
    if (data.code === 1) {
      toast("Student registered successfully!", { type: "success" });
      setProfileImage(images.avatar3);
      window.location.reload(); // Maybe redirect to list student
    } else {
      handleSubmitError();
    }
  };

  const handleProfileUpload = (event) => {
    const file = event.target.files[0];
    setProfileImageFile(file);
    const url = URL.createObjectURL(file);
    setProfileImage(url);
  };

  const uploadFileToS3 = async () => {
    if (!profileImageFile) return;
    const folder = "profile/";
    const currentFile = profileImageFile;
    const fileName =
      folder + generateUUID() + currentFile.name.replaceAll(" ", "_");
    const path =
      "https://" +
      process.env.REACT_APP_AWS_BUCKET_NAME +
      ".s3.amazonaws.com/" +
      fileName;
    await uploadAsync(currentFile, fileName);
    setProfileImage(path);
    return path;
  };

  const submitToServer = async (values) => {
    // first upload the image.
    const avatar = await uploadFileToS3();

    // then send over all the form data along with the uploaded file URL to the backend endpoint

    await submitAddStudentRequest({
      firstname: values.firstname,
      lastname: values.lastname,
      othername: values.othername,
      gender: values.gender,
      dob: values.dateOfBirth,
      admno: values.admissionNumber,
      class: values.class,
      stateoforigin: values.stateOfOrigin,
      lgaoforigin: values.lgaOfOrigin,
      password: values.accountPassword,
      email: values.email,
      phone: values.phone,
      address: values.address,
      path: avatar,

      // Academic Info
      lastsch: values.lastSchoolAttended,
      highclass: values.highestClassAttained,
      schstate: values.attendedSchoolState,
      schlga: values.lgaOfSchoolAttended,
      schoolAddress: values.schoolAddress,

      // Medical Info
      medicalcondition: values.medicalCondition,

      // Parent Info
      fathersName: values.fathersName,
      mothersName: values.mothersName,
      fathersAddress: values.fathersAddress,
      fathersReligion: values.fathersReligion,
      fathersOccupation: values.fathersOccupation,
      fathersPhone: values.fathersPhone,
      mothersPhone: values.mothersPhone,
      mothersAddress: values.mothersAddress,
      mothersReligion: values.mothersReligion,
      mothersOccupation: values.mothersOccupation,
      parentEmail: values.parentEmail,
      parentAccountPassword: values.parentAccountPassword,
    });
  };

  return (
    <Box className={styles.cardMargin}>
      <Formik
        initialValues={initialValues}
        onSubmit={async (
          values: Partial<IRegisterStudentValues>,
          { setSubmitting }
        ) => {
          setSubmitting(true);
          await submitToServer(values);
          setSubmitting(false);
        }}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isSubmitting,
          setFieldValue,
        }) => (
          <Form style={{ position: "relative" }}>
            <PersonalInfo
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
              setFieldValue={setFieldValue}
              handleProfileUpload={handleProfileUpload}
              profileImage={profileImage}
              uploadProgress={progress}
            />
            <AcademicInfo
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <MedicalInfo
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
            <ParentInfo
              values={values}
              errors={errors}
              touched={touched}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />

            <Box className={styles.bottomNav}>
              <Box className={styles.navItem}>
                <SubmitButton
                  style={{ width: "100%" }}
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                  onClick={() => console.log(errors)}
                >
                  {isSubmitting ? "Please wait..." : " Create Student"}
                </SubmitButton>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default AddStudent;
