import { Button, ButtonGroup } from "@mui/material";
import { SubmitButton } from "components/Common";
import Table, { RowData } from "components/Table";

interface ITableProps {
  groups: any[];
  title: string;
}

const GenerateReportGroupTable = (props: ITableProps) => {
  const { groups, title } = props;

  return (
    <Table
      title={{ label: title }}
      disableSideSheet={true}
      rows={groups}
      columns={[
        {
          id: "class",
          label: "Class",
          renderer: (item: RowData) => {
            const value = item as any;
            return (
              <>{`${value.classinfo.class} - ${value.classinfo.section}`}</>
            );
          },
        },

        {
          id: "status",
          label: "Status",
          renderer: (item: RowData) => {
            const value = item as any;
            return <>{Number(value.status) < 1 ? "Pending" : "Generated"}</>;
          },
        },
        {
          id: "actions",
          label: "Action",
          renderer: (item: RowData) => {
            const value = item as any;
            return (
              <Button
                style={{ whiteSpace: "nowrap" }}
                type="submit"
                variant="outlined"
              >
                {value.status < 1 ? "GENERATE" : "RE-GENERATE"}
              </Button>
            );
          },
        },
      ]}
    />
  );
};

export default GenerateReportGroupTable;
