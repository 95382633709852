import { Box, Grid, Stack } from "@mui/material";
import Card from "components/Card";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import { GenericAPIResponse } from "types/generic";
import PageLayout from "components/PageLayout";
import React, { useState } from "react";
import ReportForm from "./ReportForm";
import {
  useGetResultSettings,
  useUpdateResultSettingMutation,
} from "hooks/resultSetting";
import { IReportSettings } from "types/report";
import SignatureSetting from "./SignatureSetting";

const Reports = () => {
  const { isLoading, isFetching, isError, refetch, data } =
    useGetResultSettings();

  const handleSubmitError = (error?: AxiosError) => {
    const data = error || {
      message: "We couldn't process your request! Try again",
    };
    toast(data.message, { type: "error" });
  };

  const handleeditSuccess = (data: GenericAPIResponse<IReportSettings>) => {
    if (data.code === 0) {
      handleSubmitError();
    } else {
      // success toast
      toast("Setting updated successfully!", { type: "success" });
      refetch();
    }
  };

  // Handle update Setting
  const { mutateAsync: submitEditSettingRequest } =
    useUpdateResultSettingMutation({
      onError: (error: AxiosError) => handleSubmitError(error), //Error handle tech debt to keep API the way it is.
      onSuccess: (data: GenericAPIResponse<any>) => handleeditSuccess(data),
    });

  return (
    <PageLayout loading={isLoading ||  isFetching} error={isError} onRetry={refetch}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={8}>
          <Card title="Result Settings">
            <ReportForm
              resultSetting={data?.data}
              onSubmit={submitEditSettingRequest}
            />
          </Card>
        </Grid>

        <Grid item xs={12} sm={4}>
          <Card title="Head Teacher's Signature">
            <SignatureSetting />
          </Card>
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default Reports;
