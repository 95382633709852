import { TextFieldContainer, SubmitButton } from "views/auth/Common";
import TextField from "components/Form/TextField";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { IClassValues } from "types/classes";
import { sections } from "./data";
import DialogTitle from "components/DialogTitle";
import { Tooltip } from "@material-ui/core";
export interface SimpleDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}

const validationSchema = Yup.object().shape({
  class: Yup.string().required("Class name is required"),
  section: Yup.string().required("Please select a section from the dropdown"),
});

export interface IClassFormProps {
  onSubmit: (values: IClassValues) => Promise<any> | any;
  open: boolean;
  isEdit?: boolean;
  data?: IClassValues | undefined;
  handleClose: Function;
}

const AddClassForm = ({
  onSubmit,
  open,
  handleClose,
  isEdit,
  data,
}: IClassFormProps) => {
  const initialValues: IClassValues = isEdit
    ? {
        class: data?.class,
        id: data?.id,
        section: data?.section,
        is_nursery: data?.is_nursery,
      }
    : {};

  return (
    <Dialog fullWidth={true} open={open} onClose={() => handleClose()}>
      <DialogTitle
        title={`${isEdit ? "Update" : "Add"} Class`}
        onClose={() => handleClose()}
      />
      <DialogContent dividers style={{ paddingTop: 0 }}>
        <Formik
          initialValues={initialValues}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            await onSubmit(values);
            setSubmitting(false);
          }}
          validationSchema={validationSchema}
        >
          {({
            values,
            setFieldValue,
            errors,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
          }) => (
            <Form>
              <TextFieldContainer direction="column" style={{ gap: "24px" }}>
                <TextField
                  type="text"
                  name="class"
                  label="Class Name"
                  variant="outlined"
                  value={values.class || ""}
                  error={!!(errors.class && touched.class)}
                  helperText={errors.class}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <Box margin="0 5px">
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="section">Select Section</InputLabel>
                    <Select
                      type="text"
                      name="section"
                      defaultValue={isEdit ? values.section : ""}
                      error={!!(errors.section && touched.section)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      labelId="section"
                      id="demo-simple-select"
                      label="Select Section"
                    >
                      {sections?.map((stat) => {
                        return <MenuItem value={stat}>{stat}</MenuItem>;
                      })}
                    </Select>
                    <FormHelperText>{errors.section}</FormHelperText>
                  </FormControl>
                </Box>

                <Box margin="0 5px">
                  <Tooltip title="Select this option if the class is a nursery class. Nursery class reports are generated separately from other classes and are tailored to accommodate nursery assessments">
                    <FormControlLabel
                      control={<Checkbox />}
                      label="Is this a nursery class?"
                      checked={values.is_nursery}
                      onChange={() =>
                        setFieldValue("is_nursery", !values.is_nursery)
                      }
                    />
                  </Tooltip>
                </Box>
              </TextFieldContainer>

              <Box
                flexDirection="row"
                textAlign="center"
                style={{ margin: "16px 0px" }}
              >
                <SubmitButton
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                >
                  {isSubmitting
                    ? "Please wait..."
                    : `${isEdit ? "Update" : "Add"} Class`}
                </SubmitButton>
              </Box>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};
export default AddClassForm;
