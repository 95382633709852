import { Box, Button, Typography } from "@mui/material";
import React from "react";
import styled from "styled-components";

export const FormSubHeader = styled.h2`
  font-weight: 600;
  font-size: 18px;
  color: var(--primary);
`

export const SubmitButton = styled(Button)`
  && {
    border-radius: 50rem;
    padding: 12px 22px;
    font-weight: 600;
    min-width: 140px;
  }
`;

export const RoundedButton = styled(Button)`
  && {
    border-radius: 25px;
    max-height: 36px;
  }
`;

export const ElevatedContainer = styled.div<{
  radius?: number;
  background?: string;
}>`
  box-shadow: 0 3px 6px rgb(0 0 0 / 12%);
  padding: 16px;
  background-color: ${(props) => props.background || "#fff"};
  border-radius: ${(props) => `${props.radius || 0}px`};
`;

export const TableHeaderNode = ({
  title,
  ctaLabel,
  onCtaClicked,
  style
}: {
  title: string;
  ctaLabel?: string;
  onCtaClicked?: Function;
  style?: React.CSSProperties;
}) => (
  <Box
    mx={2}
    my={2}
    mb={1}
    display="flex"
    flexDirection="row"
    alignItems="center"
    style={style}
  >
    <Typography fontWeight="bold" sx={{ flex: 1 }} variant="body1">
      {title}
    </Typography>
    {ctaLabel && (
      <RoundedButton
        variant="contained"
        onClick={() => onCtaClicked && onCtaClicked()}
      >
        {ctaLabel}
      </RoundedButton>
    )}
  </Box>
);
