import React, { useState } from "react";
import TextField from "components/Form/TextField";
import { Box, InputLabel } from "@mui/material";
import { Card, CardContent, CardHeader } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import styles from "../../profile/profile.module.css";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const ParentInfo = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  hidePassword = false,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <Box marginBottom="64px">
      <Card
        sx={{
          ".MuiCardHeader-root": { padding: "20px 0px 0px 14px" },
          minWidth: 275,
        }}
      >
        <CardHeader
          sx={{
            "&.MuiCardHeader-title": { padding: "0px", marginBottom: "0" },
          }}
          title={<h2 className={styles.headline}>Parent Info</h2>}
        />
        <CardContent
          sx={{ "&.MuiCardContent-root": { padding: "0 16px 24px 16px" } }}
        >
          <Box className={styles.textFieldContainer}>
            <TextField
              type="text"
              name="fathersName"
              value={values.fathersName || ""}
              error={!!(errors.fathersName && touched.fathersName)}
              label="Father's Full Name"
              variant="outlined"
              helperText={errors.fathersName}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <TextField
              type="text"
              name="mothersName"
              value={values.mothersName || ""}
              error={!!(errors.mothersName && touched.mothersName)}
              label="Mother's Full Name"
              variant="outlined"
              helperText={errors.mothersName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Box>

          <Box className={styles.textFieldContainer}>
            <TextField
              type="text"
              name="fathersAddress"
              value={values.fathersAddress || ""}
              error={!!(errors.fathersAddress && touched.fathersAddress)}
              label="Father's Contact Address"
              variant="outlined"
              helperText={errors.fathersAddress}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <TextField
              type="text"
              name="mothersAddress"
              value={values.mothersAddress || ""}
              error={!!(errors.mothersAddress && touched.mothersAddress)}
              label="Mother's Contact Address"
              variant="outlined"
              helperText={errors.mothersAddress}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Box>

          <Box className={styles.textFieldContainer}>
            <Box margin="0 5px" flex={1}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="fathersReligion">Father's Religion</InputLabel>
                <Select
                  type="text"
                  name="fathersReligion"
                  value={values.fathersReligion || ""}
                  error={!!(errors.fathersReligion && touched.fathersReligion)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  labelId="fathersReligion"
                  id="demo-simple-select"
                  label="Father Religion"
                  MenuProps={{ disablePortal: true }}
                >
                  <MenuItem value="CHRISTIAN">CHRISTIAN</MenuItem>;
                  <MenuItem value="MUSLIM">MUSLIM</MenuItem>
                  <MenuItem value="OTHERS">OTHERS</MenuItem>
                </Select>
                <FormHelperText>{errors.FathersReligion}</FormHelperText>
              </FormControl>
            </Box>
            <Box margin="0 5px" flex={1}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="mothersReligion">Mother's Religion</InputLabel>
                <Select
                  type="text"
                  name="mothersReligion"
                  value={values.mothersReligion || ""}
                  error={!!(errors.mothersReligion && touched.mothersReligion)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  labelId="mothersReligion"
                  id="demo-simple-select"
                  label="Mother Religion"
                  MenuProps={{ disablePortal: true }}
                >
                  <MenuItem value="CHRISTIAN">CHRISTIAN</MenuItem>;
                  <MenuItem value="MUSLIM">MUSLIM</MenuItem>
                  <MenuItem value="OTHERS">OTHERS</MenuItem>
                </Select>
                <FormHelperText>{errors.mothersReligion}</FormHelperText>
              </FormControl>
            </Box>
          </Box>
          <Box className={styles.textFieldContainer}>
            <TextField
              type="text"
              name="fathersOccupation"
              label="Father's Occupation"
              variant="outlined"
              value={values.fathersOccupation || ""}
              error={!!(errors.fathersOccupation && touched.fathersOccupation)}
              helperText={errors.fathersOccupation}
              onChange={handleChange}
              onBlur={handleBlur}
              sx={{ flex: 1 }}
            />

            <TextField
              type="text"
              name="mothersOccupation"
              label="Mother's Occupation"
              variant="outlined"
              value={values.mothersOccupation || ""}
              error={!!(errors.mothersOccupation && touched.mothersOccupation)}
              helperText={errors.mothersOccupation}
              onChange={handleChange}
              onBlur={handleBlur}
              sx={{ flex: 1 }}
            />
          </Box>

          <Box className={styles.textFieldContainer}>
            <TextField
              type="text"
              name="fathersPhone"
              label="Father's Phone Number"
              variant="outlined"
              value={values.fathersPhone || ""}
              error={!!(errors.fathersPhone && touched.fathersPhone)}
              helperText={errors.fathersPhone}
              onChange={handleChange}
              onBlur={handleBlur}
            />

            <TextField
              type="text"
              name="mothersPhone"
              value={values.mothersPhone || ""}
              helperText={errors.mothersPhone}
              error={!!(errors.mothersPhone && touched.mothersPhone)}
              label="Mother's Phone Number"
              variant="outlined"
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Box>

          <Box className={styles.textFieldContainer}>
            <TextField
              type="text"
              name="parentEmail"
              label="Email"
              variant="outlined"
              value={values.parentEmail || ""}
              error={!!(errors.parentEmail && touched.parentEmail)}
              helperText={errors.parentEmail}
              onChange={handleChange}
              onBlur={handleBlur}
              sx={{ flex: 1 }}
            />
            {!hidePassword && (
              <TextField
                type={showPassword ? "text" : "password"}
                name="parentAccountPassword"
                label="Account Password"
                value={values.parentAccountPassword || ""}
                error={
                  !!(
                    errors.parentAccountPassword &&
                    touched.parentAccountPassword
                  )
                }
                helperText={errors.parentAccountPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{ flex: 1 }}
              />
            )}
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ParentInfo;
