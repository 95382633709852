import React from "react";
import Box from "@mui/material/Box";
import { studentData } from "Test/Table/dataTest";
import TableTest from "Test/Table/TableTest";

const Test = () => {
  return (
    <Box sx={{ margin: "2rem" }}>
      <TableTest records={studentData} />
    </Box>
  );
};

export default Test;
