import { post, get } from "api";
import { MutationOptions } from "hooks/types";
import { useMutation, useQuery, QueryOptions } from "react-query";
import { GenericAPIResponse } from "types/generic";
import { IGrade, IGradeSystem } from "types/settings";

//get grade system data
export const useGetGradeSettings = (options?: QueryOptions<IGrade[]>) => {
  return useQuery<IGrade[]>(
    ["settings", "grades"],
    () => get(`settings/grade`),
    { ...(options || {}) }
  );
};

//save grade request mutation
export const useGradeSystemMutation = (
  options?: MutationOptions<GenericAPIResponse<any>>
) => {
  return useMutation(
    (value: IGradeSystem) => post(`settings/grade`, { ...value }),
    { ...(options || {}) }
  );
};
