import React, { useEffect, useState } from "react";
import TextField from "components/Form/TextField";
import { Box, InputLabel } from "@mui/material";
import styles from "../../profile/profile.module.css";
import { Card, CardContent, CardHeader } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import DatePicker from "views/auth/Signup/DatePicker";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ProfileImage from "components/ProfileImage";
import { images } from "../../../assets/img/index";
import { useStateData, lgaData } from "hooks/dashboard";
import { ILgaData } from "../../../types/dashboard";
import { useSortedClasses } from "hooks/common";

const PersonalInfo = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  handleProfileUpload,
  profileImage,
  uploadProgress,
  hidePassword = false,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const { data: stateData } = useStateData();

  const classData = useSortedClasses();

  const [lgas, setLgas] = useState<ILgaData[]>([
    {
      id: values.lgaOfOrigin,
      state_id: values.stateOfOrigin,
      lga: values.lgaOfOriginName,
    },
  ]);

  const getLgas = async (stateId: number) => {
    const data = await lgaData(stateId);
    setLgas(data);
  };

  return (
    <Box marginBottom="32px">
      <Card
        sx={{
          ".MuiCardHeader-root": { padding: "20px 0px 0px 14px" },
          minWidth: 275,
        }}
      >
        <CardHeader
          sx={{
            "&.MuiCardHeader-title": { padding: "0px", marginBottom: "0" },
          }}
          title={<h2 className={styles.headline}>Personal Info</h2>}
        />
        <CardContent
          sx={{ "&.MuiCardContent-root": { padding: "0 16px 24px 16px" } }}
        >
          <Box className={styles.flexBox}>
            <Box marginTop="12px">
              <ProfileImage
                src={profileImage || images.avatar3}
                size="120px"
                type="circle"
                canChange={true}
                callback={handleProfileUpload}
                uploadProgress={uploadProgress}
                id="profileImage"
              />
            </Box>

            <Box flex={2}>
              <Box className={styles.textFieldContainer}>
                <TextField
                  type="text"
                  name="firstname"
                  value={values.firstname || ""}
                  error={!!(errors.firstname && touched.firstname)}
                  label="First Name"
                  variant="outlined"
                  helperText={errors.firstname}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <TextField
                  type="text"
                  name="lastname"
                  error={!!(errors.lastname && touched.lastname)}
                  helperText={errors.lastname}
                  value={values.lastname || ""}
                  label="Last Name"
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <TextField
                  type="text"
                  name="othername"
                  error={!!(errors.othername && touched.othername)}
                  helperText={errors.othername}
                  value={values.othername || ""}
                  label="Other Name"
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Box>

              <Box className={styles.textFieldContainer}>
                <TextField
                  type="text"
                  name="admissionNumber"
                  value={values.admissionNumber || ""}
                  helperText={errors.admissionNumber}
                  error={!!(errors.admissionNumber && touched.admissionNumber)}
                  label="Admission Number"
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  sx={{ flex: "1" }}
                />

                <Box margin="0 5px" flex={1}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="class">Class</InputLabel>
                    <Select
                      type="text"
                      name="class"
                      value={values.class || ""}
                      error={!!(errors.class && touched.class)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      labelId="class"
                      id="demo-simple-select"
                      label="Class"
                      MenuProps={{ disablePortal: true }}
                    >
                      {classData?.map((classes) => {
                        return (
                          <MenuItem key={classes.id} value={classes.id}>
                            {`${classes.class} ${classes.section}`}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText>{errors.class}</FormHelperText>
                  </FormControl>
                </Box>
              </Box>

              <Box className={styles.textFieldContainer}>
                <Box margin="0 5px" flex={1}>
                  <DatePicker
                    name="dateOfBirth"
                    value={values.dateOfBirth || ""}
                    helperText={errors.dateOfBirth}
                    error={!!(errors.dateOfBirth && touched.dateOfBirth)}
                    onChange={(value) =>
                      setFieldValue("dateOfBirth", value, true)
                    }
                    onBlur={handleBlur}
                    label="Date of Birth"
                  />
                </Box>

                <Box margin="0 5px" flex={1}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="gender">Gender</InputLabel>
                    <Select
                      type="text"
                      name="gender"
                      value={values.gender || ""}
                      error={!!(errors.gender && touched.gender)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      labelId="gender"
                      id="demo-simple-select"
                      label="Gender"
                      MenuProps={{ disablePortal: true }}
                    >
                      <MenuItem value="Male">Male</MenuItem>;
                      <MenuItem value="Female">Female</MenuItem>;
                    </Select>
                    <FormHelperText>{errors.gender}</FormHelperText>
                  </FormControl>
                </Box>
              </Box>

              <Box className={styles.textFieldContainer}>
                <Box margin="0 5px" flex={1}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="state">State of Origin</InputLabel>
                    <Select
                      type="text"
                      name="stateOfOrigin"
                      value={values.stateOfOrigin || ""}
                      error={!!(errors.stateOfOrigin && touched.stateOfOrigin)}
                      onChange={(event) => {
                        handleChange(event);
                        getLgas(event.target.value);
                      }}
                      onBlur={handleBlur}
                      labelId="state"
                      id="demo-simple-select"
                      label="State of Origin"
                      MenuProps={{ disablePortal: true }}
                    >
                      {stateData?.map((state) => {
                        return (
                          <MenuItem key={state.id} value={state.id}>
                            {state.state}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText>{errors.stateOfOrigin}</FormHelperText>
                  </FormControl>
                </Box>

                <Box margin="0 5px" flex={1}>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="lga">LGA of Origin</InputLabel>
                    <Select
                      type="text"
                      name="lgaOfOrigin"
                      value={values.lgaOfOrigin || ""}
                      error={!!(errors.lgaOfOrigin && touched.lgaOfOrigin)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      labelId="lga"
                      id="demo-simple-select"
                      label="LGA of Origin"
                      MenuProps={{ disablePortal: true }}
                    >
                      {lgas?.map((lga) => {
                        return (
                          <MenuItem key={lga.id} value={lga.id}>
                            {lga.lga}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText>{errors.lgaOfOrigin}</FormHelperText>
                  </FormControl>
                </Box>
              </Box>

              <Box className={styles.textFieldContainer}>
                <TextField
                  type="text"
                  name="phone"
                  value={values.phone || ""}
                  helperText={errors.phone}
                  error={!!(errors.phone && touched.phone)}
                  label="Phone Number"
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <TextField
                  type="text"
                  name="address"
                  label="Address"
                  variant="outlined"
                  value={values.address || ""}
                  error={!!(errors.address && touched.address)}
                  helperText={errors.address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Box>

              <Box className={styles.textFieldContainer}>
                <TextField
                  type="text"
                  name="email"
                  value={values.email || ""}
                  helperText={errors.email}
                  error={!!(errors.email && touched.email)}
                  label="Email"
                  variant="outlined"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  sx={{ flex: 1 }}
                />

                {!hidePassword && (
                  <TextField
                    type={showPassword ? "text" : "password"}
                    name="accountPassword"
                    label="Account Password"
                    value={values.accountPassword || ""}
                    error={
                      !!(errors.accountPassword && touched.accountPassword)
                    }
                    helperText={errors.accountPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    sx={{ flex: 1 }}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default PersonalInfo;
