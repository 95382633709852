import { useEffect } from "react";
import DashboardLayout from "components/Dashboard/DashboardLayout";
import { useAuthProfile } from "hooks/auth";
import { RouteComponentProps } from "react-router";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import DashboardHome from "views/dashboard/home";
import NotFound from "components/NotFound";
import PageLayout from "components/PageLayout";
import EditProfile from "views/profile/index";
import AddStudent from "views/addStudent/registerStudent";
import Settings from "views/SettingsPage";
import AddUser from "views/users/add";
import ViewUsers from "views/users/list";
import ListStudents from "views/addStudent/listStudents";
import ClassReport from "views/report/class";
import ClassReportList from "views/report/list";
import ChangePassword from "views/auth/ChangePassword";
import ReportGroup from "views/report/reportGroup";
import SubjectReport from "views/report/subject/report";
import SubjectReportSheet from "views/report/subject/list";
import ReportGroupbyClass from "views/report/reportGenerator";
import CreateResultPin from "views/result/pins/resultPin";
import ViewResultPins from "views/result/pins/viewPins";
import CumulativeResult from "views/result/cumulative/session";
import CumulativeResultGroup from "views/result/cumulative/result";
import ClassResult from "views/result/class";
import ViewClassResult from "views/result/class/view";
import NurseryClassReport from "views/report/nursery/class";
import NurserySubjectReport from "views/report/nursery/subject";
import NurserySubjectReportSheet from "views/report/nursery/subject/NurserySubjectReportSheet";
import NurseryClassReportList from "views/report/nursery/class/NurseryClassReportList";
import StudentByClass from "views/addStudent/studentByClass";
/** Renders the dashboard routes */
const DashboardRoute = (props: RouteComponentProps) => {
  const {
    data: profile,
    isLoading,
    isFetching,
    error,
    refetch,
  } = useAuthProfile();

  const history = useHistory();

  useEffect(() => {
    const unRegister = history.listen(() => {
      window.scrollTo({
        left: 0,
        top: 0,
        behavior: "smooth",
      });
    });
    return unRegister;
  }, [history]);

  return (
    <PageLayout
      loading={isFetching || isLoading}
      error={error}
      onRetry={() => refetch()}
      disablePageMargins
    >
      <DashboardLayout account={profile!}>
        <Switch>
          <Route
            path="/dashboard/"
            exact
            render={() => <Redirect to="/dashboard/home" />}
          />
          <Route exact path="/dashboard/home" component={DashboardHome} />
          <Route
            exact
            path="/dashboard/account/profile"
            component={EditProfile}
          />
          <Route
            exact
            path="/dashboard/students/add_students"
            component={AddStudent}
          />
          <Route
            exact
            path="/dashboard/students/list_student"
            component={ListStudents}
          />
          <Route
            exact
            path="/dashboard/students/student_by_class"
            component={StudentByClass}
          />
          <Route exact path="/dashboard/account/add_user" component={AddUser} />
          <Route
            exact
            path="/dashboard/account/list_user"
            component={ViewUsers}
          />
          <Route
            exact
            path="/dashboard/account/change_password"
            component={ChangePassword}
          />

          <Route
            path="/dashboard/result/result_pin"
            exact
            component={CreateResultPin}
          />
          <Route
            path="/dashboard/result/list_result_pin"
            exact
            component={ViewResultPins}
          />
          <Route
            exact
            path="/dashboard/report/class_report"
            component={ClassReport}
          />
          <Route exact path="/dashboard/result/class" component={ClassResult} />

          <Route
            exact
            path="/dashboard/result/class/view"
            component={ViewClassResult}
          />

          <Route
            exact
            path="/dashboard/report/group_report"
            component={ReportGroup}
          />
          <Route
            exact
            path="/dashboard/report/class_report_list"
            component={ClassReportList}
          />
          <Route
            exact
            path="/dashboard/report/subject_report"
            component={SubjectReport}
          />
          <Route
            exact
            path="/dashboard/report/subject_report_list"
            component={SubjectReportSheet}
          />

          <Route
            exact
            path="/dashboard/report/reportgroupbyclass"
            component={ReportGroupbyClass}
          />

          <Route
            exact
            path="/dashboard/report/nursery_class_report"
            component={NurseryClassReport}
          />

          <Route
            exact
            path="/dashboard/report/nursery_class_report_list"
            component={NurseryClassReportList}
          />
          <Route
            exact
            path="/dashboard/report/nursery_subject_report"
            component={NurserySubjectReport}
          />
          <Route
            exact
            path="/dashboard/report/nursery_subject_report_list"
            component={NurserySubjectReportSheet}
          />

          <Route
            exact
            path="/dashboard/result/cumulative_result"
            component={CumulativeResult}
          />
          <Route
            exact
            path="/dashboard/result/cumulative_result_group"
            component={CumulativeResultGroup}
          />
          <Route exact path="/dashboard/settings" component={Settings} />
          <Route path="*" render={() => <NotFound homeUrl="/dashboard" />} />
        </Switch>
      </DashboardLayout>
    </PageLayout>
  );
};

export default DashboardRoute;
