import DashboardProfile from "./DashboardProfile";

const EditProfile = () => {
  return (
    <>
      <DashboardProfile />
    </>
  );
};

export default EditProfile;
