import { Button, ButtonGroup } from "@mui/material";
import Table, { RowData } from "components/Table";

import DeleteIcon from "@mui/icons-material/Delete";

import Edit from "@mui/icons-material/Edit";
import { IReportCommentSetting , IReportCommentSettingFormValue} from "types/report";

interface ReportCommentTableProps {
  reportComments: IReportCommentSetting[];
  onUpdateReportComment?: (values: IReportCommentSettingFormValue) => any;
  onDeleteReportComment?: (reportCommntId: number) => any;
  openAddReportCommentModal: () => void;
}

const ReportCommentTable = (props: ReportCommentTableProps) => {
  const { reportComments, openAddReportCommentModal, onUpdateReportComment, onDeleteReportComment } =
    props;
  
    return (
    <Table
      title={{ label: "Manage Report Comments" }}
      disableSideSheet={true}
      ctaLabel="Add Report Comment"
      onCTAClicked={openAddReportCommentModal}
      rows={reportComments}
      columns={[
        {
          id: "title",
          label: "Report Title",
        },
        {
          id: "comment",
          label: "Comment",
        },

        {
          id: "actions",
          label: "Action",
          renderer: (item: RowData) => {
            const value = item as IReportCommentSettingFormValue;
            return (
              <ButtonGroup size="small">

                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    onDeleteReportComment?.(value.id);
                  }}
                  style={{ whiteSpace: "nowrap" }}
                  variant="outlined"
                >
                  
                  <DeleteIcon sx={{ "&:hover": { color: "#ff5722" } }} />
                </Button>

                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    onUpdateReportComment?.(value);
                  }}
                  style={{ whiteSpace: "nowrap" }}
                  variant="outlined"
                >
                  
                  <Edit sx={{ "&:hover": { color: "#ff5722" } }} />
                </Button>
              </ButtonGroup>
            );
          },
        },
      ]}
    />
  );
};

export default ReportCommentTable;
