import Table, { RowData } from "components/Table";
import { IGetListPinValues, IPins } from "types/results";
import { Button, ButtonGroup } from "@mui/material";
import PrintIcon from "@mui/icons-material/Print";
import DeleteIcon from "@mui/icons-material/Delete";

interface PinsTableProps {
  pins: IPins[];
}

const PinsTable = (props: PinsTableProps) => {
  const { pins } = props;

  return (
    <Table
      title={{ label: "MANAGE PINS" }}
      disableSideSheet={true}
      rows={pins}
      columns={[
        {
          id: "token",
          label: "PINS",
        },
        {
          id: "status",
          label: "Status",
        },
        {
          id: "created_at",
          label: "Generated Time",
        },

        {
          id: "action",
          label: "",
          renderer: (item: RowData) => {
            const value = item as IGetListPinValues;
            return (
              <ButtonGroup size="medium">
                <Button style={{ whiteSpace: "nowrap" }} variant="outlined">
                  <PrintIcon sx={{ "&:hover": { color: "#ff5722" } }} />
                </Button>

                <Button style={{ whiteSpace: "nowrap" }} variant="outlined">
                  <DeleteIcon sx={{ "&:hover": { color: "#ff5722" } }} />
                </Button>
              </ButtonGroup>
            );
          },
        },
      ]}
    />
  );
};

export default PinsTable;
