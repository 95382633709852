import PageLayout from "components/PageLayout";

const DashboardHome = () => {
  return (
    <PageLayout loading={false} error={false}>
      <h3>DASHBOARD</h3>
    </PageLayout>
  );
};

export default DashboardHome;
