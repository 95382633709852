export const subscriptionData = [
  {
    value: '80',
    plan: 'Starter plan for a month',
    starColor: '#969696',
    valueI: '$80',
    formValue: 'starter',
    period: '/month',
    label: '',
    type: 'starter'
  },
  {
    value: '150',
    plan: 'Monthly Basic plan',
    starColor: '#969696',
    valueI: '$150',
    formValue: 'basic',
    period: '/month',
    label: '',
    type: 'basic'
  },
  {
    value: '300',
    plan: 'Get premium access',
    starColor: '#70757a',
    valueI: '$300',
    formValue: 'premium',
    period: '/month',
    label: '',
    type: 'premium'
  }
]

export const subscriptionInfoStarter = [
  { type: 'Starter', icon: 'none', display: 'flex', price: '$80' },
  { info: 'Totorial pack', icon: 'initial', display: 'none' },
  {
    info: 'Connect up to % online devices',
    icon: 'initial',
    display: 'none'
  },
  { info: 'Unlimited banwidth and thime', icon: 'initial', display: 'none' },
  {
    info: 'Supporting protocols devices',
    icon: 'initial',
    display: 'none'
  }
]

export const subscriptionInfoBasic = [
  { type: 'Basic', icon: 'none', display: 'flex', price: '$150' },
  { info: 'Totorial pack', icon: 'initial', display: 'none' },
  {
    info: 'Connect up to % online devices',
    icon: 'initial',
    display: 'none'
  },
  { info: 'Unlimited banwidth and thime', icon: 'initial', display: 'none' },
  {
    info: 'Supporting protocols devices',
    icon: 'initial',
    display: 'none'
  },
  {
    info: 'Connect up to % online devices',
    icon: 'initial',
    display: 'none'
  }
]

export const subscriptionInfoPremium = [
  { type: 'Premium', icon: 'none', display: 'flex', price: '$300' },
  { info: 'Totorial pack', icon: 'initial', display: 'none' },
  {
    info: 'Advanced instructors search',
    icon: 'initial',
    display: 'none'
  },
  { info: 'Post event', icon: 'initial', display: 'none' },
  {
    info: 'Supporting protocols devices',
    icon: 'initial',
    display: 'none'
  },

  { info: 'Unlimited banwidth and thime', icon: 'initial', display: 'none' },
  {
    info: 'Moneterization',
    icon: 'initial',
    display: 'none'
  }
]
