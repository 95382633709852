import { Card, CardContent, CardHeader, Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import UpdateProfileForm from "./UpdateProfileForm";
import { a11yProps, TabPanel } from "components/Tab/TabPanel";
import AccountDetails from "./AccountDetails";
import { useProfileMutation } from "hooks/dashboard";
import { GenericAPIResponse } from "types/generic";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import styles from "./profile.module.css";
import { TabStyle, TabsStyle } from "components/Tab/TabStyles";

const DashboardProfile = () => {
  const [value, setValue] = React.useState(0);

  const handleChangeValue = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // Handling Submit process for profile details update
  const { mutateAsync: submitProfilePostRequest } = useProfileMutation({
    onError: (error: AxiosError) => handleSubmitError(error), //Error handle tech debt to keep API the way it is.
    onSuccess: (data: GenericAPIResponse<any>) => handleSubmitSuccess(data),
  });

  const handleSubmitError = (error?: AxiosError) => {
    const data = error || {
      message: "We couldn't process your request! Try again",
    };
    toast(data.message, { type: "error" });
  };

  const handleSubmitSuccess = (data: GenericAPIResponse<any>) => {
    //set cookie, then proceed to dashboard.
    if (data.code === 1) {
      toast("Account details updated successfully!", { type: "success" });
    } else {
      handleSubmitError();
    }
  };

  return (
    <Box className={styles.cardMargin}>
      <Card sx={{ ".MuiCardHeader-root": { padding: "16px 16px 0" } }}>
        <CardHeader
          sx={{
            ".MuiCardHeader-title": {
              color: "#0aa89e",
              fontSize: { xs: "16px", md: "20px" },
              padding: "8px 0",
              marginBottom: "0",
            },
          }}
          title="UPDATE YOUR PROFILE DETAILS"
        />

        <CardContent>
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                borderBottom: 0,
                ".MuiTabs-indicator": {
                  display: "none",
                },
              }}
            >
              <Tabs
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                value={value}
                onChange={handleChangeValue}
                sx={TabsStyle}
              >
                <Tab sx={TabStyle} label="Profile Details" {...a11yProps(0)} />
                <Tab sx={TabStyle} label="Account Details" {...a11yProps(1)} />
              </Tabs>
            </Box>

            <Box padding={{ xs: "1rem 0", sm: "2rem" }}>
              <TabPanel value={value} index={0}>
                <UpdateProfileForm onSubmit={submitProfilePostRequest} />
              </TabPanel>

              <TabPanel value={value} index={1}>
                <AccountDetails />
              </TabPanel>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default DashboardProfile;
