import { Button, ButtonGroup } from "@mui/material";
import Table, { RowData } from "components/Table";

import DeleteIcon from "@mui/icons-material/Delete";

import Edit from "@mui/icons-material/Edit";
import { IAddTermValues, Term } from "types/terms";

interface ITermTableProps {
  terms: Term[];
  onUpdateTerm?: (values: IAddTermValues) => any;
  onDeleteTerm?: (termId: number) => any;
  openAddTermModal: () => void;
}

const TermTable = (props: ITermTableProps) => {
  const { terms, openAddTermModal, onUpdateTerm, onDeleteTerm } =
    props;

  return (
    <Table
      title={{ label: "Manage Terms" }}
      disableSideSheet={true}
      ctaLabel="Add Term"
      onCTAClicked={openAddTermModal}
      rows={terms}
      columns={[
        {
          id: "term",
          label: "Term Name",
        },
        {
          id: "status",
          label: "Status",
        },

        {
          id: "actions",
          label: "Action",
          renderer: (item: RowData) => {
            const value = item as Term;
            return (
              <ButtonGroup size="small">

                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    onDeleteTerm?.(value.id);
                  }}
                  style={{ whiteSpace: "nowrap" }}
                  variant="outlined"
                >
                  
                  <DeleteIcon sx={{ "&:hover": { color: "#ff5722" } }} />
                </Button>

                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    onUpdateTerm?.(value);
                  }}
                  style={{ whiteSpace: "nowrap" }}
                  variant="outlined"
                >
                  
                  <Edit sx={{ "&:hover": { color: "#ff5722" } }} />
                </Button>
              </ButtonGroup>
            );
          },
        },
      ]}
    />
  );
};

export default TermTable;
