import React from "react";
import { GenericAPIResponse } from "types/generic";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { useSubmitSubjectReport } from "hooks/reports";
import SubjectReportList from "views/report/subject/list/SubjectReportList";


const NurserySubjectReportSheet = () => {

  const { mutateAsync: saveReportDetails } = useSubmitSubjectReport({
    onError: (error: AxiosError) => handleSubmitError(error),
    onSuccess: (data: GenericAPIResponse<any>) => handleSubmitSuccess(data),
  });

  const handleSubmitError = (error?: AxiosError) => {
    const data = error || {
      message: "We couldn't process your request! Try again",
    };
    toast(data.message, { type: "error" });
  };

  const handleSubmitSuccess = (data: GenericAPIResponse<any>) => {
    if (data.code === 1) {
      toast("Nursery Subject report details saved successfully!", { type: "success" });
    } else {
      handleSubmitError();
    }
  };

  return (
    <>
      <SubjectReportList renderAsNursery={true} onSubmit={saveReportDetails} />
    </>
  );
};

export default NurserySubjectReportSheet;
