import React from 'react'
import { Box, Typography } from "@mui/material";



const SampleComponent = ({row}) => {
  return (
    <Box>
      <Typography>Name: {row.name}</Typography>
      <Typography>Class: {row.class}</Typography>
      <Typography>Intelligence Quotient: {row.intelligence}</Typography>
      <Typography>Score: {row.score}</Typography>
      <Typography>Remark: {row.remark.description}</Typography>
      <Typography>Total: Score {row.score} out of {row.remark.numOfQuestion}</Typography>
    </Box>
  )
}

export default SampleComponent