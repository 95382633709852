import { get, post } from "api";
import { decodeToken } from "api/cookie";
import { MutationOptions } from "hooks/types";
import { QueryOptions, useMutation, useQuery } from "react-query";
import { GenericAPIResponse } from "types/generic";
import { IAddUserFormValues, IUser } from "types/users";

export const useAddUserMutation = (
  options?: MutationOptions<GenericAPIResponse<any>>
) => {
  const userId = decodeToken().userid;
  return useMutation(
    (value: IAddUserFormValues) =>
      post(`user/adduser`, {
        firstname: value.firstName,
        lastname: value.lastName,
        email: value.email,
        phone: value.phoneNumber,
        privilege: value.role,
        password: value.password,
        class: value.class,
        user: userId,
      }),
    { ...(options || {}) }
  );
};

export const useUsers = (options?: QueryOptions<IUser[]>) => {
  const userid = decodeToken().userid;

  return useQuery<IUser[]>(
    ["users", userid],
    () => get(`user/listuser/${userid}`),
    { ...(options || {}) }
  );
};

export const useUnLockUserMutation = (
  options?: MutationOptions<GenericAPIResponse<any>>
) => {
  const userId = decodeToken().userid;
  return useMutation(
    (id: number) =>
      post(`user/unlock`, {
        id,
        user: userId,
      }),
    { ...(options || {}) }
  );
};

export const useLockUserMutation = (
  options?: MutationOptions<GenericAPIResponse<any>>
) => {
  const userId = decodeToken().userid;
  return useMutation(
    (id: number) =>
      post(`user/lock`, {
        id,
        user: userId,
      }),
    { ...(options || {}) }
  );
};
