import React from "react";
import { Card as MUICard, CardContent, CardHeader } from "@mui/material";
import { lightGrey, primary } from "config/colors";
import styled from 'styled-components';

const StyledCardHeader = styled(CardHeader)`
  border-bottom: 1px solid ${lightGrey}
`;

interface ICardProps {
  title: string;
  children: React.ReactNode | React.ReactNode[];
}

const Card = (props: ICardProps) => {
  const { title, children } = props;

  return (
    <MUICard>
      <StyledCardHeader
        title={
          <h2
            style={{
              color: primary,
              fontSize: 20,
            }}
          >
            {title}
          </h2>
        }
        sx={{ padding: { xs: "16px", md: "16px 24px" } }}
      />
      <CardContent sx={{ padding: { xs: "16px 24px", md: "24px" } }}>
        {children}
      </CardContent>
    </MUICard>
  );
};

export default Card;
