import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useFormik } from "formik";
import { IReportSettings } from "types/report";
import * as Yup from "yup";
import styles from "../../profile/profile.module.css";
import { SubmitButton } from "components/Common";
import DatePicker from "views/auth/Signup/DatePicker";

const validationSchema = Yup.object().shape({
  day_school_open: Yup.number().required(
    "Required! Please Select numbers of days"
  ),
  next_term_begins: Yup.string().required("Required! Please Select date"),
});

interface IClassReportFormProps {
  onSubmit?: (values: Partial<IReportSettings>) => Promise<any>;
  resultSetting?: IReportSettings;
}

const ReportForm = ({ onSubmit, resultSetting }: IClassReportFormProps) => {
  const initialValues: Partial<IReportSettings> = {
    next_term_begins: resultSetting?.next_term_begins,
    day_school_open: resultSetting?.day_school_open,
  };

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    isSubmitting,
    handleSubmit,
    setFieldValue,
    isValid,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values: Partial<IReportSettings>, { setSubmitting }) => {
      setSubmitting(true);
      await onSubmit?.(values);
      setSubmitting(false);
    },
  });

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box className={styles.textFieldContainer} flexDirection="column">
          <DatePicker
            onChange={(value) => setFieldValue("next_term_begins", value, true)}
            name="next_term_begins"
            value={values.next_term_begins || ""}
            helperText={errors.next_term_begins}
            error={!!(errors.next_term_begins && touched.next_term_begins)}
            defaultValue={resultSetting?.next_term_begins}
            onBlur={handleBlur}
            label="Next Term Begins"
          />
        </Box>

        <Box className={styles.textFieldContainer} flexDirection="column">
          <TextField
            type="number"
            name="day_school_open"
            label="Open days"
            variant="outlined"
            error={!!(errors.day_school_open && touched.day_school_open)}
            helperText={
              "This configures the number of school open days for the current session and term" ||
              errors.day_school_open
            }
            onChange={handleChange}
            onBlur={handleBlur}
            defaultValue={resultSetting?.day_school_open}
          />
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <SubmitButton
            disabled={isSubmitting || !isValid}
            type="submit"
            variant="contained"
            style={{ marginTop: 32 }}
          >
            {isSubmitting ? "Please wait..." : "Update"}
          </SubmitButton>
        </Box>
      </form>
    </Box>
  );
};

export default ReportForm;
