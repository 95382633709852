export const mobileColumnTable = {
    overflowX: "auto",
    backgroundColor: {xs:"transparent", md:"white"},
    boxShadow: "none",
    marginBottom: "2rem",

    ".MuiTableHead-root": {
      display: { xs: "none", md: "table-header-group !important" },
    },

    ".MuiTableBody-root": {
      display: { xs: "flex", md: "table-row-group !important" },
      flexDirection: { xs: "column", md: "row !important" },
    },

    ".MuiTableRow-root": {
      display: { xs: "flex !important", md: "table-row !important" },
      flexDirection: { xs: "column !important", md: "row !important" },
      background: {
        xs: "rgba(189, 207, 247, 0.13) !important ",
        md: "inherit !important",
      },
      marginBottom: { xs: "2rem", md: "0" },
    },

    ".MuiTableCell-root": {
      borderBottom: {
        xs: "0 !important",
        md: "1px solid rgba(224, 224, 224, 1) !important ",
        padding: "16px 10px !important",
        fontSize: "13px !important",
      },
    },

}