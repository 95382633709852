import React, { useEffect, useState } from "react";
import PersonalInfo from "../registerStudent/PersonalInfo";
import AcademicInfo from "../registerStudent/AcademicInfo";
import MedicalInfo from "../registerStudent/MedicalInfo";
import ParentInfo from "../registerStudent/ParentInfo";
import { Box } from "@mui/material";
import { useFormik } from "formik";
import { IStudentList } from "types/students";
import { updateValidationSchema } from "../registerStudent/ValidationSchema";
import { generateUUID } from "hooks/uuid";
import { SubmitButton } from "views/auth/Common";
import useS3Upload from "hooks/thirdparty/useS3Upload";
import { makeInitialValues } from "./helper";
import { AxiosError } from "axios";
import { GenericAPIResponse } from "types/generic";
import {
  useUpdateStudentMutation,
  useUpdateStudentProfileImage,
} from "hooks/students";
import { toast } from "react-toastify";

interface IUpdateStudentProps {
  row: IStudentList;
}
const ViewStudent = ({ row }: IUpdateStudentProps) => {
  const [profileImage, setProfileImage] = useState(row.avatar);

  const { progress, uploadAsync } = useS3Upload();

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    isSubmitting,
    setFieldValue,
    setValues,
    handleSubmit,
  } = useFormik({
    initialValues: makeInitialValues(row),
    validationSchema: updateValidationSchema,
    onSubmit: async (values, helper) => {
      // submit the form here,
      helper.setSubmitting(true);
      await submitToServer(values);
      helper.setSubmitting(false);
    },
  });

  useEffect(() => {
    setValues(makeInitialValues(row));
  }, [row]);

  const handleProfileUpload = async (event) => {
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);
    setProfileImage(url);
    const path = await uploadFileToS3(file);
    submitStudentUpdateProfileImageRequest({
      id: row.studentid,
      path: path,
    });
  };

  const uploadFileToS3 = async (file) => {
    const folder = "profile/";
    const currentFile = file;
    const fileName =
      folder + generateUUID() + currentFile.name.replaceAll(" ", "_");
    const path =
      "https://" +
      process.env.REACT_APP_AWS_BUCKET_NAME +
      ".s3.amazonaws.com/" +
      fileName;
    await uploadAsync(currentFile, fileName);
    setProfileImage(path);
    return path;
  };

  const handleSubmitError = (error?: AxiosError) => {
    const data = error || {
      message: "We couldn't process your request! Try again",
    };
    toast(data.message, { type: "error" });
  };

  const handleSubmitSuccess = (data: GenericAPIResponse<any>) => {
    if (data.code === 1) {
      toast("Student updated successfully!", { type: "success" });
    } else {
      handleSubmitError();
    }
  };

  const { mutateAsync: submitStudentUpdateProfileImageRequest } =
    useUpdateStudentProfileImage({
      onError: (error: AxiosError) => handleSubmitError(error),
      onSuccess: (data: GenericAPIResponse<any>) => handleSubmitSuccess(data),
    });

  const { mutateAsync: submitUpdateStudentRequest } = useUpdateStudentMutation({
    onError: (error: AxiosError) => handleSubmitError(error),
    onSuccess: (data: GenericAPIResponse<any>) => handleSubmitSuccess(data),
  });

  const submitToServer = async (values) => {
    // then send over all the form data along with the uploaded file URL to the backend endpoint

    await submitUpdateStudentRequest({
      id: row.studentid,
      firstname: values.firstname,
      lastname: values.lastname,
      othername: values.othername,
      gender: values.gender,
      dob: values.dateOfBirth,
      admno: values.admissionNumber,
      class: values.class,
      stateoforigin: values.stateOfOrigin,
      lgaoforigin: values.lgaOfOrigin,
      email: values.email,
      phone: values.phone,
      address: values.address,

      // Academic Info
      lastsch: values.lastSchoolAttended,
      highclass: values.highestClassAttained,
      schstate: values.attendedSchoolState,
      schlga: values.lgaOfSchoolAttended,
      schoolAddress: values.schoolAddress,

      // Medical Info
      medicalcondition: values.medicalCondition,

      // Parent Info
      fathersName: values.fathersName,
      mothersName: values.mothersName,
      fathersAddress: values.fathersAddress,
      fathersReligion: values.fathersReligion,
      fathersOccupation: values.fathersOccupation,
      fathersPhone: values.fathersPhone,
      mothersPhone: values.mothersPhone,
      mothersAddress: values.mothersAddress,
      mothersReligion: values.mothersReligion,
      mothersOccupation: values.mothersOccupation,
      parentEmail: values.parentEmail,
    });
  };

  return (
    <Box sx={{ ".MuiCard-root": { boxShadow: "none !important" } }}>
      <form onSubmit={handleSubmit}>
        <PersonalInfo
          values={values}
          errors={errors}
          touched={touched}
          handleChange={handleChange}
          handleBlur={handleBlur}
          setFieldValue={setFieldValue}
          handleProfileUpload={handleProfileUpload}
          profileImage={profileImage}
          uploadProgress={progress}
          hidePassword={true}
        />
        <AcademicInfo
          values={values}
          errors={errors}
          touched={touched}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
        <MedicalInfo
          values={values}
          errors={errors}
          touched={touched}
          handleChange={handleChange}
          handleBlur={handleBlur}
        />
        <ParentInfo
          values={values}
          errors={errors}
          touched={touched}
          handleChange={handleChange}
          handleBlur={handleBlur}
          hidePassword={true}
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            background: "white",
            position: "sticky",
            bottom: "0",
            boxShadow: "10px 0px 8px rgb(0 0 0 / 30%)",
            zIndex: "1111",
            width: { xs: "100%", md: "100%" },
            padding: "8px",
          }}
        >
          <SubmitButton
            style={{ width: "50%" }}
            disabled={isSubmitting}
            type="submit"
            variant="contained"
            onClick={() => console.log(errors)}
          >
            {isSubmitting ? "Please wait..." : " Update Student"}
          </SubmitButton>
        </Box>
      </form>
    </Box>
  );
};

export default ViewStudent;
