import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
  Home,
  Logout,
  PeopleAltOutlined,
  AccountBox,
  Settings,
  ReportOutlined,
  PinOutlined,
  Visibility,
} from "@mui/icons-material";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import {
  viewChangePasswordUrl,
  viewAccountAddUserUrl,
  viewAccountListUserUrl,
  viewAccountProfileUrl,
  viewAddStudentUrl,
  viewListStudentUrl,
  classResultUrl,
  studentReportUrl,
  subjectReportUrl,
  reportGroupUrl,
  cumulativeResultUrl,
  nurseryClassResultUrl,
  nurseryStudentReportUrl,
  nurseryClassReportUrl,
  nurserySubjectReportUrl,
  nurseryStudentResultUrl,
  studentResultUrl,
  resultPinUrl,
  viewResultPinUrl,
  logVisitorsUrl,
  VisitorsHistoryUrl,
  checkinVisitorsUrl,
  classReportUrl,
  viewStudentByClassUrl,
} from "config/urls";
import { clearAuthCookies } from "api/cookie";
import { white } from "config/colors";
import { IAccount } from "types/auth";
import React from "react";
import { DropdownItem } from "types/sidebarDropdown";
import SidebarListItem from "./SidebarListItem";
import { useQueryClient } from "react-query";
import { RestoreOutlined } from "@material-ui/icons";
import { ListItemButton } from "@mui/material";

const AccountDropdownData: DropdownItem[] = [
  {
    primary: "Profile",
    url: viewAccountProfileUrl,
    key: "profile",
    hasParam: false,
  },
  {
    primary: "Add User",
    url: viewAccountAddUserUrl,
    key: "add_user",
    hasParam: false,
  },
  {
    primary: "List User",
    url: viewAccountListUserUrl,
    key: "list_user",
    hasParam: false,
  },
  {
    primary: "Change Password",
    url: viewChangePasswordUrl,
    key: "change_password",
    hasParam: false,
  },
];

const StudentDropdownData: DropdownItem[] = [
  {
    primary: "Add Student",
    url: viewAddStudentUrl,
    key: "add_student",
    hasParam: false,
  },
  {
    primary: "List Students",
    url: viewListStudentUrl,
    key: "list_student",
    hasParam: false,
  },
  {
    primary: "Student by Class",
    url: viewStudentByClassUrl,
    key: "student_by_class",
    hasParam: false,
  },
];

const ReportDropdownData: DropdownItem[] = [
  {
    primary: "Class Report",
    url: classReportUrl,
    key: "class_report",
    hasParam: false,
  },
  {
    primary: "Student Report",
    url: studentReportUrl,
    key: "student_report",
    hasParam: false,
  },
  {
    primary: "Subject Report",
    url: subjectReportUrl,
    key: "subject_report",
    hasParam: false,
  },
  {
    primary: "Report Group",
    url: reportGroupUrl,
    key: "report_group",
    hasParam: false,
  },
];

const NurseryReportDropdownData: DropdownItem[] = [
  {
    primary: "Class Report",
    url: nurseryClassReportUrl,
    key: "nur_class_report",
    hasParam: false,
  },
  {
    primary: "Student Report",
    url: nurseryStudentReportUrl,
    key: "nur_student_report",
    hasParam: false,
  },
  {
    primary: "Subject Report",
    url: nurserySubjectReportUrl,
    key: "nur_subject_report",
    hasParam: false,
  },
];

const NurseryResultDropdownData: DropdownItem[] = [
  {
    primary: "Class Result",
    url: nurseryClassResultUrl,
    key: "nur_class_result",
    hasParam: false,
  },
  {
    primary: "Student Result",
    url: nurseryStudentResultUrl,
    key: "nur_student_result",
    hasParam: false,
  },
];

const ResultDropdownData: DropdownItem[] = [
  {
    primary: "Class Result",
    url: classResultUrl,
    key: "class_result",
    hasParam: false,
  },
  {
    primary: "Student Result",
    url: studentResultUrl,
    key: "student_result",
    hasParam: false,
  },
  {
    primary: "Cumulative Result",
    url: cumulativeResultUrl,
    key: "cumulative_result",
    hasParam: false,
  },
];

const ResultPinDropdownData: DropdownItem[] = [
  {
    primary: "Create Pins",
    url: resultPinUrl,
    key: "result_pin",
    hasParam: false,
  },
  {
    primary: "List Pins",
    url: viewResultPinUrl,
    key: "result_list_pin",
    hasParam: false,
  },
];

const VisitorDropdownData: DropdownItem[] = [
  {
    primary: "Log Visitor",
    url: logVisitorsUrl,
    key: "log_visitor",
    hasParam: false,
  },
  {
    primary: "Visitors Checkin",
    url: checkinVisitorsUrl,
    key: "vistor_checkin",
    hasParam: false,
  },
  {
    primary: "Visitors History",
    url: VisitorsHistoryUrl,
    key: "vistor_history",
    hasParam: false,
  },
];

/** Renders the dashboard sidebar drawer menu */
const Sidebar = ({
  onItemClicked,
  account,
}: {
  onItemClicked?: Function;
  account: IAccount;
}) => {
  const queryClient = useQueryClient();
  return (
    <div>
      <InfoWrapper>
        <Avatar src="/default.png" />
        <Name>
          {account.firstname?.toUpperCase()} {account.lastname?.toUpperCase()}
        </Name>
      </InfoWrapper>

      <Divider />

      <List>
        <SidebarLink
          onClick={() => onItemClicked && onItemClicked()}
          to="/dashboard/home"
          activeClassName="activeLink"
        >
          <ListItemButton key="Home">
            <StyledListItemIcon>
              <Home />
            </StyledListItemIcon>
            <ListItemText primary="Home" />
          </ListItemButton>
        </SidebarLink>

        <SidebarListItem
          onItemClicked={onItemClicked}
          data={AccountDropdownData}
          name="Account"
        >
          <AccountBox />
        </SidebarListItem>

        <SidebarListItem
          onItemClicked={onItemClicked}
          data={StudentDropdownData}
          name="Students"
        >
          <PeopleAltOutlined />
        </SidebarListItem>

        <SidebarListItem
          onItemClicked={onItemClicked}
          data={ReportDropdownData}
          name="Exam Report"
        >
          <ReportOutlined />
        </SidebarListItem>

        <SidebarListItem
          onItemClicked={onItemClicked}
          data={NurseryReportDropdownData}
          name="Nursery Report"
        >
          <ReportOutlined />
        </SidebarListItem>

        <SidebarListItem
          onItemClicked={onItemClicked}
          data={ResultDropdownData}
          name="Results"
        >
          <RestoreOutlined />
        </SidebarListItem>

        <SidebarListItem
          onItemClicked={onItemClicked}
          data={NurseryResultDropdownData}
          name="Nursery Results"
        >
          <RestoreOutlined />
        </SidebarListItem>

        <SidebarListItem
          onItemClicked={onItemClicked}
          data={ResultPinDropdownData}
          name="Result Pin"
        >
          <PinOutlined />
        </SidebarListItem>

        <SidebarListItem
          onItemClicked={onItemClicked}
          data={VisitorDropdownData}
          name="Visitors"
        >
          <Visibility />
        </SidebarListItem>

        <SidebarLink
          onClick={() => onItemClicked && onItemClicked()}
          to="/dashboard/settings"
          activeClassName="activeLink"
        >
          <ListItemButton key="Settings">
            <StyledListItemIcon>
              <Settings />
            </StyledListItemIcon>
            <ListItemText primary="Settings" />
          </ListItemButton>
        </SidebarLink>
      </List>
      <Divider />
      <List>
        <ListItemButton
          key="Logout"
          onClick={() => {
            // @todo: if we move the logout logic from here, may be to some re-usable hook / page.
            // We should remember to also invaidate the cached queries to avoid use of stale caches.
            queryClient.invalidateQueries();
            clearAuthCookies();
            window.location.href = "/";
          }}
        >
          <StyledListItemIcon>
            <Logout />
          </StyledListItemIcon>
          <ListItemText primary="Logout" />
        </ListItemButton>
      </List>
    </div>
  );
};

export const StyledListItemIcon = styled(ListItemIcon)`
  & {
    color: #fff !important;
  }
`;

export const SidebarLink = styled(NavLink)`
  & {
    text-decoration: none !important;
    color: #fff;
    display: block;
  }
`;

const Name = styled.p`
  color: ${white};
  margin-left: 16px;
  font-weight: 500;
`;

const InfoWrapper = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
`;

const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px dashed ${white};
  padding: 4px;
`;

export default Sidebar;
