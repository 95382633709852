import React, { useRef, useState } from "react";
import { Box } from "@mui/material";
import GradeTable from "./GradeTable";
import {IGradeSystem } from "types/settings";
import { FieldArray, Form, Formik} from "formik";
import { toast } from "react-toastify";
import { SubmitButton } from "views/auth/Common";
import styles from "../../profile/profile.module.css";
import DeleteModal from "components/DeleteModal";
import validation from "./validation"


type IDeleteSelectedRow = {
  rowIndex: number;
};

interface ISaveGradeProps {
  onSubmit: (values: IGradeSystem) => Promise<any> | any;
  initialValues: IGradeSystem;
}

const Grades = ({ onSubmit, initialValues }: ISaveGradeProps) => {
  const [selectedRowIndex, setSelectedRowIndex] =
    useState<IDeleteSelectedRow | null>(null);

  const ref = useRef<HTMLDivElement | null>(null);

  const handleDeleteRow = async (pop) => {
    if (!selectedRowIndex) return;
    try {
      const rowIndex = selectedRowIndex.rowIndex;
      const response = await pop(rowIndex);
      if (response) {
        toast.success("Field deleted successfully!", {
          type: "success",
        });
        setSelectedRowIndex(null); // closes the modal
      } else {
        toast("Something went wrong", { type: "error" });
      }
    } catch {
      toast("Something went wrong. Please try again!", { type: "error" });
    }
  };

  const ScrollInToView = () => {
    ref?.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const addRow = (push, values) => {
    push({
      score: "",
      grade: "",
      comment: "",
    });
    toast("New field Added Succefully!", {
      type: "success",
    });
    ScrollInToView();
  };

  return (
    <Box>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting }) => {
          if (validation(values)) {
            setSubmitting(true);
            await onSubmit(values);
            setSubmitting(false);
          }
        }}
      >
        {({ values, handleChange, handleBlur, isSubmitting, isValid }) => (
          <FieldArray
            name="grades"
            render={({ push, pop }) => (
              <Form>
                <GradeTable
                  values={values}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  handleDelete={(rowIndex) => setSelectedRowIndex({ rowIndex })}
                />

                <Box className={styles.bottomNav}>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "1rem",
                      justifyContent: { xs: "center", md: "flex-end" },
                      width: "95%",
                    }}
                  >
                    <SubmitButton
                      style={{ borderRadius: "4px" }}
                      variant="outlined"
                      disabled={values.grades.length >= 6}
                      onClick={() => addRow(push, values)}
                    >
                      ADD GRADE
                    </SubmitButton>

                    <SubmitButton
                      style={{ borderRadius: "4px", boxShadow: "0" }}
                      disabled={isSubmitting || !isValid}
                      type="submit"
                      variant="contained"
                    >
                      {isSubmitting ? "Please wait..." : "Save"}
                    </SubmitButton>
                  </Box>
                </Box>

                <DeleteModal
                  open={selectedRowIndex != null}
                  prompt={"Do you want to delete this field"}
                  title="Delete Row"
                  onCancel={() => setSelectedRowIndex(null)}
                  onConfirm={() => handleDeleteRow(pop)}
                />
              </Form>
            )}
          />
        )}
      </Formik>
      <Box
        sx={{ minHeight: "10vh", background: "transparent" }}
        ref={ref}
      ></Box>
    </Box>
  );
};

export default Grades;
