import PageLayout from "components/PageLayout";
import { useListStudents } from "hooks/students";
import StudentsTable from "./Table";

const ListStudents = () => {
  const {
    data: students,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useListStudents();

  /** Fetches and renders student list in a tabular format */
  const Student = students?.map((student) => {
    return student;
  });

  return (
    <PageLayout
      loading={isLoading || isFetching}
      error={isError}
      onRetry={refetch}
    >
      <StudentsTable
        students={
          Student?.map((student) => {
            return student;
          }) || []
        }
      />
    </PageLayout>
  );
};

export default ListStudents;
