import {
  IClassReportList,
  IPostClassReportDetails,
  IRecordScore,
  IReportComment,
} from "types/report";

export const submitSaveRecordValues = (
  values: Partial<IClassReportList>,
  session: number,
  studentId: number,
  term: number 
): Partial<IPostClassReportDetails> => {
  return {
    student: studentId,
    session: session,
    term: term,
    attendance_count: values.attendance_count,
    report: values.headmasterreport,
    formreport: values.formmasterreport,

    grades: values.scores?.map((item) => {
      return {
        subject: item.subject_id,
        first_ca: item.first_ca || "",
        second_ca: item.second_ca || "",
        third_ca: item.third_ca || "",
        fourth_ca: item.fourth_ca || "",
        exam: item.exam || "",
      };
    }),
    ...values.comments,
  };
};

export const initialValues = (
  resultDetails?: IReportComment,
  reportTableData?: IRecordScore[]
): Partial<IClassReportList> => {
  return {
    headmasterreport: resultDetails?.headmasterreport || "",
    formmasterreport: resultDetails?.formmasterreport || "",
    attendance_count: resultDetails?.attendance_count || 0,

    comments: {
      neatness: resultDetails?.neatness || "",
      reliability: resultDetails?.reliability || "",
      attentiveness: resultDetails?.attentiveness || "",
      punctuality: resultDetails?.punctuality || "",
      politeness: resultDetails?.politeness || "",
      selfcontrol: resultDetails?.selfcontrol || "",
      sport: resultDetails?.sport || "",
      game: resultDetails?.game || "",
      fluency: resultDetails?.fluency || "",
      handwriting: resultDetails?.handwriting || "",
      honesty: resultDetails?.honesty || "",
      perseverance: resultDetails?.perseverance || "",
    },

    scores: reportTableData,
  };
};
