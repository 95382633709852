import Table from "components/Table";
import TextField from "components/Form/TextField";
import { useScreenSize } from "hooks/browser/useScreenSize";
import Typography from "@mui/material/Typography";
import { IGetSubjectReportTypes } from "types/report";
import { mobileColumnTable } from "components/Table/Style";
import { useFormikContext } from "formik";

const SubjectListTable = ({
  renderAsNursery,
}: {
  renderAsNursery?: boolean;
}) => {
  const { isSm, isMd } = useScreenSize();

  const { values, handleChange } = useFormikContext<IGetSubjectReportTypes>();

  const data = values?.data || [];

  const isNotWithinRange = (value: number) => {
    return value < 0 || value > 100 || isNaN(value);
  };

  const getColumns = () => {
    const columns: any[] = [
      {
        id: "name",
        label: "Student Name",
        renderer: (_, index) => {
          return (
            <Typography sx={{ fontSize: "13px", fontWeight: "600" }}>
              {`${data[index].student.firstname} ${data[index].student.lastname}`}
            </Typography>
          );
        },
      },
    ];

    if (!renderAsNursery) {
      columns.push(
        ...[
          {
            id: "first_ca",
            label: "FIRST CA",
            renderer: (_, index) => {
              return (
                <TextField
                  sx={{ width: { xs: "95%", md: "80%" } }}
                  type="number"
                  name={`data.${index}.result.first_ca`}
                  onChange={handleChange}
                  value={data[index].result.first_ca}
                  error={isNotWithinRange(
                    Number(data[index].result.first_ca || 0)
                  )}
                  label={isSm || isMd ? "FIRST CA" : ""}
                />
              );
            },
          },

          {
            id: "second_ca",
            label: "SECOND CA",
            renderer: (_, index) => {
              return (
                <TextField
                  sx={{ width: { xs: "95%", md: "80%" } }}
                  type="number"
                  name={`data.${index}.result.second_ca`}
                  onChange={handleChange}
                  value={data[index].result.second_ca}
                  error={isNotWithinRange(
                    Number(data[index].result.second_ca || 0)
                  )}
                  label={isSm || isMd ? "SECOND CA" : ""}
                />
              );
            },
          },

          {
            id: "third_ca",
            label: "THIRD CA",
            renderer: (_, index) => {
              return (
                <TextField
                  sx={{ width: { xs: "95%", md: "80%" } }}
                  type="number"
                  name={`data.${index}.result.third_ca`}
                  onChange={handleChange}
                  value={data[index].result.third_ca}
                  error={isNotWithinRange(
                    Number(data[index].result.third_ca || 0)
                  )}
                  label={isSm || isMd ? "THIRD CA" : ""}
                />
              );
            },
          },

          {
            id: "fourth_ca",
            label: "FOURTH CA",
            renderer: (_, index) => {
              return (
                <TextField
                  sx={{ width: { xs: "95%", md: "80%" } }}
                  type="number"
                  name={`data.${index}.result.fourth_ca`}
                  onChange={handleChange}
                  value={data[index].result.fourth_ca}
                  error={isNotWithinRange(
                    Number(data[index].result.fourth_ca || 0)
                  )}
                  label={isSm || isMd ? "FOURTH CA" : ""}
                />
              );
            },
          },
        ]
      );
    }

    columns.push({
      id: "exam",
      label: "EXAM",
      renderer: (_, index) => {
        return (
          <TextField
            sx={{ width: { xs: "95%", md: "80%" } }}
            type="number"
            name={`data.${index}.result.exam`}
            onChange={handleChange}
            value={data[index].result.exam}
            error={isNotWithinRange(Number(data[index].result.exam || 0))}
            label={isSm || isMd ? "EXAM" : ""}
          />
        );
      },
    });

    return columns;
  };

  return (
    <Table
      sx={mobileColumnTable}
      title={{ label: `${values.subject} Report Sheet` }}
      disableSideSheet
      rows={data}
      columns={getColumns()}
    />
  );
};

export default SubjectListTable;
