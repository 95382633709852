
import React, { useState } from "react";
import PageLayout from "components/PageLayout";
import { AxiosError } from "axios";
import { GenericAPIResponse } from "types/generic";
import { toast } from "react-toastify";
import {
  useAddTermMutation,
  useDeleteTerm,
  useEditTermMutation,
  useListTerms,
} from "hooks/term";
import { IAddTermValues } from "types/terms";
import TermTable from "./Table";
import DeleteModal from "components/DeleteModal";
import AddTermForm from "./AddTermForm";
import { Fab } from "@mui/material";
import { Add } from "@mui/icons-material";

const Terms = () => {
  const { isLoading, isFetching, isError, refetch, data } = useListTerms();

  const [getTermId, setGetTermId] = useState<number>(0);
  const [openTermModal, setTermModalOpen] = useState(false);
  const [openEditTermModal, setEditTermModalOpen] = useState(false);
  const [openDeleteAcctModal, setOpenDeleteAcctModal] = useState(false);
  const [editTermObj, setEditTermObj] = useState<IAddTermValues>();

  const handleSubmitError = (error?: AxiosError) => {
    const data = error || {
      message: "We couldn't process your request! Try again",
    };
    toast(data.message, { type: "error" });
  };

  const handleSubmitSuccess = (data: GenericAPIResponse<IAddTermValues>) => {
    if (data.code === 0) {
      handleSubmitError();
    } else {
      toast("Term added successfully!", { type: "success" });
      setTermModalOpen(false);
      refetch();
    }
  };

  const { mutateAsync: submitAddTermPostRequest } = useAddTermMutation({
    onError: (error: AxiosError) => handleSubmitError(error), //Error handle tech debt to keep API the way it is.
    onSuccess: (data: GenericAPIResponse<any>) => handleSubmitSuccess(data),
  });

  // Handle update Term
  const { mutateAsync: submitEditTermRequest } = useEditTermMutation({
    onError: (error: AxiosError) => handleSubmitError(error), //Error handle tech debt to keep API the way it is.
    onSuccess: (data: GenericAPIResponse<any>) => handleEditSuccess(data),
  });

  // Handle Delete Term
  const { mutateAsync: deleteTermRequest } = useDeleteTerm({
    onError: (error: AxiosError) => handleSubmitError(error), //Error handle tech debt to keep API the way it is.
    onSuccess: (data: GenericAPIResponse<any>) => handleDeleteSuccess(data),
  });

  const handleEditSuccess = (data: GenericAPIResponse<IAddTermValues>) => {
    if (data.code === 0) {
      handleSubmitError();
    } else {
      // success toast
      toast("Term updated successfully!", { type: "success" });
      setEditTermModalOpen(false);
      // refresh the data for account details.
      refetch();
    }
  };

  const handleDeleteSuccess = (data: GenericAPIResponse<IAddTermValues>) => {
    //set cookie
    if (data.code === 0) {
      handleSubmitError();
    } else {
      // success toast
      toast("Term deleted successfully!", { type: "success" });
      setOpenDeleteAcctModal(false);

      // refresh the data for account details.
      refetch();
    }
  };

  const handleOpenModal = () => {
    setTermModalOpen(true);
  };

  return (
    <PageLayout
      loading={isLoading || isFetching}
      error={isError}
      onRetry={refetch}
    >
      <TermTable
        openAddTermModal={handleOpenModal}
        terms={data || []}
        onDeleteTerm={(setId) => {
          setOpenDeleteAcctModal(true);
          setGetTermId(setId);
        }}
        onUpdateTerm={(vals) => {
          setEditTermObj(vals);
          setEditTermModalOpen(true);
        }}
      />
      {openTermModal && (
        <AddTermForm
          open={true}
          handleClose={() => setTermModalOpen(false)}
          onSubmit={submitAddTermPostRequest}
        />
      )}

      {openEditTermModal && (
        <AddTermForm
          open={true}
          isEdit
          data={editTermObj}
          handleClose={() => setEditTermModalOpen(false)}
          onSubmit={submitEditTermRequest}
        />
      )}

      {openDeleteAcctModal && (
        <DeleteModal
          prompt="Do you want to delete this term?"
          onCancel={() => setOpenDeleteAcctModal(false)}
          onConfirm={async () => {
            await deleteTermRequest(getTermId);
          }}
          title="Delete Term"
          open={true}
        />
      )}
       <Fab
        onClick={() => handleOpenModal()}
        color="primary"
        style={{ position: "fixed", right: 40, bottom: 40 }}
        sx={{ display: { md: "none" } }}
      >
        <Add />
      </Fab>
    </PageLayout>
  );
};

export default Terms;
