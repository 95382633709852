export const studentData =[
    {
        id: 1,
        name: "Marc GoldBridge",
        class:"Lower Class",
        intelligence: 4,
        score: 16,
        remark:{numOfQuestion:30, description:"Average" }
    },

    {
        id: 2,
        name: "Alistra Diony",
        class:"Middle Class",
        intelligence: 6 ,
        score: 19,
        remark:{numOfQuestion:30, description:"Good" }
    },

    {
        id: 3,
        name: "Camella Stones",
        class:"Upper Class",
        intelligence: 8 ,
        score: 25,
        remark:{numOfQuestion:30, description:"Execellent" }
    },


    {
        id: 4,
        name: "Syvillia Shawn",
        class:"Upper Class",
        intelligence: 8 ,
        score: 27,
        remark:{numOfQuestion:30, description:"Execellent" }
    },

    {
        id: 5,
        name: "Katlin Caroline",
        class:"Middle Class",
        intelligence: 5 ,
        score: 21,
        remark:{numOfQuestion:30, description:"Good" }
    },
]