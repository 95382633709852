import React from "react";
import TextField from "components/Form/TextField";
import { TextFieldContainer, SubmitButton } from "../Common";
import { Box } from "@mui/material";
import { Form, Formik } from "formik";
import { IResetPasswordFormValues } from "types/auth";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Provide a valid email address")
    .required("Email address is required"),
});

export interface IResetPasswordFormProps {
  onSubmit: (values: IResetPasswordFormValues) => Promise<any> | any;
}

const ResetPasswordForm = ({ onSubmit }: IResetPasswordFormProps) => {
  const initialValues: IResetPasswordFormValues = {};

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting}) => {
        setSubmitting(true);
        await onSubmit(values);
        setSubmitting(false);
      }}
      validationSchema={validationSchema}
    >
      {({ 
        values, 
        errors, 
        touched, 
        handleChange, 
        handleBlur, 
        isSubmitting, 
      }) => (
        <Form>
          <TextFieldContainer >
            <TextField
              type="text"
              name="email"
              value={values.email || ""}
              error={!!(errors.email && touched.email)}
              label="Your email address"
              variant="outlined"
              helperText={errors.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </TextFieldContainer>

          <Box
            flexDirection="row"
            textAlign="center"
            style={{ margin: "32px 0px" }}
          >
            <SubmitButton
              disabled={isSubmitting}
              type="submit"
              variant="contained"
            >
              {isSubmitting ? "Please wait..." : "Reset"}
            </SubmitButton>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default ResetPasswordForm;