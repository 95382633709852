import React from "react";
import TextField from "components/Form/TextField";
import { TextFieldContainer, SubmitButton } from "../Common";
import { Box } from "@mui/material";
import { Form, Formik } from "formik";
import { ISignUpFormValues } from "types/auth";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  school: Yup.string().required("School name is required").min(5, "Too Short!"),
  phone: Yup.string()
    .required("Phone number is required")
    .min(10, "Too Short!")
    .max(11, "Too Long!"),
  email: Yup.string()
    .email("Provide a valid email address")
    .required("Email address is required"),
  password: Yup.string()
    .min(7, "Password should be more than 6 characters")
    .required("Password is required"),
  repeatpassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm password"),
});

export interface ISignInFormProps {
  onSubmit: (values: ISignUpFormValues) => Promise<any> | any;
}

const SignupForm = ({ onSubmit }: ISignInFormProps) => {
  const initialValues: ISignUpFormValues = {};

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        await onSubmit(values);
        setSubmitting(false);
      }}
      validationSchema={validationSchema}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        isSubmitting,
      }) => (
        <Form>
          <TextFieldContainer>
            <TextField
              type="text"
              name="school"
              value={values.school || ""}
              helperText={errors.school}
              error={!!(errors.school && touched.school)}
              label="School Name"
              variant="outlined"
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </TextFieldContainer>

          <TextFieldContainer direction="row">
            <TextField
              type="text"
              name="email"
              value={values.email || ""}
              error={!!(errors.email && touched.email)}
              label="Your email address"
              variant="outlined"
              helperText={errors.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <TextField
              type="text"
              name="phone"
              error={!!(errors.phone && touched.phone)}
              helperText={errors.phone}
              value={values.phone || ""}
              label="Phone number"
              variant="outlined"
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </TextFieldContainer>

          <TextFieldContainer direction="row">
            <TextField
              type="password"
              name="password"
              label="Password"
              variant="outlined"
              value={values.password || ""}
              error={!!(errors.password && touched.password)}
              helperText={errors.password}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <TextField
              type="password"
              name="repeatpassword"
              value={values.repeatpassword || ""}
              helperText={errors.repeatpassword}
              error={!!(errors.repeatpassword && touched.repeatpassword)}
              onChange={handleChange}
              onBlur={handleBlur}
              label="Confirm password"
              variant="outlined"
            />
          </TextFieldContainer>
          <Box
            flexDirection="row"
            textAlign="center"
            style={{ margin: "32px 0px" }}
          >
            <SubmitButton
              disabled={isSubmitting}
              type="submit"
              variant="contained"
            >
              {isSubmitting ? "Please wait..." : "Register"}
            </SubmitButton>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default SignupForm;
