import AWS from "aws-sdk";
import { PutObjectRequest } from "aws-sdk/clients/s3";
import { useState } from "react";

const useS3Upload = () => {
  const region = process.env.REACT_APP_AWS_REGION;
  const bucket = process.env.REACT_APP_AWS_BUCKET_NAME || "cliqueng";
  const identityPoolId = process.env.REACT_APP_AWS_IDENTITY_POOL_ID || "";

  const [progress, setProgress] = useState(0);

  AWS.config.update({
    region: region,
    credentials: new AWS.CognitoIdentityCredentials({
      IdentityPoolId: identityPoolId,
    }),
  });

  /** Uploads the provided file asynchronously and returns a promise. */
  const uploadAsync = async (file: File, fileName: string) => {
    // reset the upload progress
    setProgress(0);
    const myBucket = new AWS.S3({
      apiVersion: "2006-03-01",
      params: { Bucket: bucket },
    });

    const params: PutObjectRequest = {
      ACL: "public-read",
      Body: file,
      Bucket: bucket,
      Key: fileName,
    };

    return await myBucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        setProgress(Math.round((evt.loaded / evt.total) * 100));
      })
      .promise();
  };

  return {
    uploadAsync,
    progress,
  };
};

export default useS3Upload;
