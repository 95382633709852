import { decodeToken } from "api/cookie";
import { QueryOptions, useMutation, useQuery } from "react-query";
import { deleteRequest, get, post, put } from "api";
import { MutationOptions } from "hooks/types";
import { GenericAPIResponse } from "types/generic";
import { IReportCommentSetting, IReportCommentSettingResponse } from "types/report";

export const useListReportSetting = (options?: QueryOptions<IReportCommentSettingResponse>) => {
    const userid = decodeToken().userid;
    return useQuery<IReportCommentSettingResponse>(
        ["settings", "comments"],
        () => get(`/settings/comments`),
        { ...(options || {}) }
    );
};


export const useAddReportCommentMutation = (
    options?: MutationOptions<GenericAPIResponse<any>>
) => {
    return useMutation(
        (value: IReportCommentSetting) =>
            post(`/settings/comment`, {
                title: value.title,
                comment: value.comment
            }),
        { ...(options || {}) }
    );
};


export const useEditReportCommentMutation = (
    options?: MutationOptions<GenericAPIResponse<any>>
) => {

    return useMutation(
        (value: IReportCommentSetting) =>
            put(`/settings/comment`, {
                title: value.title,
                comment: value.comment,
                id: value.id,
            }),
        { ...(options || {}) }
    );
};


export const useDeleteReportComment = (
    options?: MutationOptions<GenericAPIResponse<any>>
) => {
    return useMutation(
        (reportCommentId: number) => deleteRequest(`/settings/comments/${reportCommentId}`),
        { ...(options || {}) }
    );
};
