import { decodeToken } from "api/cookie";
import { QueryOptions, useMutation, useQuery } from "react-query";
import { deleteRequest, get, post, put } from "api";
import { IAddSessionValues, Session } from "types/sessions";
import { MutationOptions } from "hooks/types";
import { GenericAPIResponse } from "types/generic";

export const useListSessions = (options?: QueryOptions<Session[]>) => {
  const userid = decodeToken().userid;
  return useQuery<Session[]>(
    ["settings", "session"],
    () => get(`/settings/session`),
    { ...(options || {}) }
  );
};

export const useAddsessionMutation = (
  options?: MutationOptions<GenericAPIResponse<any>>
) => {
  return useMutation(
    (value: IAddSessionValues) =>
      post(`/students/sessions`, {
        session: value.session,
        status: value.status,
      }),
    { ...(options || {}) }
  );
};

export const useEditSessionMutation = (
  options?: MutationOptions<GenericAPIResponse<any>>
) => {
  return useMutation(
    (value: IAddSessionValues) =>
      put(`/students/sessions`, {
        session: value.session,
        status: value.status,
        id: value.id,
      }),
    { ...(options || {}) }
  );
};

export const useDeleteSession = (
  options?: MutationOptions<GenericAPIResponse<any>>
) => {
  return useMutation(
    (sessionId: number) =>
      deleteRequest(`/students/sessions`, { id: sessionId }),
    { ...(options || {}) }
  );
};
