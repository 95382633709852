import React, { useState } from "react";
import PageLayout from "components/PageLayout";
import {
  useLockUserMutation,
  useUnLockUserMutation,
  useUsers,
} from "hooks/users";
import UsersTable from "./Table";
import ConfirmationModal from "components/ConfirmationModal";
import { toast } from "react-toastify";

type ISelectedUserIntent = {
  userId: number;
  updateToLocked: boolean;
};

/** Fetches and renders users in a tabular format */
const ViewUsers = () => {
  const { data: users, isLoading, isFetching, isError, refetch } = useUsers();
  const { mutateAsync: unLockUserAsync } = useUnLockUserMutation();
  const { mutateAsync: lockUserAsync } = useLockUserMutation();

  const [selectedUserIntent, setSelectedUserIntent] =
    useState<ISelectedUserIntent | null>(null);

  const handleIntentConfirmation = async () => {
    if (!selectedUserIntent) return;
    try {
      const userId = selectedUserIntent.userId;
      const response = await (selectedUserIntent.updateToLocked
        ? lockUserAsync(userId)
        : unLockUserAsync(userId));
      if (response.code) {
        toast.success(response.message || "User updated successfully!", {
          type: "success",
        });
        setSelectedUserIntent(null); // closes the modal
        refetch();
      } else {
        toast(response.message || "Something went wrong", { type: "error" });
      }
    } catch {
      toast("Something went wrong. Please try again!", { type: "error" });
    }
  };

  return (
    <PageLayout
      loading={isLoading || isFetching}
      error={isError}
      onRetry={refetch}
    >
      <UsersTable
        users={users || []}
        onUpdateAccountStatus={(userId, updateToLocked) =>
          setSelectedUserIntent({ userId, updateToLocked })
        }
      />
      <ConfirmationModal
        open={selectedUserIntent != null}
        prompt={
          selectedUserIntent?.updateToLocked
            ? "Are you sure you want to lock this user? Once locked, user will no longer have access to the platform"
            : "Are you sure you want to un-lock this user account?"
        }
        title="Are you sure?"
        onCancel={() => setSelectedUserIntent(null)}
        onConfirm={handleIntentConfirmation}
      />
    </PageLayout>
  );
};

export default ViewUsers;
