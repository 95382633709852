import React, { useState } from "react";
import TextField from "components/Form/TextField";
import styles from "../../profile/profile.module.css";
import { Card, CardContent, CardHeader } from "@mui/material";
import { Box, InputLabel } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { useStateData, lgaData } from "hooks/dashboard";
import { ILgaData } from "../../../types/dashboard";

const AcademicInfo = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
}) => {
  const { data: stateData } = useStateData();

  const [lgas, setLgas] = useState<ILgaData[]>([
    {
      id: values.lgaOfSchoolAttended,
      state_id: values.attendedSchoolState,
      lga: values.lgaOfSchoolAttendedName,
    },
  ]);

  const getLgas = async (stateId: number) => {
    const data = await lgaData(stateId);
    setLgas(data);
  };

  return (
    <Box marginBottom="32px">
      <Card
        sx={{
          ".MuiCardHeader-root": { padding: "20px 0px 0px 14px" },
          minWidth: 275,
        }}
      >
        <CardHeader
          sx={{
            "&.MuiCardHeader-title": { padding: "0px", marginBottom: "0" },
          }}
          title={<h2 className={styles.headline}>Academic Info</h2>}
        />
        <CardContent
          sx={{ "&.MuiCardContent-root": { padding: "0 16px 24px 16px" } }}
        >
          <Box className={styles.textFieldContainer}>
            <TextField
              type="text"
              name="lastSchoolAttended"
              value={values.lastSchoolAttended || ""}
              error={
                !!(errors.lastSchoolAttended && touched.lastSchoolAttended)
              }
              label="Last School Attended"
              variant="outlined"
              helperText={errors.lastSchoolAttended}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <TextField
              type="text"
              name="highestClassAttained"
              error={
                !!(errors.highestClassAttained && touched.highestClassAttained)
              }
              helperText={errors.highestClassAttained}
              value={values.highestClassAttained || ""}
              label="Highest Class Attained"
              variant="outlined"
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Box>

          <Box className={styles.textFieldContainer}>
            <Box margin="0 5px" flex={1}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="state">State of Origin</InputLabel>
                <Select
                  type="text"
                  name="attendedSchoolState"
                  value={values.attendedSchoolState || ""}
                  error={
                    !!(
                      errors.attendedSchoolState && touched.attendedSchoolState
                    )
                  }
                  onChange={(event) => {
                    handleChange(event);
                    getLgas(event.target.value);
                  }}
                  onBlur={handleBlur}
                  labelId="state"
                  id="demo-simple-select"
                  label="State of School Attended"
                  MenuProps={{ disablePortal: true }}
                >
                  {stateData?.map((state) => {
                    return (
                      <MenuItem key={state.id} value={state.id}>
                        {state.state}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText>{errors.attendedSchoolState}</FormHelperText>
              </FormControl>
            </Box>

            <Box margin="0 5px" flex={1}>
              <FormControl sx={{ width: "100%" }}>
                <InputLabel id="lgaschool">LGA of School Attended</InputLabel>
                <Select
                  type="text"
                  name="lgaOfSchoolAttended"
                  value={values.lgaOfSchoolAttended || ""}
                  error={
                    !!(
                      errors.lgaOfSchoolAttended && touched.lgaOfSchoolAttended
                    )
                  }
                  onChange={handleChange}
                  onBlur={handleBlur}
                  labelId="lgaschool"
                  id="demo-simple-select"
                  label="LGA of School Attended"
                  MenuProps={{ disablePortal: true }}
                >
                  {lgas?.map((lga) => {
                    return (
                      <MenuItem key={lga.id} value={lga.id}>
                        {lga.lga}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText>{errors.lgaOfSchoolAttended}</FormHelperText>
              </FormControl>
            </Box>
          </Box>

          <Box className={styles.textFieldContainer}>
            <TextField
              type="text"
              name="schoolAddress"
              value={values.schoolAddress || ""}
              error={!!(errors.schoolAddress && touched.schoolAddress)}
              label="School Address"
              variant="outlined"
              helperText={errors.schoolAddress}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default AcademicInfo;
