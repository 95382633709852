import { Box, Button } from "@mui/material";
import React from "react";
import Card from "./Card";
import BlockIcon from "@mui/icons-material/Block";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

interface INoRecordProps {
  message?: string;
  title?: string;
  onCTAClicked?: Function;
}
const NoRecordFound = ({ message, title, onCTAClicked }: INoRecordProps) => {
  return (
    <Card title={title ? title : "Record Not Found"}>
      <Box sx={style}>
        <BlockIcon
          style={{ width: "3rem", height: "3rem", color: "#ff5622e4" }}
        />
        <p>
          {message
            ? message
            : "No Information available for the data you are looking for!"}
        </p>
        {onCTAClicked && (
          <Button variant="contained" onClick={() => onCTAClicked()}>
            <ChevronLeftIcon /> GO BACK
          </Button>
        )}
      </Box>
    </Card>
  );
};

export default NoRecordFound;

const style = {
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  gap: "8px",

  p: {
    fontWeight: "500",
    color: "var(--primary)",
    textAlign: "center",
  },

  button: {
    background: "#ff5622e4",
    marginTop: "8px",
    fontWeight: "500",

    ":hover": {
      background: "#ff5622f4",
    },
  },
};
