import { get, post, put } from "api";
import { MutationOptions } from "hooks/types";
import { QueryOptions, useMutation, useQuery } from "react-query";
import {
  IAccount,
  IAuthData,
  ILoginFormValues,
  IResetPasswordFormValues,
  IChangePasswordFormValues,
} from "types/auth";
import { GenericAPIResponse } from "types/generic";
import { decodeToken } from "../../api/cookie";

export const useSignUpMutation = (
  options?: MutationOptions<GenericAPIResponse<IAuthData>>
) => {
  return useMutation(
    (value: ILoginFormValues) => post("user/register", value),
    { ...(options || {}) }
  );
};

export const useLoginMutation = (
  options?: MutationOptions<GenericAPIResponse<IAuthData>>
) => {
  return useMutation((value: ILoginFormValues) => post("user/login", value), {
    ...(options || {}),
  });
};

export const useResetMutation = (
  options?: MutationOptions<GenericAPIResponse<IAuthData>>
) => {
  return useMutation(
    (value: IResetPasswordFormValues) => post("user/fpassword", value),
    { ...(options || {}) }
  );
};

export const useChangePasswordMutation = (
  options?: MutationOptions<GenericAPIResponse<IAuthData>>
) => {
  return useMutation(
    (value: IChangePasswordFormValues) => put("profile/changepassword", value),
    { ...(options || {}) }
  );
};

export const useAuthProfile = (options?: QueryOptions<IAccount>) => {
  const userid = decodeToken().userid;
  return useQuery<IAccount, any>(
    ["profile", userid],
    () => get(`profile/${userid}`),
    { ...(options || {}), cacheTime: 0, retry: false } // caching is disabled for profile route.
  );
};
