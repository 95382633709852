import { IStudentList } from "types/students";

export const makeInitialValues = (row: IStudentList) => {
  return {
    firstname: row?.firstname,
    lastname: row?.lastname,
    othername: row?.othername,
    gender: row?.gender,
    dateOfBirth: row?.dob,
    admissionNumber: row.admno,
    class: row?.class,
    stateOfOrigin: row?.stateoforigin,
    lgaOfOrigin: row?.lgaoforigin,
    lgaOfOriginName: row?.lgadata?.lga,
    accountPassword: row?.password,
    email: row?.email ? row?.email : row?.guardian?.email,
    phone: row?.phone ? row?.phone : row?.guardian?.fatherphone,
    address: row?.address ? row?.address : row?.guardian?.address,
    uploadProfile: row?.avatar,

    // Academic Info
    lastSchoolAttended: row?.lastsch?.attendschool,
    highestClassAttained: row?.lastsch?.highclass,
    attendedSchoolState: row?.lastsch?.state,
    lgaOfSchoolAttended: row?.lastsch?.lga,
    lgaOfSchoolAttendedName: row?.lastsch?.lgadata?.lga,
    schoolAddress: row?.lastsch?.address,

    // Medical Info
    medicalCondition: row?.med?.medcondition,

    // Parent Info
    fathersName: getParentName(row).fathersName,
    mothersName: getParentName(row).mothersName,
    fathersAddress: row?.guardian?.fatheraddress,
    fathersReligion: row?.guardian?.fatherreligion,
    fathersOccupation: row?.guardian?.fatheroccupation,
    fathersPhone: row?.guardian?.fatherphone,
    mothersPhone: row?.guardian?.motherphone || row.guardian?.phone,
    mothersAddress: row?.guardian?.address,
    mothersReligion: row?.guardian?.motherreligion,
    mothersOccupation: row?.guardian?.motheroccupation,
    parentEmail: row?.guardian?.email,
    parentAccountPassword: row?.guardian?.password,
  };
};

export const getParentName = (student: IStudentList) => {
  const guardianFullName = `${student.guardian?.firstname || ""} ${
    student.guardian?.lastname || ""
  }`;
  const fathersName = student.guardian?.fathername || guardianFullName;
  const mothersName = student.guardian?.mothername || guardianFullName;

  return {
    fathersName,
    mothersName,
  };
};
