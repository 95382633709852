import {
  viewCategoriesUrl,
  dashboardHomeUrl,
  viewAccountProfileUrl,
  viewAccountAddUserUrl,
  viewAccountUpdateUserUrl,
  viewAccountListUserUrl,
  viewAddStudentUrl,
  viewListStudentUrl,
  viewAssessmentsUrl,
  viewSettingstUrl
} from "config/urls";

const urlPath = [
  {
    title: "Home",
    path: dashboardHomeUrl(),
  },
  {
    title: "Categories",
    path: viewCategoriesUrl(),
  },
  {
    title: "Profile",
    path: viewAccountProfileUrl(),
  },
  {
    title: "Add User",
    path: viewAccountAddUserUrl(),
  },
  {
    title: "Update User",
    path: viewAccountUpdateUserUrl(),
  },
  {
    title: "List User",
    path: viewAccountListUserUrl(),
  },
  {
    title: "Add Student",
    path: viewAddStudentUrl(),
  },
  {
    title: "List Student",
    path: viewListStudentUrl(),
  },
  {
    title: "Assessments",
    path: viewAssessmentsUrl(),
  },
    {
    title: "Settings",
    path: viewSettingstUrl(),
  },
];

export const appName = (pathname) => {
  return urlPath.find((item) => item.path === pathname)?.title;
};
