import { Button, Link, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import AccountModalForm from "./AccountModal";
import {
  useAccountInfo,
  useAddAccountMutation,
  useDeleteAccountInfo,
} from "hooks/dashboard";
import { toast } from "react-toastify";
import { IAccountValues } from "types/profile";
import { GenericAPIResponse } from "types/generic";
import { AxiosError } from "axios";
import DeleteModal from "components/DeleteModal";
import DataLoader from "components/DataLoader";
import DataLoaderError from "components/DataLoaderError";

const AccountDetails = () => {
  // Set a state for opening the account dialog modal
  const [openAcctModal, setAcctModalOpen] = useState(false);
  const [openDeleteAcctModal, setOpenDeleteAcctModal] = useState(false);

  const [getAcctId, setGetAcctId] = useState<number>(0);

  //Handle get account details
  const { isLoading, data, error, refetch } = useAccountInfo();
  const acctData = data?.accounts;

  // Handle the add account submit process
  const { mutateAsync: submitAddAccountPostRequest } = useAddAccountMutation({
    onError: (error: AxiosError) => handleSubmitError(error), //Error handle tech debt to keep API the way it is.
    onSuccess: (data: GenericAPIResponse<any>) => handleSubmitSuccess(data),
  });

  const handleSubmitError = (error?: AxiosError) => {
    const data = error || {
      message: "We couldn't process your request! Try again",
    };
    toast(data.message, { type: "error" });
  };

  const handleSubmitSuccess = (data: GenericAPIResponse<IAccountValues>) => {
    //set cookie
    if (data.code === 0) {
      handleSubmitError();
    } else {
      // success toast
      toast("Account added successfully!", { type: "success" });
      setAcctModalOpen(false);

      // refresh the data for account details.
      refetch();
    }
  };

  // Handle Delete account details
  const { mutateAsync: deleteAccountDetailsRequest } = useDeleteAccountInfo({
    onError: (error: AxiosError) => handleSubmitError(error), //Error handle tech debt to keep API the way it is.
    onSuccess: (data: GenericAPIResponse<any>) => handledeleteSuccess(data),
  });

  const handledeleteSuccess = (data: GenericAPIResponse<IAccountValues>) => {
    //set cookie
    if (data.code === 0) {
      handleSubmitError();
    } else {
      // success toast
      toast("Account deleted successfully!", { type: "success" });
      setOpenDeleteAcctModal(false);

      // refresh the data for account details.
      refetch();
    }
  };

  if (isLoading) {
    return <DataLoader message="Loading Account Details..." />;
  } else if (error) {
    return (
      <DataLoaderError
        errorMessage="Loading Account Details Failed... "
        onRetry={() => refetch()}
      />
    );
  }

  return (
    <Box>
      {!acctData || acctData?.length == 0 ? (
        <Box display="flex" justifyContent="center">
          <Typography variant="h6" component="h6" color="#ff5722">
            Add Account
          </Typography>
        </Box>
      ) : (
        acctData.map((acct) => {
          return (
            <Box
              key={acct.id}
              display="flex"
              marginBottom="1rem"
              alignItems="center"
              justifyContent="space-between"
              flexDirection="row"
              borderBottom="1px solid #c7c6c66f"
            >
              <Box>
                <Typography
                  color="#0aa89e"
                  variant="h6"
                  component="p"
                  fontSize="16px"
                >
                  {acct.bank}
                </Typography>
                <Box paddingBottom="1rem">

                <Typography
                  
                  variant="subtitle1"
                  component="p"
                  color="#626161"
                >
                  {acct.acc_no}
                </Typography>
                </Box>
              </Box>
              <Link
                onClick={() => {
                  setGetAcctId(acct.id!);
                  setOpenDeleteAcctModal(true);
                }}
                sx={{
                  cursor: "pointer",
                  color: "#626161",
                  "&:hover": {
                    padding: "5px",
                    borderRadius: "50%",
                    background: "#c7c6c66f",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    transition: "all .3s ease-in-out",
                  },
                }}
              >
                <DeleteIcon sx={{ "&:hover": { color: "#ff5722" } }} />
              </Link>
            </Box>
          );
        })
      )}

      <Box display="flex" justifyContent="center">
        <Button
          onClick={() => setAcctModalOpen(true)}
          sx={{
            padding: "12px 22px",
            marginTop: "30px",
            fontWeight: "600",
            borderRadius: "50rem",
            minWidth: "140px",
          }}
          variant="contained"
        >
          ADD ACCOUNT DETAILS
        </Button>
      </Box>

      {openAcctModal && (
        <AccountModalForm
          open={true}
          handleClose={() => setAcctModalOpen(false)}
          onSubmit={submitAddAccountPostRequest}
        />
      )}

      {openDeleteAcctModal && (
        <Box>
          <DeleteModal
            prompt="Do you want to delete this account?"
            onCancel={() => setOpenDeleteAcctModal(false)}
            onConfirm={async () => {
              await deleteAccountDetailsRequest(getAcctId);
            }}
            title="Delete Account"
            open={true}
          />
          </Box>
      )}
    </Box>
  );
};

export default AccountDetails;
