import React from "react";
import { Box, Typography } from "@mui/material";
import { RoundedButton } from "./Common";
import Lottie from "react-lottie";
import AnimatedError from "../assets/animated/error.json";
import AnimatedLoading from "../assets/animated/loading.json";

interface IPageLayoutProps {
  loading: boolean;
  error: boolean;
  errorMessage?: string;
  children: React.ReactNode | React.ReactNode[];
  disablePageMargins?: boolean;
  onRetry?: () => any;
}

const PageLayout = ({
  loading,
  error,
  errorMessage,
  onRetry,
  children,
  disablePageMargins,
}: IPageLayoutProps) => {
  errorMessage =
    errorMessage ||
    "It's not you, it's us, but don't fret. Give us another try";

  const renderLoading = () => (
    <Box textAlign="center" style={{ marginTop: "10vh" }}>
      <Lottie
        options={{ loop: true, autoplay: true, animationData: AnimatedLoading }}
        width={300}
        isClickToPauseDisabled
        height={300}
      />
    </Box>
  );

  const renderError = () => (
    <Box textAlign="center" style={{ marginTop: "10vh" }}>
      <Lottie
        options={{ loop: true, autoplay: true, animationData: AnimatedError }}
        width={300}
        height={300}
        isClickToPauseDisabled
      />

      <Typography variant="subtitle1" fontWeight="bold" mt={-5}>
        Something went wrong
      </Typography>
      <Typography variant="body1" mb={4} mt={1}>
        {errorMessage}
      </Typography>

      <RoundedButton
        onClick={onRetry}
        variant="contained"
        style={{ minWidth: 180 }}
      >
        TRY AGAIN
      </RoundedButton>
    </Box>
  );

  return loading ? (
    renderLoading()
  ) : error ? (
    renderError()
  ) : (
    <Box
      sx={{ margin: disablePageMargins ? {} : { md: "32px", xs: "32px 0" } }}
    >
      {children}
    </Box>
  );
};

export default PageLayout;
