import PageLayout from "components/PageLayout";
import Card from "components/Card";
import { toast } from "react-toastify";
import CreatePinForm from "./CreatePinForm";
import { useCreatePinMutation } from "hooks/results";
import { useListSessions } from "hooks/session";
import { useListTerms } from "hooks/term";
import { IPostPinFormTypes } from "types/results";
import { Box } from "@mui/material";

const CreateResultPin = () => {
  const { mutateAsync: createPin } = useCreatePinMutation();

  const { isLoading: isSessionLoading } = useListSessions();
  const { isLoading: isTermLoading } = useListTerms();

  const handleSubmission = async (values: Partial<IPostPinFormTypes>) => {
    try {
      const response = await createPin(values as IPostPinFormTypes);
      if (response.code) {
        toast(response.message || "Pin created successfully!", {
          type: "success",
        });
      } else {
        toast(response.message || "Something went wrong", { type: "error" });
      }
    } catch (error) {
      toast("Something went wrong", { type: "error" });
    }
  };

  return (
    <Box sx={{ margin: { xs: "0", lg: "0 120px" } }}>
      <PageLayout loading={isSessionLoading || isTermLoading} error={false}>
        <Card title="CREATE PINS">
          <CreatePinForm onSubmit={handleSubmission} />
        </Card>
      </PageLayout>
    </Box>
  );
};

export default CreateResultPin;
