import Box from "@mui/material/Box";
import styles from "./styles.module.css";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { useEffect, useRef } from "react";
import { useEscapeKey } from "hooks/useEscapeKey";

type ISideSheetProps ={
  closeModal: () => void;
  children: React.ReactNode;
  open:  boolean;
  tableBodyRef: React.MutableRefObject<HTMLTableSectionElement|null>;
}
const SideSheet = ({ closeModal, children, open, tableBodyRef}: ISideSheetProps) => {
  // Close with escape key
  useEscapeKey(closeModal);

  //Close with outside click
  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    const clickedOutside = (e) => {
      if (
        open &&
        tableBodyRef.current &&
        !ref.current?.contains(e.target) &&
        !tableBodyRef?.current?.contains(e.target)
      ) {
        closeModal();
      }
    };

    document.addEventListener("mousedown", clickedOutside);

    return () => {
      document.removeEventListener("mousedown", clickedOutside);
    };
  }, []);

  return (
    <Box ref={ref} className={styles.contentWrapper}>
      <Box className={styles.iconWrapper}   sx={{".MuiSvgIcon-root":{fontSize: "36px"}}}>
        <KeyboardDoubleArrowRightIcon
          onClick={closeModal}
        />
      </Box>

      <Box className={styles.content}>{children}</Box>
    </Box>
  );
};

export default SideSheet;
