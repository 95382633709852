import { TextFieldContainer, SubmitButton } from "views/auth/Common";
import TextField from "components/Form/TextField";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { IAccountValues } from "types/profile";
import { Box, Dialog, DialogTitle, Typography } from "@mui/material";
import { Close } from "@mui/icons-material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { useBanksData } from "../../hooks/dashboard/index";

export interface SimpleDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}

const validationSchema = Yup.object().shape({
  accno: Yup.number()
    .required("Account number is required")
    .test(
      "len",
      "Account number must not be more or less than 10 digits!",
      (val) => val?.toString().length === 10
    ),

  bank: Yup.string().required("Please select a bank from the dropdown"),
});

export interface IAccountFormProps {
  onSubmit: (values: IAccountValues) => Promise<any> | any;
  open: boolean;
  handleClose: Function;
}

const AccountModalForm = ({
  onSubmit,
  open,
  handleClose,
}: IAccountFormProps) => {
  const initialValues: IAccountValues = {};
  const { isLoading, data: banks } = useBanksData();

  return (
    <Dialog
      sx={{ ".MuiTypography-root": { padding: "16px 0 0px 16px" } }}
      fullWidth={true}
      open={open}
      onClose={() => handleClose()}
    >
      <DialogTitle
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        Add Account
        <Close
          sx={{ marginRight: "25px", cursor: "pointer" }}
          onClick={() => handleClose()}
        />
      </DialogTitle>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          await onSubmit(values);
          setSubmitting(false);
        }}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isSubmitting,
        }) => (
          <Form>
            <TextFieldContainer
              direction="column"
              style={{ padding: "0 15px", gap: "15px" }}
            >
              <TextField
                type="number"
                name="accno"
                label="Account number"
                variant="outlined"
                value={values.accno || ""}
                error={!!(errors.accno && touched.accno)}
                helperText={errors.accno}
                onChange={handleChange}
                onBlur={handleBlur}
              />

              <Box margin="0 5px">
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="bank">Select a bank</InputLabel>
                  <Select
                    type="text"
                    name="bank"
                    error={!!(errors.bank && touched.bank)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={isLoading}
                    labelId="bank"
                    id="demo-simple-select"
                    label="Select a bank"
                  >
                    {banks?.map((bank) => {
                      return <MenuItem value={bank.bank}>{bank.bank}</MenuItem>;
                    })}
                  </Select>
                  <FormHelperText>{errors.bank}</FormHelperText>
                </FormControl>
              </Box>
            </TextFieldContainer>

            <Box
              flexDirection="row"
              textAlign="center"
              style={{ margin: "32px 0px" }}
            >
              <SubmitButton
                disabled={isSubmitting}
                type="submit"
                variant="contained"
              >
                {isSubmitting ? "Please wait..." : "Add Account"}
              </SubmitButton>
            </Box>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
export default AccountModalForm;
