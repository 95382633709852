import { Button } from "@mui/material";
import Table from "components/Table";
import TextField from "components/Form/TextField";
import { useScreenSize } from "hooks/browser/useScreenSize";
import { mobileColumnTable } from "components/Table/Style";

interface IGradeSystemProps {
  values: number | string | any;
  handleChange: React.ChangeEventHandler;
  handleBlur: React.FocusEventHandler;
  handleDelete: (rowIndex: number) => any;
}

const GradeTable = ({
  values,
  handleChange,
  handleDelete,
}: IGradeSystemProps) => {
  const { isSm } = useScreenSize();


  return (
    <Table
      sideSheetWidth="436px"
      sx={mobileColumnTable}
      disableFilter
      disableFooter
      disableSideSheet
      rows={values.grades}
      columns={[
        {
          id: "score",
          label: "SCORE AND ABOVE",
          renderer: (_, index) => {
            return (
              <TextField
                sx={{ ".MuiInputBase-input": { width: "100%" }, width: "100%" }}
                required
                onChange={handleChange}
                type="number"
                name={`grades.${index}.score`}
                value={values.grades[index].score}
                label={isSm ? "SCORE" : ""}
              />
            );
          },
        },

        {
          id: "grade",
          label: "GRADE",
          renderer: (_, index) => {
            return (
              <TextField
                sx={{ ".MuiInputBase-input": { width: "100%" }, width: "100%" }}
                required
                onChange={handleChange}
                type="text"
                name={`grades.${index}.grade`}
                value={values.grades[index].grade?.toUpperCase()}
                label={isSm ? "GRADE" : ""}
              />
            );
          },
        },

        {
          id: "comment",
          label: "COMMENT",
          renderer: (_, index) => {
            return (
              <TextField
                sx={{ ".MuiInputBase-input": { width: "100%" }, width: "100%" }}
                required
                onChange={handleChange}
                type="text"
                name={`grades.${index}.comment`}
                value={values.grades[index].comment}
                label={isSm ? "COMMENT" : ""}
              />
            );
          },
        },

        {
          id: "id",
          label: "",
          renderer: (_, index) => {
            return (
              <>
                <Button
                  sx={{
                    padding: "0px",
                    fontWeight: "600",
                    minWidth: "30px",
                    marginLeft: { xs: "8px", md: "0" },
                    width: { xs: "100%", md: "auto" },
                    background: "#F00",
                    "&:hover": {
                      background: "#ff0000e1",
                    },
                  }}
                  onClick={() => handleDelete(index)}
                  variant="contained"
                  disabled={values.grades.length <= 2}
                >
                  <span>{isSm ? "DELETE" : "X"}</span>
                </Button>
              </>
            );
          },
        },
      ]}
    />
  );
};

export default GradeTable;
