import { Box, CircularProgress, Typography } from "@mui/material";
import React from "react";

interface IDataLoaderProps {
  message?: string;
}

const DataLoader = ({ message }: IDataLoaderProps) => {
  return (
    <Box
      marginTop="1rem"
      display="flex"
      flexDirection="column"
      gap="1rem"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress sx={{ color: "#ff5722" }} size="30px" />
      <Typography variant="h6" component="h6" color="#ff5722">
        {message ? message : "Loading Data"}
      </Typography>
    </Box>
  );
};
export default DataLoader;
