import { deleteRequest, get, post } from "api";
import { decodeToken } from "api/cookie";
import { MutationOptions } from "hooks/types";
import { QueryOptions, useMutation, useQuery } from "react-query";
import {
  IAcctInfo,
  ILgaData,
  IUpdateProfileInfo,
} from "types/dashboard";
import { GenericAPIResponse } from "types/generic";
import { IAccountValues } from "types/profile";
import { IUpdateProfileValues } from "types/profile";
import { IStateData, IBankData } from "../../types/dashboard";

// Create a mutation for posting profile payload data
export const useProfileMutation = (
  options?: MutationOptions<GenericAPIResponse<any>>
) => {
  const userId = decodeToken().userid;
  return useMutation(
    (value: IUpdateProfileValues) =>
      post(`profile/update`, { ...value, user: userId }),
    { ...(options || {}) }
  );
};

// mutation for posting add account payload
export const useAddAccountMutation = (
  options?: MutationOptions<GenericAPIResponse<any>>
) => {
  const schoolId = decodeToken().school;
  return useMutation(
    (value: IAccountValues) =>
      post(`profile/account`, { ...value, id: schoolId }),
    { ...(options || {}) }
  );
};

// useQuery for get profile details request

export const useUpdateProfileInfo = (
  options?: QueryOptions<IUpdateProfileInfo>
) => {
  const userid = decodeToken().userid;

  return useQuery<IUpdateProfileInfo>(
    ["profile-details", userid],
    () => get(`profile/${userid}`),
    { ...(options || {}) }
  );
};

// useQuery for get account request

export const useAccountInfo = (options?: QueryOptions<IAcctInfo>) => {
  const userid = decodeToken().userid;

  return useQuery<IAcctInfo>(
    ["profile-details", userid],
    () => get(`profile/${userid}`),
    { ...(options || {}) }
  );
};

// Delete account mutation
export const useDeleteAccountInfo = (
  options?: MutationOptions<GenericAPIResponse<any>>
) => {
  return useMutation(
    (acctId: number) => deleteRequest(`profile/account`, { id: acctId }),
    { ...(options || {}) }
  );
};

// useQuery for updating school logo
export const useUpdateSchoolLogo = (
  options?: MutationOptions<GenericAPIResponse<any>>
) => {
  return useMutation(
    (value: { path: string }) => post(`profile/updatelogo`, value),
    { ...(options || {}) }
  );
};

/* General and usable across modules*/

// useQuery for get states
export const useStateData = (options?: QueryOptions<IStateData[]>) => {
  return useQuery<IStateData[]>(["states"], () => get(`user/states`), {
    ...(options || {}),
  });
};

export const useBanksData = (options?: QueryOptions<IBankData[]>) => {
  return useQuery<IBankData[]>(["banks"], () => get(`banks/`), {
    ...(options || {}),
  });
};

export const lgaData = async (stateId: number): Promise<ILgaData[]> => {
  return await get<ILgaData[]>(`user/lgas/${stateId}`);
};



