import React from "react";

import {
  TextField as MUITextField,
  TextFieldProps as MUITextFieldProps,
  SxProps,
  Theme,
  FormControl,
  FormHelperText,
  Select,
  MenuItem,
  InputLabel
} from "@mui/material";



type ITextFieldProps = {
  startIconNode?: React.ReactNode;
  overrideDefaultStyles?: boolean;
  styles?: React.CSSProperties;
};

const defaultStyles = {
  flexGrow: 1,
  margin: "0 6px",
};

const TextField = ({
  startIconNode,
  InputProps,
  overrideDefaultStyles = false,
  styles = {},
  sx,
  ...props
}: ITextFieldProps & MUITextFieldProps) => {
  InputProps = InputProps || {};
  InputProps = {
    ...InputProps,
    startAdornment: startIconNode || InputProps.startAdornment,
  };
  return <MUITextField sx={sx} style={overrideDefaultStyles ? styles : defaultStyles} InputProps={InputProps} {...props} />;
};

export default TextField;

type SelectOption = {
  value: string;
  label: string;
};


