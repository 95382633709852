import React, { useEffect} from "react";
import ReportTable from "./Table";
import Comments from "./Comments";
import { IPostClassReportDetails } from "types/report";
import { FormikProvider, useFormik } from "formik";
import Profile from "./Profile";
import { SubmitButton } from "views/auth/Common";
import { Box } from "@mui/material";
import { useLocation } from "react-router-dom";
import { useStudentReportDetails } from "hooks/reports";
import DataLoader from "components/DataLoader";
import DataLoaderError from "components/DataLoaderError";
import { isNotWithinRange } from "./validateScore";
import { initialValues, submitSaveRecordValues } from "./helper";
import { IStudentList } from "types/students";

const style = {
  display: "flex",
  justifyContent: "center",
  background: "white",
  position: "sticky",
  bottom: "0",
  boxShadow: "10px 0px 8px rgb(0 0 0 / 30%)",
  zIndex: "1111",
  width: { xs: "100%", md: "100%" },
  padding: "8px",
};

interface ISaveRecordProps {
  onSubmit: (values: Partial<IPostClassReportDetails>) => Promise<any> | any;
  row: IStudentList;
  renderAsNursery?: boolean
}

const EditReportDetails = ({ onSubmit, row, renderAsNursery }: ISaveRecordProps) => {
  const { search } = useLocation();

  //Get query parameter
  const [session, term, studentId] = [
    new URLSearchParams(search).get("session"),
    new URLSearchParams(search).get("term"),
    row?.id,
  ];


  
  //Fetch class report details using the query parameter
  const { isLoading, isFetching, data, refetch, error } =
    useStudentReportDetails(session, term, studentId);

  //function to transform the data to array of object
  function objectToArray(obj) {
    // check  for undefined
    if (!obj || typeof obj !== "object") {
      return [];
    }

    return Object.keys(obj).map((key) => {
      return {
        subjects: key,
        ...obj[key],
      };
    });
  }

  const reportTableData = objectToArray(data?.data); //subject and score data
  const resultDetails = data?.resultdetails; //comment details

  const formik = useFormik({
    initialValues: initialValues(resultDetails, reportTableData),
    enableReinitialize: true,

    onSubmit: async (
      value: Partial<IPostClassReportDetails>,
      { setSubmitting }
    ) => {
      const submitValues = submitSaveRecordValues(
        formik.values,
        Number(session),
        Number(studentId),
        Number(term)
      );
      if (isNotWithinRange(formik.values)) {
        setSubmitting(true);
        await onSubmit(submitValues);
        setSubmitting(false);
      }
    },
  });

  //handle refecth onChange of stduent ID
  useEffect(() => {
    refetch();
  }, [studentId]);

  // Handling error when loading or fetching data
  if (isLoading || isFetching) {
    return (
      <Box marginTop="5rem">
        <DataLoader />
      </Box>
    );
  } else if (error) {
    return (
      <Box marginTop="5rem">
        <DataLoaderError
          errorMessage="Loading Details Failed... "
          onRetry={() => refetch()}
        />
      </Box>
    );
  }

  return (
    <Box>
      <Profile data={row} />

      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <ReportTable isNursery={renderAsNursery} />
          <Comments />

          <Box sx={style}>
            <SubmitButton
              style={{ width: "50%" }}
              disabled={formik.isSubmitting}
              type="submit"
              variant="contained"
            >
              {formik.isSubmitting ? "Please wait..." : " Save"}
            </SubmitButton>
          </Box>
        </form>
      </FormikProvider>
    </Box>
  );
};

export default EditReportDetails;
