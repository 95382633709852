import * as Yup from "yup";

export const validationSchema = Yup.object().shape({
  // Personal Info Validation
  firstname: Yup.string().required("First name is required!"),
  lastname: Yup.string().required("Last name is required!"),
  othername: Yup.string().required("Other name is required!"),
  gender: Yup.string().required("Gender is required"),
  dateOfBirth: Yup.string().required("Date of birth is required!"),
  admissionNumber: Yup.string()
    .required("Admission number is required")
    .min(3, "Too Short!"),
  class: Yup.string().required("Class is required"),
  stateOfOrigin: Yup.string().required("Select your state from the drop down"),
  lgaOfOrigin: Yup.string().required("LGA is required!"),
  accountPassword: Yup.string()
    .required("Password is required")
    .min(6, "Password must not be less than 6 characters!"),
  email: Yup.string().email("Enter a valid email address"),
  phoneNo: Yup.string(),
  address: Yup.string()
    .required("Address is required")
    .min(6, "Password must not be less than 6 characters!"),
  uploadProfile: Yup.mixed(),

  // Academic Info Validation
  lastSchoolAttended: Yup.string()
    .required("Last school attended is required")
    .min(5, "Too Short!"),
  highestClassAttained: Yup.string()
    .required("Highest class attained is required")
    .min(5, "Too Short!"),
  attendedSchoolState: Yup.number().required(
    "Select a state from the drop down"
  ),
  lgaOfSchoolAttended: Yup.string().required(
    "LGA of school attended is required!"
  ),
  schoolAddress: Yup.string()
    .required("School address is required")
    .min(5, "Too Short!"),

  // Medical Info Validation
  medicalCondition: Yup.string().required("Medical condition is required!"),

  // Guardian Info Validation
  fathersName: Yup.string()
    .required("Father's full name is required!")
    .min(5, "Too Short"),
  mothersName: Yup.string()
    .required("Mother's full name is required!")
    .min(5, "Too Short"),
  mothersAddress: Yup.string()
    .required("Address is required!")
    .min(5, "Too Short"),
  fathersAddress: Yup.string()
    .required("Address is required!")
    .min(5, "Too Short"),
  mothersReligion: Yup.string().required("Select the religion applies to you!"),
  fathersReligion: Yup.string().required("Select the religion applies to you!"),
  mothersOccupation: Yup.string()
    .required("Occupation is required!")
    .min(3, "Too Short"),
  fathersOccupation: Yup.string()
    .required("Occupation is required!")
    .min(3, "Too Short"),
  fathersPhone: Yup.number()
    .required("Father's phone number is required")
    .test(
      "len",
      "Phone number must cannot be more than 11 digits!",
      (val) => val?.toString().length === 10
    ),
  mothersPhone: Yup.number()
    .required("Mother's phone number is required")
    .test(
      "len",
      "Phone number cannot be more than 11 digits!",
      (val) => val?.toString().length === 10
    ),
  parentEmail: Yup.string()
    .email("Provide a valid email address")
    .required("Email address is required"),
  parentAccountPassword: Yup.string()
    .min(7, "Password should be more than 6 characters")
    .required("Password is required"),
});



export const updateValidationSchema = Yup.object().shape({
  // Personal Info Validation
  firstname: Yup.string().required("First name is required!"),
  lastname: Yup.string().required("Last name is required!"),
  othername: Yup.string().required("Other name is required!"),
  gender: Yup.string().required("Gender is required"),
  dateOfBirth: Yup.string().required("Date of birth is required!"),
  admissionNumber: Yup.string()
    .required("Admission number is required")
    .min(3, "Too Short!"),
  class: Yup.string().required("Class is required"),
  stateOfOrigin: Yup.string().required("Select your state from the drop down"),
  lgaOfOrigin: Yup.string().required("LGA is required!"),
  email: Yup.string().email("Enter a valid email address"),
  phoneNo: Yup.string(),
  address: Yup.string()
    .required("Address is required")
    .min(6, "Password must not be less than 6 characters!"),
  uploadProfile: Yup.mixed(),

  // Academic Info Validation
  lastSchoolAttended: Yup.string()
    .required("Last school attended is required")
    .min(5, "Too Short!"),
  highestClassAttained: Yup.string()
    .required("Highest class attained is required")
    .min(5, "Too Short!"),
  attendedSchoolState: Yup.number().required(
    "Select a state from the drop down"
  ),
  lgaOfSchoolAttended: Yup.string().required(
    "LGA of school attended is required!"
  ),
  schoolAddress: Yup.string()
    .required("School address is required")
    .min(5, "Too Short!"),

  // Medical Info Validation
  medicalCondition: Yup.string().required("Medical condition is required!"),

  // Guardian Info Validation
  fathersName: Yup.string()
    .required("Father's full name is required!")
    .min(5, "Too Short"),
  mothersName: Yup.string()
    .required("Mother's full name is required!")
    .min(5, "Too Short"),
  mothersAddress: Yup.string()
    .required("Address is required!")
    .min(5, "Too Short"),
  fathersAddress: Yup.string()
    .required("Address is required!")
    .min(5, "Too Short"),
  mothersReligion: Yup.string().required("Select the religion applies to you!"),
  fathersReligion: Yup.string().required("Select the religion applies to you!"),
  mothersOccupation: Yup.string()
    .required("Occupation is required!")
    .min(3, "Too Short"),
  fathersOccupation: Yup.string()
    .required("Occupation is required!")
    .min(3, "Too Short"),
  fathersPhone: Yup.number()
    .required("Father's phone number is required")
    .test(
      "len",
      "Phone number must cannot be more than 11 digits!",
      (val) => val?.toString().length === 10
    ),
  mothersPhone: Yup.number()
    .required("Mother's phone number is required")
    .test(
      "len",
      "Phone number cannot be more than 11 digits!",
      (val) => val?.toString().length === 10
    ),
  parentEmail: Yup.string()
    .email("Provide a valid email address")
    .required("Email address is required"),
});