import PageLayout from "components/PageLayout";
import { classResultUrl } from "config/urls";
import useQueryParameters from "hooks/browser/useQueryParameters";
import { useClassResultStudents } from "hooks/results";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import ListTable from "views/report/list/Table";
import PageListItem from "views/report/subject/list/SubjectDetails";

type IViewClassResultParams = {
  session: string;
  term: string;
  class: string;
};

const ViewClassResult = () => {
  const params = useQueryParameters<IViewClassResultParams>();
  const history = useHistory();

  const { data, isLoading, isFetching, isError, refetch } =
    useClassResultStudents(params.session, params.term, params.class);

  useEffect(() => {
    if (
      [params.session, params.term, params.class].some((item) => !item?.trim())
    ) {
      history.replace(classResultUrl());
    }
  }, [params]);

  const showError = isError || data?.code === 0;

  return (
    <PageLayout
      loading={isLoading || isFetching}
      error={showError}
      onRetry={refetch}
      errorMessage={data?.message}
    >
      <PageListItem
        values={[
          `Class: ${data?.class?.class || "-"} (${data?.class?.section || ""})`,
          `Session: ${data?.session?.session || "-"}`,
          `Term: ${data?.term?.term || ""}`,
        ]}
      />
      <ListTable
        students={data?.data || []}
        hideEditButton
        columnConfig={{
          hidePhoneColumn: true,
        }}
      />
    </PageLayout>
  );
};

export default ViewClassResult;
