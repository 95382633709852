import Table, { RowData } from "components/Table";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";
import { ICumulativeResultItem } from "types/results";

interface IResultGroupTableProps {
  resultGroup: ICumulativeResultItem[];
}

const ResultTable = (props: IResultGroupTableProps) => {
  const { resultGroup } = props;

  return (
    <Table
      disableSideSheet
      title={{ label: "Cumulative Result Groups" }}
      rows={resultGroup}
      columns={[
        {
          id: "classes",
          label: "Class",
          renderer: (item: RowData) => {
            const value = item as ICumulativeResultItem;
            return <>{value?.classinfo.class}</>;
          },
        },
        {
          id: "section",
          label: "Section",
          renderer: (item: RowData) => {
            const value = item as ICumulativeResultItem;
            return <>{value.classinfo?.section}</>;
          },
        },
        {
          id: "status",
          label: "Status",
          renderer: (item: RowData) => {
            const value = item as ICumulativeResultItem;
            return <>{value?.status === 1 ? "Active" : "Pending"}</>;
          },
        },

        {
          id: "action",
          label: "",
          renderer: (item: RowData) => {
            const value = item as ICumulativeResultItem;

            return (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around",
                  gap: { xs: "12px", md: "0" },
                }}
              >
                <Button
                  sx={{
                    padding: "4px 12px",
                    minWidth: "30px",
                    whiteSpace: "nowrap",
                  }}
                  variant="outlined"
                >
                  Generate Result
                </Button>
                <Button
                  sx={{
                    padding: "4px 12px",
                    minWidth: "30px",
                    whiteSpace: "nowrap",
                  }}
                  variant="outlined"
                  disabled={value.status === 0}
                >
                  View Results
                </Button>
              </Box>
            );
          },
        },
      ]}
    />
  );
};

export default ResultTable;
