import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Collapse from "@mui/material/Collapse";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { alpha, styled } from "@mui/material";
import { NavLink } from "react-router-dom";
import { DropdownItem } from "types/sidebarDropdown";

interface ISidebarSubmenuProps {
  data: DropdownItem[];
  isExpanded?: boolean;
  onItemClicked?: Function;
  queryParam?: string | number;
}

const Submenu = ({
  data,
  isExpanded,
  queryParam,
  onItemClicked,
}: ISidebarSubmenuProps) => {
  return (
    <Collapse in={isExpanded} timeout="auto" unmountOnExit>
      {data.map((d) => {
        const { primary, url, hasParam, key } = d;
        return (
          <List component="div" disablePadding key={key}>
            <SidebarLink
              to={hasParam ? url(queryParam) : url()}
              activeClassName="activeLink"
              onClick={(e) => {
                e.stopPropagation();
                onItemClicked && onItemClicked();
              }}
            >
              <ListItemWrapper>
                <ListItemIcon></ListItemIcon>
                <ListItemText primary={primary} />
              </ListItemWrapper>
            </SidebarLink>
          </List>
        );
      })}
    </Collapse>
  );
};

export default Submenu;

const ListItemWrapper = styled(ListItem)(({ theme }) => ({
  "&:hover": {
    backgroundColor: alpha(theme.palette.primary.light, 0.25),
  },
}));

const SidebarLink = styled(NavLink)`
  & {
    text-decoration: none !important;
    color: #fff;
    display: block;
  }
`;
