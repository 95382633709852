import React from 'react'
import PageLayout from "components/PageLayout";
import { Box, Card } from "@material-ui/core";
import { useListReportGroup } from 'hooks/reports';
import ReportGroupTable from './Table';

const ReportGroup = () => {
  
  const { isLoading, isFetching, isError, refetch, data}   = useListReportGroup();

  return (
    
      <PageLayout
        loading={isLoading || isFetching}
        error={isError}
        onRetry={refetch}
      >
          <ReportGroupTable reportGroup={data?.data || []}/>
       
      </PageLayout>
   
  )
}

export default ReportGroup