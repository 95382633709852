import Table, { RowData } from "components/Table";
import { viewAddStudentUrl } from "config/urls";
import { Link, useHistory } from "react-router-dom";
import { IStudentList } from "types/students";
import ViewStudent from "../updateStudent";
import { getParentName } from "../updateStudent/helper";

interface IStudentsTableProps {
  students: IStudentList[];
}

const StudentsTable = (props: IStudentsTableProps) => {
  const { students } = props;

  const history = useHistory();

  return (
    <Table
      sideSheetWidth="50%"
      title={{ label: "Student List" }}
      columnPerBreakpoint={{
        sm: ["admno", "firstname", "lastname", "classes"],
      }}
      ctaLabel="Add Student"
      onCTAClicked={() => {
        history.push(viewAddStudentUrl());
      }}
      rows={students}
      render={(row) => {
        return <ViewStudent row={row as IStudentList} />;
      }}
      sx={{
        ".MuiTableCell-root": {
          padding: "16px 10px !important",
          fontSize: "13px !important",
        },
      }}
      columns={[
        {
          id: "admno",
          label: "ADM No",
          renderer: (item: RowData) => {
            const value = item as IStudentList;
            return (
              <Link
                style={{ textDecoration: "underline", color: "#0aa89e" }}
                to="#"
              >
                {value?.admno}
              </Link>
            );
          },
        },
        {
          id: "firstname",
          label: "First Name",
        },
        {
          id: "lastname",
          label: "Last Name",
        },
        {
          id: "othername",
          label: "Other Name",
        },
        {
          id: "classes",
          label: "Class",
          renderer: (item: RowData) => {
            const value = item as IStudentList;
            return <>{value["classes"]?.class}</>;
          },
        },
        {
          id: "fathersname",
          label: "Father's Name",
          renderer: (item: RowData) => {
            const value = item as IStudentList;
            return getParentName(value).fathersName || "";
          },
        },
        {
          id: "mothersname",
          label: "Mother's Name",
          renderer: (item: RowData) => {
            const value = item as IStudentList;
            return getParentName(value).mothersName || "";
          },
        },
        {
          id: "phone",
          label: "PHONE",
          renderer: (item: RowData) => {
            const value = item as IStudentList;
            return (
              <>{value?.phone ? value?.phone : value["guardian"]?.phone}</>
            );
          },
        },
      ]}
    />
  );
};

export default StudentsTable;
