import { TextFieldContainer, SubmitButton } from "views/auth/Common";
import TextField from "components/Form/TextField";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Box, Dialog, DialogContent } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import { IAddSessionValues } from "types/sessions";
import DialogTitle from "components/DialogTitle";
export interface SimpleDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}

const validationSchema = Yup.object().shape({
  session: Yup.string().required("Session name is required"),

  status: Yup.string().required("Please select a status from the dropdown"),
});

export interface ISessionFormProps {
  onSubmit: (values: IAddSessionValues) => Promise<any> | any;
  open: boolean;
  isEdit?: boolean;
  data?: IAddSessionValues | undefined;
  handleClose: Function;
}

const AddSessionForm = ({
  onSubmit,
  open,
  handleClose,
  isEdit,
  data,
}: ISessionFormProps) => {
  const initialValues: IAddSessionValues = isEdit
    ? {
        session: data?.session,
        id: data?.id,
        status: data?.status,
      }
    : {};

  return (
    <Dialog
      sx={{ ".MuiTypography-root": { padding: "16px 0 0px 16px" } }}
      fullWidth={true}
      open={open}
      onClose={() => handleClose()}
    >
      <DialogTitle
        title={`${isEdit ? "Update" : "Add"} Session`}
        onClose={() => handleClose()}
      />
      <DialogContent dividers style={{ paddingTop: 0 }}>
        <Formik
          initialValues={initialValues}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            await onSubmit(values);
            setSubmitting(false);
          }}
          validationSchema={validationSchema}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
          }) => (
            <Form>
              <TextFieldContainer
                direction="column"
                style={{ gap: "24px" }}
              >
                <TextField
                  type="text"
                  name="session"
                  label="Session Name"
                  variant="outlined"
                  value={values.session || ""}
                  error={!!(errors.session && touched.session)}
                  helperText={errors.session}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />

                <Box margin="0 5px">
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="status">Select Status</InputLabel>
                    <Select
                      type="text"
                      name="status"
                      error={!!(errors.status && touched.status)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      defaultValue={isEdit ? values.status : ""}
                      labelId="status"
                      id="demo-simple-select"
                      label="Select Status"
                    >
                      {["Active", "Inactive"]?.map((stat) => {
                        return <MenuItem value={stat}>{stat}</MenuItem>;
                      })}
                    </Select>
                    <FormHelperText>{errors.status}</FormHelperText>
                  </FormControl>
                </Box>
              </TextFieldContainer>

              <Box
                flexDirection="row"
                textAlign="center"
                style={{ margin: "16px 0px" }}
              >
                <SubmitButton
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                >
                  {isSubmitting
                    ? "Please wait..."
                    : `${isEdit ? "Update" : "Add"} Session`}
                </SubmitButton>
              </Box>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};
export default AddSessionForm;
