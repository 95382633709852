import React from "react";
import Card from "components/Card";
import PageLayout from "components/PageLayout";
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useFormik } from "formik";
import styles from "../../../profile/profile.module.css";
import * as Yup from "yup";
import { SubmitButton } from "views/auth/Common";
import { useHistory } from "react-router-dom";
import { useListSessions } from "hooks/session";
import { Session } from "types/sessions";

const validationSchema = Yup.object().shape({
  session: Yup.string().required("Required! Please Select a Session"),
});

const CumulativeResult = () => {
  const {
    data: activeSession,
    isLoading,
    isError,
    isFetching,
    refetch,
  } = useListSessions();

  const initialValues: Partial<Session> = {};

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    isSubmitting,
    handleSubmit,
    isValid,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values: Partial<Session>, { setSubmitting }) => {
      setSubmitting(true);
      await handleSubmission(values);
      setSubmitting(false);
    },
  });

  const history = useHistory();

  const handleSubmission = async (values: Partial<Session>) => {
    history.push(`cumulative_result_group?session=${values.session}`);
  };

  return (
    <Box sx={{ margin: { xs: "0", lg: "0 120px" } }}>
      <PageLayout
        loading={isLoading || isFetching}
        error={isError}
        onRetry={refetch}
      >
        <Card title="CUMULATIVE RESULT">
          <form onSubmit={handleSubmit}>
            <Box className={styles.textFieldContainer}>
              <Box margin="0 5px" flex={1}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="session">Select Session</InputLabel>
                  <Select
                    type="text"
                    name="session"
                    value={values.session || ""}
                    error={!!(errors.session && touched.session)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Select Session"
                  >
                    {activeSession?.map((session) => {
                      return (
                        <MenuItem key={session.id} value={session.id}>
                          {session.session}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText>{errors.session}</FormHelperText>
                </FormControl>
              </Box>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <SubmitButton
                disabled={isSubmitting || !isValid}
                type="submit"
                variant="contained"
                style={{ marginTop: 28 }}
              >
                {isSubmitting ? "Please wait..." : "NEXT"}
              </SubmitButton>
            </Box>
          </form>
        </Card>
      </PageLayout>
    </Box>
  );
};

export default CumulativeResult;
