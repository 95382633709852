import React from "react";
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  Autocomplete,
} from "@mui/material";
import styles from "../../profile/profile.module.css";
import { IClassReportList} from "types/report";
import { useCommentReport } from "hooks/reports";
import { useFormikContext } from "formik";

const style = {
  width: "100%",
  ".MuiFilledInput-root": { background: "rgb(4 243 93 / 6%)" },
  ".MuiFilledInput-root:hover": { background: "rgb(88 223 12 / 6%)" },
  marginTop: "16px",
};

const Comments = () => {
  const { data: autoCompleteData } = useCommentReport();
  const autoCompleteComment = autoCompleteData?.data || [];

  const { values, handleChange, setFieldValue } =
    useFormikContext<IClassReportList>();

  const comments = Object.keys(values.comments || {}).map((item) => ({
    key: item,
    value: values.comments![item],
  }));

  return (
    <Box
      sx={{
        margin: { xs: "0 0 48px 0", md: "48px 0" },
        width: "100%",
        padding: { xs: "0 16px", md: "20px 32px" },
      }}
    >
      <h2
        style={{
          fontSize: "16px",
          fontWeight: "600",
          color: "var(--primary)",
          textAlign: "center",
        }}
      >
        ATTENDANCE, PSYCHO-MOTOR RATING, FORM TEACHER, AND HEAD TEACHER REPORT{" "}
      </h2>

      <Box>
        <Box>
          <Box className={styles.textFieldContainer}>
            <TextField
              type="text"
              name="attendance_count"
              value={values.attendance_count || ""}
              label="DAYS PRESENT"
              variant="filled"
              onChange={handleChange}
              sx={style}
            />
          </Box>
          <Box className={styles.textFieldContainer}>
            <Autocomplete
              disablePortal
              freeSolo
              fullWidth
              options={autoCompleteComment.map((item) => item.comment)}
              onChange={(event, newValue) =>
                setFieldValue("formmasterreport", newValue)
              }
              id="formmasterreport"
              value={values.formmasterreport || ""}
              defaultValue={values.formmasterreport}
              renderInput={(params) => (
                <TextField
                  type="text"
                  name="formmasterreport"
                  variant="filled"
                  label="FORM MASTER REPORT"
                  sx={style}
                  {...params}
                />
              )}
            />
          </Box>
          <Box className={styles.textFieldContainer}>
            <Autocomplete
              disablePortal
              freeSolo
              fullWidth
              options={autoCompleteComment.map((item) => item.comment)}
              onChange={(event, newValue) =>
                setFieldValue("headmasterreport", newValue)
              }
              id="headmasterreport"
              value={values.headmasterreport || ""}
              defaultValue={values.headmasterreport}
              renderInput={(params) => (
                <TextField
                  type="text"
                  name="headmasterreport"
                  variant="filled"
                  label="HEAD MASTER REPORT"
                  sx={style}
                  {...params}
                />
              )}
            />
          </Box>
        </Box>

        <Grid container direction="row" spacing={2}>
          {comments.map((comment) => (
            <Grid item xs={6} md={6}>
              <FormControl variant="filled" sx={style}>
                <InputLabel id={`${comment?.key}`}>
                  {comment?.key?.toUpperCase()}
                </InputLabel>
                <Select
                  type="text"
                  name={`comments.${comment.key}`}
                  value={comment.value || ""}
                  onChange={handleChange}
                  label={comment?.key}
                  MenuProps={{ disablePortal: true }}
                >
                  {[
                    "EXCELLENT",
                    "VERY GOOD",
                    "GOOD",
                    "FAIR",
                    "POOR",
                    "NOT OBSERVABLE",
                  ].map((item) => {
                    return (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default Comments;
