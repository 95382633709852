import PageLayout from "components/PageLayout";
import ListPinForm from "./FilterPinForm";
import { Box } from "@mui/material";
import PinsTable from "./PinsTable";
import { useEffect, useState } from "react";
import { usePins } from "hooks/results";
import DataLoader from "components/DataLoader";
import { useListSessions } from "hooks/session";
import { useListTerms } from "hooks/term";
import { IFilterPinValues } from "types/results";
import DataLoaderError from "components/DataLoaderError";

const ViewResultPins = () => {
  const {
    data: sessions,
    isLoading: isLoadingSessions,
    isError,
  } = useListSessions();
  const { data: terms, isLoading: isLoadingTerms, isFetching } = useListTerms();

  const [filter, setFilter] = useState<IFilterPinValues | null>(null);

  const handleSubmission = (values: IFilterPinValues) => {
    setFilter({
      session: values.session,
      term: values.term,
    });
  };

  const {
    isLoading: isLoadingPins,
    isFetching: isFetchingPins,
    isError: errorLoadingPin,
    data: listPins,
    refetch: fetchPins,
  } = usePins(filter?.session, filter?.term);

  useEffect(() => {
    if (filter) {
      fetchPins();
    }
  }, [filter, fetchPins]);

  const getMainContent = () => {
    if (filter && (isFetchingPins || isLoadingPins)) {
      return <DataLoader message="Loading Pins..." />;
    } else if (errorLoadingPin) {
      return <DataLoaderError />;
    } else if (listPins?.data) {
      return <PinsTable pins={listPins.data || []} />;
    }
  };

  return (
    <PageLayout loading={isLoadingSessions || isLoadingTerms} error={isError}>
      <Box>
        <ListPinForm
          onSubmit={handleSubmission}
          sessions={sessions || []}
          terms={terms || []}
        />
      </Box>

      <Box marginTop="48px">{getMainContent()}</Box>
    </PageLayout>
  );
};

export default ViewResultPins;
