import React from "react";
import { Box } from "@mui/material";
import SignupForm from "./SignupForm";
import { FormContainer, FormContent, Title, Layout } from "../Common";
import Header from "components/NavMenu/Header";
import { useHistory } from "react-router-dom";
import { useSignUpMutation } from "../../../hooks/auth/index";
import { AxiosError } from "axios";
import { GenericAPIResponse } from "../../../types/generic";
import { IAuthData } from "../../../types/auth";
import { toast } from "react-toastify";
import { setToken } from "../../../api/cookie";

const SignUp = () => {
  const history = useHistory();

  const { mutateAsync: submitSignUpRequest } = useSignUpMutation({
    onError: (error: GenericAPIResponse<IAuthData>) => handleAuthError(error), //Error handle tech debt to keep API the way it is.
    onSuccess: (data: GenericAPIResponse<IAuthData>) => handleAuthSuccess(data),
  });

  const handleAuthError = (error: GenericAPIResponse<IAuthData>) => {
    const data = error || {
      message: "We couldn't process your request! Try again",
    };
    toast(data.message, { type: "error" });
  };

  const handleAuthSuccess = (data: GenericAPIResponse<IAuthData>) => {
    //set cookie, then proceed to dashboard.
    if (data.code === 0) {
      handleAuthError(data);
    } else {
      setToken(data.token);
      history.push("/dashboard/account/profile");
    }
  };

  return (
    <>
      <Header />
      <Layout>
        <FormContainer>
          <FormContent style={{ marginTop: 32 }}>
            <Title>Create an account</Title>
            <Box mt={2}>
              <SignupForm onSubmit={submitSignUpRequest} />
            </Box>
          </FormContent>
        </FormContainer>
      </Layout>
    </>
  );
};

export default SignUp;
