import React from "react";
import { Close } from "@mui/icons-material";
import { IconButton, Typography } from "@mui/material";
import styled from "styled-components";

interface IModalContainerProps {
  title: string;
  handleClose?: Function;
  children: React.ReactNode;
  style?: React.CSSProperties,
}

const ModalContainer = ({
  children,
  title,
  handleClose,
  style,
}: IModalContainerProps) => {
  return (
    <ModalContent style={style}>
      <ModalHeader>
        <Typography variant="body2" fontWeight="bold" sx={{ flex: 1 }}>
          {title}
        </Typography>
        <IconButton onClick={()=>handleClose &&  handleClose()}>
          <Close />
        </IconButton>
      </ModalHeader>
      <ModalBody>{children}</ModalBody>
    </ModalContent>
  );
};

const ModalHeader = styled.div`
  padding: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  align-items: center;
  box-shadow: 0 2px 4px rgb(0 0 0 / 12%);
  display: flex;
  flex-direction: row;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  max-width:100%;
  background-color: #fff;
  border: none;
  border-radius: 16px;
`;

const ModalBody = styled.div`
  max-height: 600px;
  overflow-y: auto;
  padding: 16px;
`;

export default ModalContainer;
