import React from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { ListItemButton, ListItemText } from "@mui/material";
import SidebarSubmenu from "./SidebarSubmenu";
import ListItem from "@mui/material/ListItem";
import { useEffect, useRef, useState } from "react";
import { DropdownItem } from "types/sidebarDropdown";
import { SidebarLink } from "./Sidebar";
import { StyledListItemIcon } from "./Sidebar";

interface IListItemProps {
  onItemClicked?: Function;
  data: DropdownItem[];
  name: string;
  children: React.ReactNode;
}
const SidebarListItem = ({
  onItemClicked,
  data,
  name,
  children,
}: IListItemProps) => {
  const [open, setOpen] = useState(false);
  const isInitialRender = useRef(true);

  useEffect(() => {
    const state = localStorage.getItem(`${name}-key`);
    if (open && !state) {
      localStorage.setItem(`${name}-key`, JSON.stringify(open));
    }

    if (!isInitialRender.current) {
      if (!open && state) {
        localStorage.removeItem(`${name}-key`);
      }
    }

    isInitialRender.current = false;
  }, [open]);

  useEffect(() => {
    const state = localStorage.getItem(`${name}-key`);
    if (state) {
      let data = JSON.parse(state);
      setOpen(data);
    }
  }, []);

  return (
    <SidebarLink onClick={() => setOpen(!open)} to="#">
      <ListItemButton key={name}>
        <StyledListItemIcon>{children}</StyledListItemIcon>
        <ListItemText primary={name} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <SidebarSubmenu
        data={data}
        isExpanded={open}
        onItemClicked={onItemClicked}
      />
    </SidebarLink>
  );
};

export default SidebarListItem;
