import { get } from "api";
import { decodeToken } from "api/cookie";
import { QueryOptions, useQuery } from "react-query";
import { IClass } from "types/common";
import { GenericAPIResponse } from "types/generic";

/** Fetches all the created classes for a achool */
export const useClasses = (options?: QueryOptions<GenericAPIResponse<IClass[]>>) => {
  return useQuery<GenericAPIResponse<IClass[]>>(
    ["students", "classes"],
    () => get(`students/classes`),
    { ...(options || {}) }
  );
};

export const useSortedClasses = () => {
  const { data: classes } = useClasses();
  return classes?.data?.sort((a, b )=> ((a.class + a.section) > (b.class + b.section)) ? 1 : -1)
}
