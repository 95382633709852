import { Button } from '@mui/material'
import styled from 'styled-components'

export const SubscribeButton = styled(Button)`
  && {
    border-radius: 50px;
    margin-top: 48px;
    padding: 18px 18px 18px 18px;
    font-weight: bold;
    min-width: 250px;
    margin-bottom: 60px;
  }
`
export const SubscribeButtonMobile = styled(Button)`
  && {
    border-radius: 50px;
    margin-top: 48px;
    padding: 18px 18px 18px 18px;
    font-weight: bold;
    min-width: 250px;
  }
`

export const Title = styled.h1`
  font-size: 1.75rem;
  font-weight: 700;
  padding: 30px 0px 0px 30px;
`

export const Layout = styled.div`
  display: flex;
  min-height: 100vh;
  background-color: hsl(210deg 40% 96%);

  @media screen and (max-width: 992px) {
    flex-direction: column;
  }
`

export const Content = styled.div`
  max-width: 500px;
  justify-content: center;
  margin: auto;
  display: flex;
  flex-direction: column;
  position: relative;

  @media screen and (max-width: 992px) {
    justify-content: unset;
    top: unset;
    flex-direction: column;
  }
`

export const SubcriptionContainer = styled.div`
  width: 100%;
  margin-top: 64px;
  display: flex;
  flex-direction: column;
`

export const CardsContainer = styled.div`
  display: none;
  @media screen and (min-width: 1198px) {
    display: flex;
    flex: 0 0 50%;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
`
