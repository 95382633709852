import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useFormik } from "formik";
import { ClassReportFormValues, IGetClassReportFormValue } from "types/report";
import * as Yup from "yup";
import styles from "../../profile/profile.module.css";
import { SubmitButton } from "components/Common";

const validationSchema = Yup.object().shape({
  session: Yup.string().required("Required! Please Select a Session"),
  term: Yup.string().required("Required! Please Select a Term"),
  class: Yup.string().required("Required! Please Select a Class"),
});

interface IClassReportFormProps {
  onSubmit?: (values: Partial<ClassReportFormValues>) => Promise<any>;
  classReport?: IGetClassReportFormValue;
}

const ReportForm = ({ onSubmit, classReport }: IClassReportFormProps) => {
  const [session, terms, classes] = [
    classReport?.data.session,
    classReport?.data.term,
    classReport?.data.class,
  ];

  const initialValues: Partial<ClassReportFormValues> = {};

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    isSubmitting,
    handleSubmit,
    isValid,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (
      values: Partial<ClassReportFormValues>,
      { setSubmitting }
    ) => {
      setSubmitting(true);
      await onSubmit?.(values);
      setSubmitting(false);
    },
  });

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box className={styles.textFieldContainer}>
          <Box margin="0 5px" flex={1}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="session">Select Session</InputLabel>
              <Select
                type="text"
                name="session"
                value={values.session || ""}
                error={!!(errors.session && touched.session)}
                onChange={handleChange}
                onBlur={handleBlur}
                label="Select Session"
              >
                {session?.map((session) => {
                  return (
                    <MenuItem key={session.id} value={session.id}>
                      {session.session}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>{errors.session}</FormHelperText>
            </FormControl>
          </Box>
        </Box>

        <Box className={styles.textFieldContainer} flexDirection="column">
          <Box margin="0 5px" flex={1}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="state">Select Term</InputLabel>
              <Select
                type="text"
                name="term"
                value={values.term || ""}
                error={!!(errors.term && touched.term)}
                onChange={handleChange}
                onBlur={handleBlur}
                label="Select Term"
              >
                {terms?.map((term) => {
                  return (
                    <MenuItem key={term.id} value={term.id}>
                      {term.term}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>{errors.term}</FormHelperText>
            </FormControl>
          </Box>

          <Box margin={{ xs: "0 5px", md: "16px 5px" }} flex={1}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="state">Select Class</InputLabel>
              <Select
                type="text"
                name="class"
                value={values.class || ""}
                error={!!(errors.class && touched.class)}
                onChange={handleChange}
                onBlur={handleBlur}
                label="Select Class"
              >
                {classes?.map((classes) => {
                  return (
                    <MenuItem key={classes.id} value={classes.id}>
                     {`${classes.class} (${classes.section})`}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>{errors.class}</FormHelperText>
            </FormControl>
          </Box>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <SubmitButton
            disabled={isSubmitting || !isValid}
            type="submit"
            variant="contained"
            style={{ marginTop: 32 }}
          >
            {isSubmitting ? "Please wait..." : "NEXT"}
          </SubmitButton>
        </Box>
      </form>
    </Box>
  );
};

export default ReportForm;
