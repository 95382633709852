import React, { useMemo } from "react";

import {
  Paper,
  Table as MUITable,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  SxProps,
  Theme,
} from "@mui/material";
import TableFilter, { ITableFilterProps } from "./filter";
import SideSheet from "./SideSheet";
import Box from "@mui/material/Box";
import Slide from "@mui/material/Slide";
import styles from "./styles.module.css";
import { useScreenSize } from "hooks/browser/useScreenSize";

type Alignment = "inherit" | "right" | "left" | "center" | "justify";
export type RowData = { [key: string]: string | number | any };
type IScreenSize = { sm?: Array<String> | any; md?: Array<String> | any };
export type ITableColumn = Array<{
  align?: Alignment;
  id: string;
  minWidth?: number;
  label: string;
  disabled?: boolean;
  renderer?: (item: RowData, index: number) => React.ReactNode;
}>;

type ITableProps = {
  rows: Array<RowData> | any[];
  columnPerBreakpoint?: IScreenSize;
  columns: ITableColumn;
  initalRowsPerPage?: number;
  preHeaderNode?: React.ReactNode;
  style?: React.CSSProperties;
  sx?: SxProps<Theme> | undefined;
  disableFilter?: boolean;
  disableFooter?: boolean;
  disableSideSheet?: boolean;
  sideSheetElement?: React.ReactNode;
  onClose?: boolean;
  ref?: any;
  sideSheetWidth?: string | number;
  render?: (row: RowData) => React.ReactNode;
} & Omit<ITableFilterProps, "onChanged">;

const Table = ({
  rows,
  columns,
  columnPerBreakpoint,
  initalRowsPerPage,
  preHeaderNode,
  style,
  sx,
  disableFilter = false,
  disableFooter = false,
  disableSideSheet = false,
  title,
  sideSheetWidth,
  onCTAClicked,
  ctaLabel,
  ref,
  render,
}: ITableProps) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(initalRowsPerPage || 10);
  const [filter, setFilter] = React.useState("");
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [openSideSheet, setOpenSideSheet] = React.useState(false);

  const containerRef = React.useRef(null);
  const tableBodyRef = React.useRef<HTMLTableSectionElement | null>(null);

  const { isSm, isMd } = useScreenSize();

  const handleRowClickEvent = (row) => {
    setSelectedRow(row);
  };

  const items = React.useMemo(() => {
    if (filter) {
      const filteredRows = rows.filter((row) => {
        const stringRepresentation = JSON.stringify(Object.values(row));
        return stringRepresentation
          .toLowerCase()
          .includes(filter.toLowerCase());
      });
      return filteredRows;
    }
    return rows;
  }, [filter, rows]);

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const enabledColumns = useMemo(() => {
    let col = [...columns];
    const smColumns = columnPerBreakpoint?.sm;
    const mdColumns = columnPerBreakpoint?.md;

    if (isSm && smColumns?.length > 0) {
      return col.filter((i) => smColumns?.includes(i.id));
    } else if (isMd && mdColumns?.length > 0) {
      return col.filter((i) => mdColumns?.includes(i.id));
    } else {
      return col.filter((c) => !c.disabled);
    }
  }, [columnPerBreakpoint, columns, isMd, isSm]);

  return (
    <Box>
      <Box>
        {!disableFilter && (
          <TableFilter
            onChanged={setFilter}
            title={title}
            ctaLabel={ctaLabel}
            onCTAClicked={onCTAClicked}
          />
        )}
        <TableContainer component={Paper} style={style} sx={sx}>
          {preHeaderNode}
          <MUITable aria-label="sticky table">
            <TableHead>
              <TableRow>
                {enabledColumns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column?.align || "left"}
                    style={{
                      top: 57,
                      minWidth: column.minWidth || "auto",
                      fontWeight: 500,
                    }}
                  >
                    {column.label.toUpperCase()}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody ref={tableBodyRef}>
              {items
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: RowData, index: number) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id || index}
                    >
                      {enabledColumns.map((column) => {
                        const value = row[column.id];
                        const renderer = column.renderer;
                        return (
                          <TableCell
                            key={column.id}
                            align={column?.align || "left"}
                            onClick={() => {
                              if (!disableSideSheet) {
                                setOpenSideSheet(true);
                                document.body.style.overflowY = "hidden";
                              }
                              handleRowClickEvent(row);
                            }}
                          >
                            {renderer ? renderer(row, index) : value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              {!items?.length && (
                <tr>
                  <td colSpan={columns.length}>
                    <p style={{ textAlign: "center" }}>No records found</p>
                  </td>
                </tr>
              )}
            </TableBody>
          </MUITable>

          {!disableFooter && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={items.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableRow>
            </TableFooter>
          )}
        </TableContainer>
      </Box>
      {!disableSideSheet && (
        <Slide
          direction="left"
          in={openSideSheet}
          container={containerRef.current}
        >
          <Box
            className={styles.sideSheet}
            minWidth={{ xs: "100%", md: sideSheetWidth || "436px" }}
          >
            {Boolean(selectedRow && render) && (
              <SideSheet
                open={openSideSheet}
                closeModal={() => {
                  setOpenSideSheet(false);
                  document.body.style.overflowY = "scroll";
                }}
                tableBodyRef={tableBodyRef}
              >
                {render!(selectedRow!)}
              </SideSheet>
            )}
          </Box>
        </Slide>
      )}
    </Box>
  );
};

export default Table;
