import { DialogTitle as MUIDialogTitle, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

interface IDialogTitleProps {
  title: string;
  onClose?: () => void;
}

const DialogTitle = ({ title, onClose }: IDialogTitleProps) => {
  return (
    <MUIDialogTitle
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      style={{padding: "12px 24px"}}
    >
      {title}
      <IconButton onClick={() => onClose?.()}>
        <Close sx={{ cursor: "pointer" }} />
      </IconButton>
    </MUIDialogTitle>
  );
};

export default DialogTitle;
