import { useMediaQuery } from "@material-ui/core";

export const useScreenSize = ()=>{

const matchesSm = useMediaQuery('(max-width: 600px)');
const matchesMd = useMediaQuery('(min-width:600px) and (max-width:900px)');
const matchesLg = useMediaQuery('(min-width:900px)');

    return {
isSm: matchesSm,
isMd: matchesMd,
isLg: matchesLg
    }
}