import { decodeToken } from "api/cookie";
import { QueryOptions, useMutation, useQuery } from "react-query";
import { deleteRequest, get, post, put } from "api";
import { MutationOptions } from "hooks/types";
import { GenericAPIResponse } from "types/generic";
import { ISubjectValues, Subject, SubjectFormVals, SubjectListResponse } from "types/subject";

export const useListSubjects = (options?: QueryOptions<SubjectListResponse>) => {
    const userid = decodeToken().userid;
    return useQuery<SubjectListResponse>(
        ["settings", "subject"],
        () => get(`/students/subjects`),
        { ...(options || {}) }
    );
};


export const useAddSubjectMutation = (
    options?: MutationOptions<GenericAPIResponse<any>>
) => {
    return useMutation(
        (value: SubjectFormVals) =>
            post(`/students/subject`, {
                subject: value.subject,
                class: value.class
            }),
        { ...(options || {}) }
    );
};


export const useEditSubjectMutation = (
    options?: MutationOptions<GenericAPIResponse<any>>
) => {

    return useMutation(
        (value: SubjectFormVals) =>

            put(`/students/subject`, {
                subject: value.subject,
                class: value?.class ,
                id: value.id,
            }),
        { ...(options || {}) } 
    
    );
};


export const useDeleteSubject = (
    options?: MutationOptions<GenericAPIResponse<any>>
) => {
    return useMutation(
        (subjectId: number) => deleteRequest(`/students/subject/${subjectId}`),
        { ...(options || {}) }
    );
};
