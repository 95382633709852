import { IGradeSystem } from "types/settings";
import { toast } from "react-toastify";

const validation = (values: IGradeSystem) => {
  // check for duplicated scores and grades.
  if (
    new Set(values.grades.map((item) => item.score)).size !==
    values.grades.length
  ) {
    toast.warning("Duplicate scores are not allowed");
    return false;
  }

  if (
    new Set(values.grades.map((item) => item.grade)).size !==
    values.grades.length
  ) {
    toast.warning("Duplicate grades are not allowed");
    return false;
  }

  //checks for invalid input in any cells
  for (let i = 0; i < values.grades.length; i++) {
    const previousInput = values.grades[i - 1]?.score;
    const currentInput = values.grades[i];

    if (
      isNaN(currentInput.score) ||
      currentInput.score < 0 ||
      currentInput.score > 100
    ) {
      toast.warning("Score should be between 0 and 100");
      return false;
    } else if (currentInput.score > previousInput) {
      toast.warning(
        "Invalid input! Score cannot be greater than previous input."
      );
      return false;
    } else if (currentInput.grade.length > 2) {
      toast.warning("Invalid input! Grade cannot be more than two characters.");
      return false;
    }
  }

  return true;
};

export default validation;
