import { useEffect} from "react";
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useFormik} from "formik";
import {
  IGetClassReportFormValue,
  SubjectReportFormValues,
} from "types/report";
import * as Yup from "yup";
import styles from "../../../profile/profile.module.css";
import { SubmitButton } from "components/Common";
import { useGetSubjectList } from "hooks/reports";
import DataLoader from "components/DataLoader";

const validationSchema = Yup.object().shape({
  session: Yup.string().required("Required! Please Select a Session"),
  term: Yup.string().required("Required! Please Select a Term"),
  class: Yup.string().required("Required! Please Select a Class"),
  subject: Yup.string().required("Required! Please Select a Subject"),
});

interface IClassReportFormProps {
  onSubmit?: (values: Partial<SubjectReportFormValues>) => Promise<any>;
  activeSession?: IGetClassReportFormValue;
}

const SubjectReportForm = ({
  onSubmit,
  activeSession,
}: IClassReportFormProps) => {

  const [sessions, terms, classes] = [
    activeSession?.data.session,
    activeSession?.data.term,
    activeSession?.data.class,
  ];


  const initialValues: Partial<SubjectReportFormValues> = {};

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    isSubmitting,
    handleSubmit,
    isValid,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (
      values: Partial<SubjectReportFormValues>,
      { setSubmitting }
    ) => {
      setSubmitting(true);
      await onSubmit?.(values);
      setSubmitting(false);
    },
  });

  const classId = values?.class;

  const {
    data: classSubject,
    isLoading,
    isError,
    isFetching,
    refetch,
  } = useGetSubjectList(classId);

  useEffect(() => {
    if (classId) {
      refetch();
    }
  }, [classId, refetch]);

  const subjects = classSubject?.data;

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Box className={styles.textFieldContainer}>
          <Box margin="0 5px" flex={1}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="session">Select Session</InputLabel>
              <Select
                type="text"
                name="session"
                value={values.session || ""}
                error={!!(errors.session && touched.session)}
                onChange={handleChange}
                onBlur={handleBlur}
                label="Select Session"
              >
                {sessions?.map((session) => {
                  return (
                    <MenuItem key={session.id} value={session.id}>
                      {session.session}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>{errors.session}</FormHelperText>
            </FormControl>
          </Box>
        </Box>

        <Box className={styles.textFieldContainer} flexDirection="column">
          <Box margin="0 5px" flex={1}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="term">Select Term</InputLabel>
              <Select
                type="text"
                name="term"
                value={values.term || ""}
                error={!!(errors.term && touched.term)}
                onChange={handleChange}
                onBlur={handleBlur}
                label="Select Term"
              >
                {terms?.map((term) => {
                  return (
                    <MenuItem key={term.id} value={term.id}>
                      {term.term}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>{errors.term}</FormHelperText>
            </FormControl>
          </Box>
        </Box>

        <Box className={styles.textFieldContainer}>
          <Box margin="0 5px" flex={1}>
            <FormControl sx={{ width: "100%" }}>
              <InputLabel id="class">Select Class</InputLabel>
              <Select
                type="text"
                name="class"
                value={values.class || ""}
                error={!!(errors.class && touched.class)}
                onChange={handleChange}
                onBlur={handleBlur}
                label="Select Class"
              >
                {classes?.map((classes) => {
                  return (
                    <MenuItem key={classes.id} value={classes.id}>
                    {`${classes.class} (${classes.section})`}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText>{errors.class}</FormHelperText>
            </FormControl>
          </Box>
        </Box>

        {classId && isFetching ? (
          <DataLoader message="Loading Subjects" />
        ) : (
          classId && (
            <Box className={styles.textFieldContainer}>
              <Box margin="0 5px" flex={1}>
                <FormControl sx={{ width: "100%" }}>
                  <InputLabel id="subject">Select Subject</InputLabel>
                  <Select
                    type="text"
                    name="subject"
                    value={values.subject || ""}
                    error={!!(errors.subject && touched.subject)}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="Select Subject"
                  >
                    {subjects?.map((subject) => {
                      return (
                        <MenuItem key={subject.id} value={subject.id}>
                          {subject.subject}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText>{errors.class}</FormHelperText>
                </FormControl>
              </Box>
            </Box>
          )
        )}

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <SubmitButton
            disabled={isSubmitting || !isValid}
            type="submit"
            variant="contained"
            style={{ marginTop: 32 }}
          >
            {isSubmitting ? "Please wait..." : "NEXT"}
          </SubmitButton>
        </Box>
      </form>
    </Box>
  );
};

export default SubjectReportForm;
