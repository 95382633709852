import * as React from "react";
import dayjs, { Dayjs } from "dayjs";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";

export default function DatePicker({
  value,
  onChange,
  name,
  label,
  helperText,
  error,
  onBlur,
  ...props
  
}) {
  const [mobiledate, setMobiledate] = React.useState(
    window.matchMedia("(max-width: 992px)").matches
  );

  React.useEffect(() => {
    window
      .matchMedia("(max-width: 992px)")
      .addEventListener("change", (e) => setMobiledate(e.matches));
  }, []);

  const [desktop, setDesktop] = React.useState(
    window.matchMedia("(min-width: 992px)").matches
  );

  React.useEffect(() => {
    window
      .matchMedia("(min-width: 992px)")
      .addEventListener("change", (e) => setDesktop(e.matches));
  }, []);


  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack spacing={3}>
        {desktop && (
          <DesktopDatePicker
            label={label}
            inputFormat="MM/DD/YYYY"
            value={value}
            onChange={onChange}
            PopperProps={{disablePortal:true}}
            renderInput={(params) => (
              <TextField
                onBlur={onBlur}
                defaultValue={props.defaultValue}
                helperText={helperText}
                name={name}
                variant="outlined"
                {...{...params, error:error}}
              />
            )}
          />
        )}

        {mobiledate && (
          <MobileDatePicker
          DialogProps={{disablePortal: true}}
            label={label}
            inputFormat="MM/DD/YYYY"
            value={value}
            onChange={onChange}
            renderInput={(params) => (
              <TextField
                onBlur={onBlur}
                helperText={helperText}
                name={name}
                variant="outlined"
                {...{...params, error:error}}
              />
            )}
          />
        )}
      </Stack>
    </LocalizationProvider>
  );
}
