import React from "react";
import styles from "./settings.module.css";
import { Box, Fab } from "@mui/material";
import { TabPanel } from "components/Tab/TabPanel";
import { Tabs } from "@mui/material";
import { LinkTab } from "components/Tab/LinkTab";
import Subject from "./Subject/Subject";
import Class from "./Class/Class";
import ClasssSet from "./ClassSet/ClasssSet";
import Sessions from "./Sessions/Sessions";
import Terms from "./Terms/Terms";
import ReportComment from "./ReportComment/ReportComment";
import Reports from "./Reports/Reports";
import { TabStyle, TabsStyle } from "components/Tab/TabStyles";
import SaveGrade from "./Grades";
import { Add } from "@mui/icons-material";

const Settings = () => {
  const [value, setValue] = React.useState(0);

  const handleChangeValue = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const tabMenu = [
    {
      itemName: "SUBJECT",
      link: "#",
    },

    {
      itemName: "CLASS",
      link: "#",
    },
    {
      itemName: "CLASS SET",
      link: "#",
    },
    {
      itemName: "SESSION",
      link: "#",
    },
    {
      itemName: "TERMS",
      link: "#",
    },
    {
      itemName: "GRADES",
      link: "#",
    },
    {
      itemName: "REPORT COMMENT",
      link: "#",
    },
    {
      itemName: "REPORT",
      link: "#",
    },
  ];

  const tabComponent = [
    Subject,
    Class,
    ClasssSet,
    Sessions,
    Terms,
    SaveGrade,
    ReportComment,
    Reports,
  ];

  return (
    <Box className={styles.settings}>
      <Box
        sx={{
          borderBottom: 0,
          ".MuiTabs-indicator": {
            display: "none",
          },
        }}
        className={styles.tabLinkContainer}
      >
        <Tabs
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          value={value}
          onChange={handleChangeValue}
          sx={TabsStyle}
        >
          {tabMenu.map((item) => {
            return (
              <LinkTab
                sx={TabStyle}
                label={item.itemName}
                href={`${item.link}`}
              />
            );
          })}
        </Tabs>
      </Box>

      <Box padding={{xs: "32px 0px", md:"32px 16px"}}>
        {tabComponent.map((Component, index) => {
          return (
            <TabPanel value={value} index={index}>
              <Component />
            </TabPanel>
          );
        })}
      </Box>
    </Box>
  );
};

export default Settings;
