import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { SubmitButton } from "components/Common";
import FieldContainer from "components/FieldContainer";
import TextField from "components/Form/TextField";
import { useFormik } from "formik";
import { useListSessions } from "hooks/session";
import { useListTerms } from "hooks/term";
import { IPostPinFormTypes } from "types/results";
import * as Yup from "yup";

interface CreatePinFormProps {
  onSubmit?: (values: Partial<IPostPinFormTypes>) => Promise<any>;
}

const CreatePinForm = ({ onSubmit }: CreatePinFormProps) => {
  const validationSchema = Yup.object().shape({
    pinNumbers: Yup.string()
      .min(1, "Enter a valid number")
      .required("Enter a valid number"),
    session: Yup.string().required("Session is required"),
    term: Yup.string().required("Please select a term"),
  });

  const initialValues: Partial<IPostPinFormTypes> = {};

  const { data: sessionData } = useListSessions();
  const { data: termData } = useListTerms();

  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    isSubmitting,
    handleSubmit,
    isValid,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values: Partial<IPostPinFormTypes>, { setSubmitting }) => {
      setSubmitting(true);
      await onSubmit?.(values);
      setSubmitting(false);
    },
  });

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Grid direction="column" display="flex">
          <FieldContainer>
            <TextField
              type="number"
              name="pinNumbers"
              value={values.pinNumbers || ""}
              helperText={errors.pinNumbers}
              error={!!(errors.pinNumbers && touched.pinNumbers)}
              label="Number of Pins"
              variant="outlined"
              onChange={handleChange}
              onBlur={handleBlur}
              overrideDefaultStyles
              fullWidth
            />
          </FieldContainer>
          <Grid container spacing={2} marginTop={0}>
            <FieldContainer lg={6}>
              <FormControl fullWidth>
                <InputLabel id="session">Select Session</InputLabel>
                <Select
                  type="text"
                  name="session"
                  error={!!(errors.session && touched.session)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  labelId="session"
                  id="session-select"
                  label="Select Session"
                  value={values.session || ""}
                  defaultValue=""
                >
                  {sessionData?.map((session) => {
                    return (
                      <MenuItem key={session.id} value={session.id}>
                        {session.session}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText>{errors.session}</FormHelperText>
              </FormControl>
            </FieldContainer>

            <FieldContainer lg={6}>
              <FormControl fullWidth>
                <InputLabel id="term">Select Term</InputLabel>
                <Select
                  type="text"
                  name="term"
                  error={!!(errors.term && touched.term)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  labelId="term"
                  id="term-select"
                  defaultValue=""
                  value={values.term || ""}
                  label="Select Term"
                >
                  {termData?.map((term) => {
                    return (
                      <MenuItem key={term.id} value={term.id}>
                        {term.term}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText>{errors.term}</FormHelperText>
              </FormControl>
            </FieldContainer>
          </Grid>
          <SubmitButton
            disabled={isSubmitting || !isValid}
            type="submit"
            variant="contained"
            style={{ marginTop: 32, alignSelf: "center" }}
          >
            {isSubmitting ? "Generating..." : "Generate Pin"}
          </SubmitButton>
        </Grid>
      </form>
    </Box>
  );
};

export default CreatePinForm;
