import { Button, ButtonGroup } from "@mui/material";
import Table, { RowData } from "components/Table";
import { IAddSessionValues, Session } from "types/sessions";

import DeleteIcon from "@mui/icons-material/Delete";

import Edit from "@mui/icons-material/Edit";

interface ISessionTableProps {
  sessions: Session[];
  onUpdateSession?: (values: IAddSessionValues) => any;
  onDeleteSession?: (sessionId: number) => any;
  openAddSessionModal: () => void;
}

const SessionsTable = (props: ISessionTableProps) => {
  const { sessions, openAddSessionModal, onUpdateSession, onDeleteSession } =
    props;

  return (
    <Table
      title={{ label: "Manage Sessions" }}
      disableSideSheet={true}
      ctaLabel="Add Session"
      onCTAClicked={openAddSessionModal}
      rows={sessions}
      columns={[
        {
          id: "session",
          label: "Session Name",
        },
        {
          id: "status",
          label: "Status",
        },

        {
          id: "actions",
          label: "Action",
          renderer: (item: RowData) => {
            const value = item as Session;
            return (
              <ButtonGroup size="small">

                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    onDeleteSession?.(value.id);
                  }}
                  style={{ whiteSpace: "nowrap" }}
                  variant="outlined"
                >
                  
                  <DeleteIcon sx={{ "&:hover": { color: "#ff5722" } }} />
                </Button>

                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    onUpdateSession?.(value);
                  }}
                  style={{ whiteSpace: "nowrap" }}
                  variant="outlined"
                >
                  
                  <Edit sx={{ "&:hover": { color: "#ff5722" } }} />
                </Button>
              </ButtonGroup>
            );
          },
        },
      ]}
    />
  );
};

export default SessionsTable;
