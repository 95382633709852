import { QueryOptions, useMutation, useQuery } from "react-query";
import { get, post } from "api";
import { GenericAPIResponse } from "types/generic";

import {
  IGetComment,
  IGetClassReportDetails,
  IGetClassReportFormValue,
  IGetClassReportMatchValue,
  IPostClassReportDetails,
  IReportGroup,
  IGetClassSubject,
  IGetSubjectReportTypes,
  IPostSubjectReportType,
} from "types/report";
import { MutationOptions } from "hooks/types";

type IReportResponse = {
  code: number,
  data : IReportGroup []
}

// Getting the the active session, term and classs for class report page
export const useActiveClassReport = (
  options?: QueryOptions<IGetClassReportFormValue>
) => {
  return useQuery<IGetClassReportFormValue>(
    ["students", "class_report_active"],
    () => get(`/students/classreportactive`),
    { ...(options || {}) }
  );
};

// Getting the the active session, term and classs for nurery class report page
export const useActiveNurseryClassReport = (
  options?: QueryOptions<IGetClassReportFormValue>
) => {
  return useQuery<IGetClassReportFormValue>(
    ["students", "class_report_active_nursery"],
    () => get(`/students/classreportactive/nursery`),
    { ...(options || {}) }
  );
};


// Getting the the active session, term and classs for class report page
export const useClassReport = (
  options?: QueryOptions<IGetClassReportFormValue>
) => {
  return useQuery<IGetClassReportFormValue>(
    ["students", "class_report"],
    () => get(`/students/classreport`),
    { ...(options || {}) }
  );
};

//Getting the list of student that match the active session class report
export const useClassReportMatchList = (
  classId: string | null,
  options?: QueryOptions<IGetClassReportMatchValue>
) => {
  return useQuery<IGetClassReportMatchValue>(
    ["students", "class_report_match"],
    () => get(`/students/classreportmatch/${classId}`),
    { ...(options || {}) }
  );
};


//Getting head teachers comments
export const useCommentReport = (options?: QueryOptions<IGetComment>) => {
  return useQuery<IGetComment>(
    ["settings", "comments"],
    () => get(`/settings/comments`),
    { ...(options || {}) }
  );
};


// Getting student report details
export const useStudentReportDetails = (
  session: string | null,
  term: string | null,
  studentId: string | null,
  options?: QueryOptions<IGetClassReportDetails>
) => {
  return useQuery<IGetClassReportDetails>(
    ["students", "report_details"],
    () => get(`/reports/details/${session}/${term}/${studentId}`),
    { ...(options || {}) }
  );
};


// Mutation for saving student report payload to database
export const useSaveReportMutation = (
  options?: MutationOptions<GenericAPIResponse<any>>
) => {
  return useMutation(
    (value: Partial<IPostClassReportDetails>) =>
      post(`/reports/`, value),
    { ...(options || {}) }
  );
};

export const useListReportGroup = (options?: QueryOptions<IReportResponse>) => {
  return useQuery<IReportResponse>(
      ["report", "group"],
      () => get(`/results/groups`),
      { ...(options || {}) }
  );
};

//Mutation for suject as per class selected
export const useGetSubjectList = (
  classId: string | undefined,
  options?: QueryOptions<IGetClassSubject>
) => {
  return useQuery<IGetClassSubject>(
    ["subjects", "subjects_list"],
    () => get(`/subjects/list/${classId}`),
    { ...(options || {}), enabled: !!classId},
  );
};

// Getting student report details
export const useSubjectReport = (
  session: string | null,
  term: string | null,
  subject: string | null,
  options?: QueryOptions<IGetSubjectReportTypes>
) => {
  return useQuery<IGetSubjectReportTypes>(
    ["students", "subject_report"],
    () => get(`/reports/students/${session}/${term}/${subject}`),
    { ...(options || {}) }
  );
};


// Mutation for saving subject report payload to database
export const useSubmitSubjectReport = (
  options?: MutationOptions<GenericAPIResponse<any>>
) => {
  return useMutation(
    (value: Partial<IPostSubjectReportType>) =>
      post(`/reports/students`, value),
    { ...(options || {}) }
  );
};


export const useReportGroupByClass = (sessionId: string | null, termId: string | null) => {
  return useQuery(
    "viewReportGroupByClass",
    async () => {
      const response = await get(`/reports/groups/${sessionId}/${termId}`);
      return response.data;
    },
  );
};


